export enum TravelPlanActionTypes {
  FETCH_TRAVELPLAN = 'FETCH_TRAVELPLAN',
  SET_WIDGET_ID = 'SET_WIDGET_ID',
  SET_PLAN_ID = 'SET_PLAN_ID',
  TRAVEL_MODE_CHANGE = 'TRAVEL_MODE_CHANGE',
  TRAVEL_MODE_CHANGE_BY_CLICKING_LIFTSHARE_MARKER = 'TRAVEL_MODE_CHANGE_BY_CLICKING_LIFTSHARE_MARKER',
  TRAVEL_CARD_CLICKED = 'TRAVEL_CARD_CLICKED',
  LIFTSHARE_CARD_CLICKED = 'LIFTSHARE_CARD_CLICKED',
  TRAVEL_BOUNDS_CHANGED = 'TRAVEL_BOUNDS_CHANGED',
  TRAVEL_PLAN_DETAIL_DISMISSED = 'TRAVEL_PLAN_DETAIL_DISMISSED',
  SET_OPTIONS = 'SET_OPTIONS',
  SET_HEADER_IMAGE_URL = 'SET_HEADER_IMAGE_URL',
}
