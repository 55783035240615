import general from './general';
import { transportTypeOptions, transportTypeColours } from './transport-types';
import pageHeaders from './page-headers';
import datesAndTimes from './dates-times';
import forms from './forms';
import appPreviews from './app-previews';
import navigation from './navigation';
import commuteBoost from './commute-boost';

export const enGB = {
  general,
  navigation,
  pageHeaders,
  transportModes: transportTypeOptions,
  transportModeColours: transportTypeColours,
  datesAndTimes,
  forms,
  appPreviews,
  commuteBoost,
};
