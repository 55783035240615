import type { GetterTree } from 'vuex';
import type { RootState } from '@/mw-components/store';
import type { CreateWidgetModalType, State } from './state';
import type { TravelPlanEmailTabStatus } from '@/mw-components/enums/travel-plan';

import type { WidgetType } from '@/mw-components/enums/widgets';
import {
  WidgetButtonFontSize,
  WidgetButtonRadius,
  WidgetStatus,
} from '@/mw-components/enums/widgets';

import { contrastingColour } from '@/mw-components/helpers/functions';
import type { Location } from '@/mw-components/types/location';

export type Getters = {
  isLoading(state: State): boolean;
  isContentReady(state: State): boolean;
  getTabData(state: State): Array<string>;
  getActiveTab(state: State): number;
  getActiveEmailTab(state: State): TravelPlanEmailTabStatus;
  getWidgetTitle(state: State): string | null;
  getWidgetIntro(state: State): string | null;
  getWidgetName(state: State): string;
  getWidgetType(state: State): WidgetType;
  getWidgetStatus(state: State): boolean;
  getWidgetCustomColour(state: State): string;
  getWidgetContrastingColour(state: State): string;
  getCustomButtonText(state: State): string;
  getButtonRadius(state: State): WidgetButtonRadius | null;
  getButtonRadiusClass(state: State): string | null;
  getButtonFontSize(state: State): WidgetButtonFontSize | null;
  getButtonFontSizeClass(state: State): string | null;
  getTravelDestination(state: State): Array<Location> | null;
  isGeofencingActive(state: State): boolean;
  isGeoFencingMapActive(state: State): boolean;
  isEditingWidgetName(state: State): boolean;
  geoFencingSelectModel(state: State): Array<number>;
  numberOfErrors(state: State): number;
  getDefaultArriveBy(state: State): boolean;
  getDefaultReturnBy(state: State): boolean;
  getDefaultOutboundTime(state: State): string;
  getDefaultReturnTime(state: State): string;
  isUnsavedChanges(state: State): boolean;
  getModalType(state: State): CreateWidgetModalType;
  getModalActive(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  isLoading: (state) => state.loading,
  isContentReady: (state) => state.contentReady,
  getTabData: (state) => state.tabs.titles,
  getActiveTab: (state) => state.tabs.active as number,
  getActiveEmailTab: (state) => state.emailTabs.active,
  getWidgetTitle: (state) => state.widget.title,
  getWidgetIntro: (state) => state.widget.introduction,
  getWidgetName: (state) => state.widget.name,
  getWidgetType: (state) => state.widget.type,
  getWidgetCustomColour: (state) => state.widget.customColour,
  getWidgetContrastingColour: (state) => contrastingColour(state.widget.customColour),
  getCustomButtonText: (state) => state.widget.customButtonText,
  getButtonRadius: (state) => state.widget.customButtonRadius, //(state.widget.customButtonRadius) ? state.widget.customButtonRadius : WidgetButtonRadius.None,
  getButtonRadiusClass: (state) => {
    const { customButtonRadius: radius } = state.widget;
    switch (radius) {
      case WidgetButtonRadius.None:
        return 'rounded-none';
      case WidgetButtonRadius.Small:
        return 'rounded-sm';
      case WidgetButtonRadius.Medium:
        return 'rounded-md';
      case WidgetButtonRadius.Large:
        return 'rounded-lg';
      case WidgetButtonRadius.Full:
        return 'rounded-full';
      default:
        return null;
    }
  },
  getButtonFontSize: (state) => state.widget.customButtonFontSize, //(state.widget.customButtonFontSize) ? state.widget.customButtonFontSize : WidgetButtonFontSize.XSmall,
  getButtonFontSizeClass: (state) => {
    const { customButtonFontSize: fontSize } = state.widget;
    switch (fontSize) {
      case WidgetButtonFontSize.XSmall:
        return 'xs';
      case WidgetButtonFontSize.Small:
        return 'sm';
      case WidgetButtonFontSize.Medium:
        return 'md';
      case WidgetButtonFontSize.Large:
        return 'lg';
      case WidgetButtonFontSize.XLarge:
        return 'xl';
      case WidgetButtonFontSize.XXLarge:
        return 'xxl';
      default:
        return null;
    }
  },
  getTravelDestination: (state) =>
    state.widget.locations && state.widget.locations.length > 0 ? state.widget.locations : [],
  getWidgetStatus: (state) => state.widget.status === WidgetStatus.Active,
  isGeofencingActive: (state) => state.geofencingActive,
  isGeoFencingMapActive: (state) => state.geoFencingMapActive,
  isEditingWidgetName: (state) => state.isEditingWidgetName,
  geoFencingSelectModel: (state) => state.selectedGeoFences,
  numberOfErrors: (state) => Object.keys(state.errors).length,
  getDefaultArriveBy: (state) => state.widget.defaultArriveBy,
  getDefaultReturnBy: (state) => state.widget.defaultReturnBy,
  getDefaultOutboundTime: (state) => state.widget.defaultOutboundTime as string,
  getDefaultReturnTime: (state) => state.widget.defaultReturnTime as string,
  isUnsavedChanges: (state) => state.unsavedChanges,
  getModalActive: (state) => state.modal.active,
  getModalType: (state) => state.modal.type,
};
