<template>
  <div
    data-cy="personalisation-token"
    class="relative cursor-pointer"
    v-clickout="closeDropdown"
    @click="data.dropdownVisible = true"
  >
    <p class="text-sec font-bold flex items-center">
      <i class="fas fa-user text-xs mr-2"></i>
      Personalisation Token
      <i class="fas fa-sort-down ml-2 self-start"></i>
    </p>
    <div
      class="absolute w-screen max-w-xs mt-1 right-0 bg-white p-6 border shadow-lg z-20"
      v-if="data.dropdownVisible"
    >
      <BrandSelect
        id="cycleOption"
        v-model="data.selectedToken"
        placeholder="Please select"
        label="Add contact token"
        class="mb-6"
      >
        <option v-for="(token, index) in filteredTokens" :key="index" :value="token.token">
          {{ token.name }}
        </option>
      </BrandSelect>
      <div class="flex justify-between">
        <Button label="Cancel" severity="secondary" outlined @click="closeDropdown" />
        <Button label="Insert" data-cy="insert-token" @click="insertToken" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { MWApi } from '@/api/legacy';
import { defineComponent, onMounted, reactive, computed } from 'vue';
import BrandSelect from '@/components/Forms/BrandSelect.vue';
import Button from 'primevue/button';
// @ts-ignore
import vClickout from 'vue3-clickout';

const api = new MWApi();

export default defineComponent({
  name: 'PersonalisationToken',
  emits: ['tokenSelected'],
  directives: {
    clickout: vClickout,
  },
  props: {
    filter: {
      type: Array,
      required: false,
    },
  },
  components: {
    BrandSelect,
    Button,
  },

  setup(props, { emit }) {
    const data = reactive({
      contentVisible: true,
      tokens: [],
      dropdownVisible: false,
      selectedToken: null as any,
    });

    onMounted(() => {
      getTokens();
    });

    const filteredTokens: any = computed(() => {
      if (props.filter && props.filter.length > 0) {
        return data.tokens.filter((token: any) => {
          return !props.filter?.includes(token.name);
        });
      } else {
        return data.tokens;
      }
    });

    const getTokens = () => {
      api.PersonalisationTokens().then((resp) => {
        data.tokens = resp;
        if (!data.tokens.length) data.contentVisible = false;
      });
    };

    const closeDropdown = () => {
      data.dropdownVisible = false;
    };

    const insertToken = () => {
      if (!data.selectedToken) return;
      emit('tokenSelected', data.selectedToken);
      closeDropdown();
    };

    return {
      data,
      closeDropdown,
      insertToken,
      filteredTokens,
    };
  },
});
</script>
