export default {
  common: {
    appPreview: 'App preview',
    expandedView: 'Expanded view',
    outOf: 'out of',
    placesLeft: 'places left',
  },
  challenge: {
    expandedView: 'Expanded view',
    listView: 'List view',
    joined: 'Joined',
    startDate: 'Start date',
    endDate: 'End date',
    challengeName: 'Challenge name',
    days: 'days',
    startsIn: 'Starts in',
    challengeStartsIn: 'Challenge starts in',
    description: 'Description',
    requirements: 'Requirements',
    joinChallenge: 'Join challenge',
  },
  boost: {
    viewBoost: 'View boost',
    claimedHeading: 'Your Boost has been claimed - enjoy!',
    awarded: 'Awarded',
    boostRedeemedOn: 'This Boost was Redeemed on',
  },
};
