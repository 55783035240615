import moment from 'moment';
import type { SeriesOption } from 'echarts';

import { type DashboardLowFrequencyResponseMetaData } from '@/api/types';
import i18n from '@/locales';
const { t } = i18n.global;

export const transportModesOrderedByEmissions = [
  0, 1, 23, 2, 3, 19, 21, 5, 22, 15, 6, 24, 25, 26, 27, 7, 10, 13, 18, 9, 20, 4, 8, 14, 11, 12, 16,
];

//13 IS TRAIN

/**
 *
 * @param apiData
 * @param apiParameter e.g. emissionsInKg | percentage | employees
 * @param stackName If null, the series will not be stacked. If provided, series with the same stack will be stacked on top of one another.
 * @param includeFuture If false, only returns data points in the past.
 * @param nameSuffix Appended to the series name, which is used for toggling the visibility. E.g. 'emissions figures'
 * @param meta Array of strings to be added to every data point. Used for tooltip formatting. E.g. ['mode', 'emissions']
 * @param start Start index for the data points.
 * @param end End index for the data points.
 */
export const convertApiDataModeToChartAreaSeries = (
  mode: any,
  apiParameter: string,
  stackName: string | null,
  nameSuffix: string,
  meta: {
    label: string;
    type: string;
    position: 'foreground' | 'background' | 'trend';
  },
  start: number,
  end: number,
) => {
  // Use start > 0 to identify whether the series is a prediction or not.
  const series: SeriesOption = {
    name: t(`transportModes.${Number(mode.mode)}`) + ` ${nameSuffix}`,
    type: 'line',
    stack: stackName ?? undefined,
    smooth: false,
    animation: false,
    lineStyle: {
      width: 1,
      //color: start ? getColour(mode.mode) : '#fff',
      color: '#fff',
      opacity: 1,
      //type: start > 0 ? 'dashed' : 'solid',
      type: 'solid',
    },
    symbol: 'none',
    showSymbol: false,
    symbolSize: 0,
    areaStyle: {
      opacity: start > 0 ? 0.55 : 0.9,
      color: t(`transportModeColours.${Number(mode.mode)}`),
    },
    emphasis: {
      disabled: false,
      focus: 'series',
      areaStyle: {
        //opacity: start > 0 ? 0 : 1,
        opacity: 1,
        color: t(`transportModeColours.${Number(mode.mode)}`),
      },
    },
    blur: {
      areaStyle: {
        //opacity: start > 0 ? 0 : 0.4,
        opacity: 0.4,
        color: t(`transportModeColours.${Number(mode.mode)}`),
      },
    },
    data: mode.data.slice(start, end).map((data: any) => {
      return {
        name: data.date,
        value: [
          moment(data.date).format('YYYY/MM/DD'),
          apiParameter == 'percentage'
            ? data.figures[apiParameter] * 100
            : apiParameter == 'employees'
            ? data.figures[apiParameter]
            : data.figures[apiParameter] / 1000,
        ],
        colour: t(`transportModeColours.${Number(mode.mode)}`),
        meta: meta,
      };
    }),
  };
  return series;
};

export const getAcelDatesForXAxisFromApiData = (data: DashboardLowFrequencyResponseMetaData[]) => {
  const series: SeriesOption = {
    name: 'dates',
    type: 'line',
    data: data.map((data: DashboardLowFrequencyResponseMetaData) => {
      return {
        name: data.date,
        value: [moment(data.date).format('YYYY/MM/DD'), 0],
      };
    }),
    lineStyle: {
      width: 0,
      color: '#000000',
      opacity: 0,
      type: 'solid',
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0,
      color: '#000000',
    },
    markPoint: {
      animation: false,
      // used for handling the hover event
      emphasis: {
        disabled: true,
        itemStyle: {
          color: '#F163C1',
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#EC30AD',
          width: 24,
          height: 24,
          borderRadius: 12,
          borderWidth: 0,
        },
      },
      label: {
        show: true,
        color: '#374151',
        fontSize: 12,
        fontWeight: 'bolder',
        backgroundColor: '#FEF9FD',
        verticalAlign: 'middle',
        align: 'center',
        padding: [5, 5, 3, 5],
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 2,
        offset: [0, 19],
        formatter: function (param: any) {
          return param.data.hasAcelScore
            ? [`{a|${param.data.value}}`]
            : [`{b|${param.data.value}}`];
        },
        rich: {
          a: {
            fontWeight: 'bold',
            color: '#F163C1', // Wish underline was supported here
          },
          b: {
            color: '#374151',
          },
        },
      },
      symbol: 'arrow',
      symbolSize: 12,
      itemStyle: {
        color: '#374151',
      },
      data: data.map((point: DashboardLowFrequencyResponseMetaData) => {
        return {
          isAnnotation: false,
          isAcelDate: true,
          name: point.date,
          coord: [moment(point.date).format('YYYY/MM/DD'), 0],
          value: moment(point.date).format('D MMM YYYY'),
          url: 'https://mobilityways.com/',
          hasAcelScore: point.metadata.length === 1,
        };
      }),
    },
  };
  return series;
};

export const getAcelScoresFromApiData = (data: DashboardLowFrequencyResponseMetaData[]) => {
  const dataArray: SeriesOption[] = [];
  data.forEach((point: DashboardLowFrequencyResponseMetaData) => {
    if (point.metadata.length === 1) {
      dataArray.push({
        isAnnotation: false,
        acelVersion: point.metadata[0].ReportVersion,
        isAcelDate: false,
        name: point.metadata[0].AcelScore,
        coord: [moment(point.date).format('YYYY/MM/DD'), 0],
        value: point.metadata[0].AcelScore,
        url: 'https://mobilityways.com/',
      });
    }
  });

  const series = {
    name: 'acels',
    type: 'line',
    data: data.map((data: any) => {
      return {
        name: data.date,
        value: [moment(data.date).format('YYYY/MM/DD'), 0],
      };
    }),
    lineStyle: {
      width: 0,
      color: '#000000',
      opacity: 0,
      type: 'solid',
    },
    showSymbol: false,
    areaStyle: {
      opacity: 0,
      color: '#000000',
    },
    markPoint: {
      animation: false,
      // used for handling the hover event
      emphasis: {
        disabled: true,
        itemStyle: {
          color: '#F163C1',
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#EC30AD',
          width: 24,
          height: 24,
          borderRadius: 12,
          borderWidth: 0,
        },
      },
      label: {
        show: true,
        color: '#F163C1',
        opacity: 1,
        backgroundColor: '#FEF9FD',
        verticalAlign: 'middle',
        align: 'center',
        padding: [3, 5, 3, 5],
        borderWidth: 1,
        borderColor: '#F163C1',
        borderRadius: 2,
        offset: [0, 51],
        formatter: function (param: any) {
          return [
            `{a|ACEL}`,
            `{c|${param.data.acelVersion == 1 ? '≈ ' : ''}}{b|${param.name}}`,
          ].join('\n');
          // See https://apache.github.io/echarts-handbook/en/how-to/label/rich-text/
        },
        rich: {
          a: {
            fontSize: 10,
            lineHeight: 16,
          },
          b: {
            fontSize: 16,
            lineHeight: 16,
            fontWeight: 'bold',
          },
          c: {
            fontSize: 12,
            lineHeight: 16,
            fontWeight: 'bold',
          },
        },
      },
      symbol: 'arrow',
      symbolSize: 12,
      itemStyle: {
        color: '#F163C1',
      },
      data: dataArray,
    },
  };
  return series;
};

export const convertAnnotationApiDataToSeries = (
  data: {
    uuid: string;
    date: string;
    value: number;
    title: string;
    content: string;
  }[],
) => {
  const series: SeriesOption = {
    name: 'annotations',
    type: 'line',
    data: [],
    markPoint: {
      animation: false,
      // used for handling the hover event
      emphasis: {
        disabled: false,
        itemStyle: {
          color: '#01D2C5',
        },
      },
      label: {
        show: true,
        color: '#ffffff',
        fontSize: 10,
        borderWidth: 0,
        offset: [0, 1],
        formatter: function (param: any) {
          return param.data.index + 1;
        },
      },
      symbol: 'circle',
      symbolSize: 18,
      itemStyle: {
        color: '#01D2C5',
        opacity: 1,
      },
      blur: {
        label: {
          show: true,
          color: '#ffffff',
          opacity: 1,
        },
        itemStyle: {
          opacity: 1,
        },
      },
      data: data.map((annotation, index) => {
        return {
          isAnnotation: true,
          index: index,
          name: annotation.title,
          coord: [moment(annotation.date).format('YYYY/MM/DD'), 0],
          value: moment(annotation.date).format('D MMM YYYY'),
          uuid: annotation.uuid,
          html: annotation.content,
        };
      }),
    },
  };
  return series;
};
