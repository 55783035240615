import { TravelPlanSwitchType } from '@/mw-components/enums/travel-plan';

export const defaultState = () => ({
  loading: true,
  headerImageUrl: null,
  contentReady: false,
  widgetId: null,
  planId: null,
  routes: null,
  facts: null,
  options: null,
  outbound: true,
  currentTravelMode: TravelPlanSwitchType.Recommended,
  activeCard: null,
  activeLiftshareCardId: null,
  viewDetail: null,
  contacts: [],
});

export const state = defaultState();
