<template>
  <div class="flex justify-between" v-if="label || tooltip || usePersonalisation || inputMenuData">
    <label class="mb-2 flex flex-row items-center" v-if="label" v-tooltip="`${tooltip ?? ''}`">
      {{ label }}
      <i v-if="tooltip" class="fa-solid fa-circle-info ml-2"></i>
    </label>

    <template v-if="usePersonalisation">
      <PersonalisationToken @token-selected="onTokenSelected" />
    </template>

    <template v-if="inputMenuData">
      <InputMenu :dataset="inputMenuData" @entry-selected="onEntrySelected">
        <slot />
      </InputMenu>
    </template>
  </div>

  <textarea
    v-bind="$attrs"
    :value="modelValue"
    @input="updateModel"
    @focus="$emit('focus')"
    class="appearance-none border rounded w-full py-2 px-3 bg-lightGrey focus:outline-none"
    :class="greyedOut ? 'text-gray-400' : 'text-gray-700'"
    ref="inputElement"
  />
</template>

<script lang="ts">
import { defineComponent, ref, type PropType } from 'vue';
import PersonalisationToken from '@/components/Forms/PersonalisationToken.vue';
import InputMenu from '../InputMenu.vue';

type Entry = {
  label: string;
  value: string;
};

export default defineComponent({
  name: 'BrandTextarea',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    usePersonalisation: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    inputMenuData: {
      type: Array as PropType<Array<Entry>>,
    },
    greyedOut: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    PersonalisationToken,
    InputMenu,
  },

  setup(props, { emit }) {
    const inputElement = ref<HTMLTextAreaElement | null>(null);

    const onTokenSelected = (token: string): void => {
      if (!inputElement.value) return;
      const [start, end, value] = [
        inputElement.value.selectionStart,
        inputElement.value.selectionEnd,
        inputElement.value.value,
      ];

      const tokenString = `${value.length === start ? ' ' : ''}${token}`;
      inputElement.value.setRangeText(tokenString, start, end, 'select');
      inputElement.value.focus();
      emit('update:modelValue', value);
    };

    const onEntrySelected = (value: any) => {
      if (!inputElement.value) return;
      inputElement.value.value = value;
      emit('update:modelValue', value);
    };

    const updateModel = (e: Event) => {
      emit('update:modelValue', (e.target as HTMLInputElement).value);
    };

    return {
      onTokenSelected,
      inputElement,
      onEntrySelected,
      updateModel,
    };
  },
});
</script>
