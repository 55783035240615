import type { GetterTree } from 'vuex';
import type { RootState } from '@/mw-components/store';
import type { State } from './state';
import storage from '@/helpers/storage';

export type Getters = {
  getUserAcelTabs(state: State): string | null;
  getUserAcelModalShift(state: State): string | null;
  getSelectedCommunity(state: State): string | null;
  checkedAccountDetails(state: State): any;
  currentCommunity(state: State, getters: any): string;
  filterAccounts(state: State): any;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getUserAcelTabs: (state: State) => state.acelTabs,
  getUserAcelModalShift: (state: State) => state.acelModalShift,
  getSelectedCommunity: (state: State) => {
    const community = storage.get('community');
    return state.community ? state.community : community ? (community as string) : null;
  },
  checkedAccountDetails: (state) => {
    if (!state.checkedAccount) return null;
    const checkedAccounts = state.communityRoles.filter((e: any) => e.id === state.checkedAccount);
    return checkedAccounts.length > 0 ? checkedAccounts[0] : null;
  },
  currentCommunity: (state, getters) => {
    const community = getters.checkedAccountDetails;
    if (!community) return '';
    return community.name;
  },
  filterAccounts: (state) => {
    if (!state.communitySearchTerm) return state.communityRoles;
    const searchTerm = state.communitySearchTerm.toLowerCase();
    return state.communityRoles.filter(
      (account: any) => account.name?.toLowerCase().includes(searchTerm),
    );
  },
};
