import { createStore, createLogger } from 'vuex';
import type {
  TravelPlanStore,
  State as TravelPlanState,
} from '@/mw-components/store/modules/travel-plan';
import type { WidgetsStore, State as WidgetState } from '@/mw-components/store/modules/widgets';
import type {
  CreateWidgetStore,
  State as CreateWidgetState,
} from '@/mw-components/store/modules/create-widget';
import type {
  DashboardAppStore,
  State as DashboardAppState,
} from '@/mw-components/store/modules/dashboard-app';
import type { ContactStore, State as ContactState } from '@/mw-components/store/modules/contacts';
import type {
  SurveyAnalyticsStore,
  State as SurveyState,
} from '@/mw-components/store/modules/survey';
export type RootState = {
  widgets: WidgetState;
  createWidget: CreateWidgetState;
  dashboardApp: DashboardAppState;
  travelPlan: TravelPlanState;
  contacts: ContactState;
  surveyAnalytics: SurveyState;
};

export type Store = CreateWidgetStore<Pick<RootState, 'createWidget'>> &
  DashboardAppStore<Pick<RootState, 'dashboardApp'>> &
  TravelPlanStore<Pick<RootState, 'travelPlan'>> &
  WidgetsStore<Pick<RootState, 'widgets'>> &
  ContactStore<Pick<RootState, 'contacts'>> &
  SurveyAnalyticsStore<Pick<RootState, 'surveyAnalytics'>>;

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

export const store = createStore({
  plugins,
  // NB. modules are regsitered dynamically when needed by the using app
  modules: {},
}) as Store;

// @ts-ignore
export let routerInstance: object;

export function useStore(routerObj: any): Store {
  routerInstance = routerObj;
  return store;
}
