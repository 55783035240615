type Env = 'localhost' | 'localdev' | 'development' | 'staging' | 'production' | 'aspire';
export const getEnv = (): Env => {
  if (import.meta.env.MODE === 'localdev') return 'localdev';
  if (import.meta.env.MODE === 'aspire') return 'aspire';
  if (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1')
  )
    return 'localhost';
  if (window.location.hostname.includes('development')) return 'development';
  if (window.location.hostname.includes('staging')) return 'staging';
  return 'production';
};

export const getDomainMwWeb = () => {
  switch (getEnv()) {
    case 'localdev':
    case 'localhost':
    case 'aspire':
      return 'http://localhost:3000';
    case 'development':
      return 'https://app-development.mobilityways.co.uk';
    case 'staging':
      return 'https://app-staging.mobilityways.co.uk';
    case 'production':
    default:
      return 'https://app.mobilityways.com';
  }
};
