export default {
  'en-GB': {
    currency: {
      style: 'currency',
      currency: 'GBP',
      notation: 'standard',
    },
    decimal: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: 'percent',
      useGrouping: false,
    },
    /**
     * @usage
     * n(mode.savingsPerYearKgCO2e / 1000, 'tonnes');
     * Or, if you want to avoid commas grouping the thousands:
     * n(mode.savingsPerYearKgCO2e / 1000, 'tonnes', { useGrouping: false });     *
     */
    tonnes: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    kg: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    },
    miles: {
      style: 'decimal',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    km: {
      style: 'decimal',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
    m: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    minutes: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
};
