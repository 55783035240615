<script lang="ts" setup>
import paths from '@/router/paths';
import { store } from '@/mw-components/store';
import { DashboardAppMutationTypes } from '@/mw-components/store/modules/dashboard-app/mutation-types';
import { reactive, inject, ref } from 'vue';
import { event as GTagEvent } from 'vue-gtag';

import type { Community } from '@/types/global-types';

//  Import the global store
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global-store';
import { useAuthStore } from '@/stores/auth/auth-store';
import router from '@/router';
const globalStore = useGlobalStore();
const { availableCommunities, user } = storeToRefs(globalStore);

const authStore = useAuthStore();

const listOfCommunities = ref<Community[] | []>(availableCommunities.value);
const searchTerm = ref<string>('');

// subscribe to the global store and listen for the selectedCommunityId changing
globalStore.$subscribe(() => {
  listOfCommunities.value = availableCommunities.value;
});

const onSearchTermChanged = () => {
  listOfCommunities.value = availableCommunities.value.filter((community) =>
    community.name.toLowerCase().includes(searchTerm.value.toLowerCase()),
  );
};

const privacyPolicy = import.meta.env.VITE_MW_PRIVACY_POLICY;
const termsLink = import.meta.env.VITE_MW_TERMS_LINK;
const securityCompliance = import.meta.env.VITE_MW_SECURITY_COMPLIANCE_LINK;

const data = reactive({
  mode: 0,
  isMenuOpen: false,
});

const onAccountClicked = (communityId: string) => {
  globalStore.updateCommunityId(communityId);
  globalStore.getCommunityOptions();
};

// Clear jwt and redirect to login
const signout = () => {
  store.commit(`dashboardApp/${DashboardAppMutationTypes.UPDATE_ACEL_MODAL_SHIFT}`, null);
  store.commit(`dashboardApp/${DashboardAppMutationTypes.UPDATE_ACEL_TABS}`, null);
  // TODO: Composable for this?
  authStore.destroyTokens();
  router.push({ name: 'login', query: { why: 'user' } });
  GTagEvent('logout', { method: 'User' });
};
</script>

<template>
  <div
    data-cy="user-nav"
    class="usernav fixed lg:relative bg-gray-800 bottom-0 right-0 mb-8 mr-8 lg:mb-0 lg:mr-0 rounded-full lg:rounded-none"
    @mouseenter="data.isMenuOpen = true"
    @mouseleave="data.isMenuOpen = false"
  >
    <div
      class="flex items-center justify-center lg:justify-between text-white py-2 px-4 text-sm cursor-pointer h-12 w-12 lg:w-auto lg:h-auto"
    >
      <span class="font-semibold hidden lg:block" id="usernavCommunity">{{
        globalStore.getCommunityName()
      }}</span>
      <i class="fas fa-chevron-down ml-2 text-xs text-pri hidden lg:block"></i>
      <span class="text-2xl block lg:hidden"><i class="fas fa-user-circle"></i></span>
    </div>

    <div
      class="z-30 absolute pt-5 right-0 max-w-xs w-screen bottom-0 lg:bottom-auto"
      v-if="data.isMenuOpen"
    >
      <div
        class="bg-white border border-smoke rounded shadow-lg"
        v-if="availableCommunities.length"
      >
        <template v-if="data.mode === 0">
          <!-- TODO: update user area with user avatar and link button for user profile-->
          <router-link
            :to="{ name: paths.accountSettings.name }"
            class="flex justify-between items-center w-full hover:bg-gray-200 select-none"
          >
            <div class="p-4 cursor-pointer">
              <p class="font-semibold">{{ user.firstName + ' ' + user.lastName }}</p>
              <p class="text-xs opacity-75">{{ user.email }}</p>
            </div>
            <i class="fas fa-angle-right text-ter mr-5"></i>
          </router-link>
          <div class="border-t border-b border-smoke">
            <div
              class="flex justify-between items-center w-full cursor-pointer px-4 py-2 hover:bg-gray-200 select-none"
            >
              {{ globalStore.getCommunityName() }}
              <i class="fas fa-check-circle text-ter"></i>
            </div>
          </div>

          <div v-if="availableCommunities.length > 12">
            <div
              class="flex justify-between items-center w-full cursor-pointer px-4 py-2 select-none"
            >
              <input
                type="text"
                class="shadow appearance-none border border-smoke rounded w-full py-1 px-3 text-gray-700 bg-lightGrey focus:outline-none"
                placeholder="Search for an account"
                v-model="searchTerm"
                @input="onSearchTermChanged"
              />
            </div>
          </div>

          <div
            v-if="listOfCommunities.length > 1 || searchTerm.length > 0"
            class="border-b border-smoke overflow-y-auto usernav__list"
          >
            <template v-for="community in listOfCommunities" :key="community.id">
              <div
                class="flex justify-between items-center w-full cursor-pointer px-4 py-2 hover:bg-gray-200 select-none"
                @click="onAccountClicked(community.id)"
              >
                {{ community.name }}
              </div>
            </template>
          </div>

          <div class="border-b border-smoke overflow-y-auto usernav__list">
            <div class="flex justify-between items-center w-full px-2 py-2 select-none">
              <ul>
                <li
                  class="flex justify-between items-center cursor-pointer w-full py-2 px-2 select-none"
                >
                  <p>
                    <a
                      href="https://trello.com/b/ofoWf0fy/mobilityways-feature-vote"
                      target="_blank"
                      ><i class="px-1 fas fa-bullhorn text-ter list-icon"></i>Feature Vote</a
                    >
                  </p>
                </li>
                <li
                  class="flex justify-between items-center cursor-pointer w-full py-2 px-2 select-none"
                >
                  <p>
                    <a href="https://content.mobilityways.com/en/knowledge" target="_blank"
                      ><i class="px-1 fas fa-question-circle text-ter list-icon"></i>Help Guides</a
                    >
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="p-4 bg-gray-200 flex items-center justify-between text-xs text-ter">
            <a data-cy="logout-button" @click="signout()" class="hover:underline"> Sign Out </a>
            <a
              data-cy="security-button"
              :href="securityCompliance"
              target="_blank"
              class="hover:underline"
              >Security &amp; Compliance</a
            >
            <div>
              <a :href="termsLink" target="_blank" class="hover:underline"> Terms </a>
              &amp;
              <a :href="privacyPolicy" target="_blank" class="hover:underline">Privacy</a>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.usernav__list {
  max-height: 400px;
  max-height: 40vh;
}

.list-icon {
  width: 25px;
  margin-right: 5px;
}
</style>
