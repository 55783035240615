import { defineStore } from 'pinia';

// Types
import type {
  PersonalisationToken,
  Community,
  Role,
  HighFrequencyDataSource,
} from '@/types/global-types';

// Helpers
import GlobalHelpers from '@/stores/global-helpers';

// import the API from mw-components
import { MWApi, MWApiV2 } from '@/api/legacy';
import paginationHelpers from '@/helpers/pagination-helpers';
import { transportModesOrderedByEmissions } from '@/stores/dashboard/dashboard-helpers';
import { initialPageSizes } from './global-constants';
import { type FeatureManagerFeatures } from '@/api/types';
import DashboardAPI from '@/api/legacy/endpoints/dashboard-api';
import { useToast } from 'vue-toastification';
import { useAuthStore } from './auth/auth-store';
import router from '@/router';

import { getV2CommunityOptionsCommunityId } from '@/api/v2/generated/tag-liftshare-community-auth-public';

const api = new MWApi();
const api2 = new MWApiV2();

export type CommunityOptions = {
  theme: {
    colourPrimary: string;
    colourSecondary: string;
  };
  enabledAppVersion: 'CompanionApp' | 'Mobilityways';
};

export const useGlobalStore = defineStore('GlobalStore', {
  /**
   * STATE
   * @docs https://pinia.vuejs.org/core-concepts/state.html
   */
  state: () => ({
    showLoading: false, // Use for toggling the visibility of the loading screen
    networkIssue: false, // Use for managing the network issue state of the app
    globalsLoaded: false,
    massUnits: 'tonnes' as 'tonnes' | 'kg',
    drivingFactorOrDuration: 'Duration' as 'Duration' | 'Driving factor',

    featureManager: {
      AcelCharts: false,
    } as FeatureManagerFeatures,

    // The user's roles
    roles: [] as Role[],

    availableCommunities: [] as Community[],

    // The ID of the community the user is currently viewing
    selectedCommunityId: GlobalHelpers.getSelectedCommunityId() as string,
    // IDs of communities selected for showing aggregated data
    aggregatedCommunityIds: [] as string[],
    communityOptions: {} as CommunityOptions,

    // The personalisation tokens for the community
    personalisationTokens: [] as PersonalisationToken[],

    user: {
      firstName: '',
      lastName: '',
      email: '',
    },

    paginationDefaultPerPage: null as number | null,
    pageSizes: [
      { label: '10 per page', value: 10 },
      { label: '25 per page', value: 25 },
      { label: '50 per page', value: 50 },
      { label: '100 per page', value: 100 },
    ],
    paginationPageSize: null as number | null,

    highFrequencyDataSources: [] as HighFrequencyDataSource[],
    showWidgetErrors: false,
    contactsFilter: {} as any, // Moving these here because
    contactsSearchTerm: '' as string,
  }),

  /**
   * GETTERS
   * @docs https://pinia.vuejs.org/core-concepts/getters.html
   */
  getters: {
    // create a getter that checks if the user is an admin
    isAdmin(): boolean {
      return this.roles.some((role) => role.Role === 'Admin' || role.Role === 'SuperAdmin');
    },
    communityIdIsInListOfAvailableCommunities(): (id: string) => boolean {
      return (id: string) => this.availableCommunities.some((community) => community.id === id);
    },
  },

  /**
   * ACTIONS
   * @docs https://pinia.vuejs.org/core-concepts/actions.html
   */
  actions: {
    clearSelectedCommunityId() {
      this.selectedCommunityId = '';
    },
    // defaultUnits() {
    //   this.defaultUnits = !this.showLoading;
    // },
    toggleLoading() {
      this.showLoading = !this.showLoading;
    },

    /**
     * Toggle show errors for widget page
     */
    toggleShowWidgetErrors(newVal: boolean) {
      this.showWidgetErrors = newVal;
    },

    /**
     * Fetch global data from the API while also checking the network status.
     */
    async initGlobalStore(): Promise<void> {
      const toast = useToast();
      const authStore = useAuthStore();
      if (!authStore.isAuthenticated) {
        this.globalsLoaded = true;
        return;
      }
      this.getFeatureManager();
      try {
        const call = await api2.Communities();
        this.networkIssue = false;
        this.availableCommunities = call.response.communities;
      } catch (e: any) {
        if (e.message === 'Failed to fetch') {
          this.networkIssue = true;
        } else if (e.status === 401) {
          authStore.destroyTokens();
          router.push({ name: 'login' });
        } else {
          toast.error('An error occurred finding communities. Please reload the page.');
        }
        return;
      }
      this.getRoles();
      this.getUserDetails();
      this.setPaginationPageSize(await paginationHelpers.getAccountDefaultPerPage());

      // Set the store's selected community ID if one isn't already set
      // But we also need to handle different users on the same machine
      const selectedCommunityIdIsInAvailableCommunities =
        GlobalHelpers.selectedCommunityIdIsInAvailableCommunities(
          this.availableCommunities,
          this.selectedCommunityId,
        );
      if (!selectedCommunityIdIsInAvailableCommunities)
        this.selectedCommunityId = GlobalHelpers.getDefaultCommunityId(this.availableCommunities);
      localStorage.community = this.selectedCommunityId;
      this.getAggregatedCommunityIds();
      await this.getCommunityOptions();

      // Get the community's personalisation tokens
      await this.getPersonalisationTokens();
      this.globalsLoaded = true;
    },
    /**
     * Get feature flags
     */
    async getFeatureManager() {
      const response = await api2.FeatureManager();
      if (response.status === 'Success') {
        this.featureManager = response.response.features;
      }
    },
    /**
     * Get community options from the API.
     */
    async getCommunityOptions() {
      const { response } = await getV2CommunityOptionsCommunityId(this.selectedCommunityId);

      if (response?.options)
        this.communityOptions = {
          theme: {
            colourPrimary: String(response.options.ThemePrimaryColour),
            colourSecondary: String(response.options.ThemeSecondaryColour),
          },
          enabledAppVersion: response.options.EnabledAppVersion as
            | 'CompanionApp'
            | 'Mobilityways',
        };
    },
    /**
     * Get the personalisation tokens from the API.
     */
    async getPersonalisationTokens() {
      const response = await api.PersonalisationTokens();
      this.personalisationTokens = response;
    },
    /**
     * Returns the name of ofhe current community
     */
    getCommunityName(id: string = this.selectedCommunityId) {
      const community = this.availableCommunities.find((comm) => comm.id === id);
      return community ? community.name : null;
    },

    /**
     * Accepts a community ID and updates the store and local storage with it.
     */
    updateCommunityId(id: string) {
      localStorage.community = id;
      this.$patch({ selectedCommunityId: id });
      this.updateAggregatedCommunityIds([id]);
    },

    /**
     * Returns a list of commnities with the roles from each community
     */
    getRoles() {
      // fetch the token data from the helper function
      const { CommunityRole: roles } = GlobalHelpers.getTokenData();
      // super admins on receive a role object
      // check if the roles value is an array
      if (!Array.isArray(roles)) this.roles = [roles];
      // everyone else receives an array of roles
      else this.roles = roles;
    },

    getUserDetails() {
      const tokenData = GlobalHelpers.getTokenData();
      this.user = {
        firstName: tokenData.AdminName,
        lastName: tokenData.Surname,
        email: tokenData.Email,
      };
    },

    async setPaginationDefaultPerPage() {
      this.paginationDefaultPerPage = await paginationHelpers.getAccountDefaultPerPage();
      if (this.paginationDefaultPerPage) {
        //check if the default page size is in the list of page sizes
        const pageSizeExists = this.pageSizes
          .map((pageSize) => pageSize.value)
          .includes(Number(this.paginationDefaultPerPage));
        //if it doesnt exist in the list of page sizes, add it to the start of the list
        if (!pageSizeExists) {
          this.pageSizes = [...initialPageSizes];
          this.pageSizes.unshift({
            label: `${this.paginationDefaultPerPage} per page`,
            value: Number(this.paginationDefaultPerPage),
          });
        }
      }
    },

    setPaginationPageSize(pageSize: number) {
      this.paginationPageSize = pageSize;
    },

    getAggregatedCommunityIds() {
      this.aggregatedCommunityIds = GlobalHelpers.getAggregatedCommunityIdsFromLocalStorage();
      if (!this.aggregatedCommunityIds.length)
        this.aggregatedCommunityIds = [this.selectedCommunityId];
    },
    async updateAggregatedCommunityIds(communityIds: string[]) {
      this.$patch({ aggregatedCommunityIds: communityIds });
      GlobalHelpers.updateAggregatedCommunityIdsInLocalStorage(communityIds);
      return true;
    },

    async fetchHighFrequencyDataSources() {
      const { response } = await DashboardAPI.fetchHighFrequencyDataSources();
      // Reorder the modes so the worst offenders are at the top
      const sortedDataSources = response.dataSources.sort(
        (a: any, b: any) =>
          transportModesOrderedByEmissions.indexOf(Number(b.mode)) -
          transportModesOrderedByEmissions.indexOf(Number(a.mode)),
      );
      this.highFrequencyDataSources = sortedDataSources;
    },
  },
});
