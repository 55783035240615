export enum Methods {
  Post = 'POST',
  Delete = 'DELETE',
  Put = 'PUT',
  Get = 'GET',
}
export type Method = 'POST' | 'GET' | 'PUT' | 'DELETE';

export enum Headers {
  XRequestedWith = 'X-Requested-With',
  ContentType = 'Content-Type',
  CacheControl = 'Cache-Control',
  Pragma = 'Pragma',
  Authorization = 'Authorization',
}

export interface WrappedResponse<Res> {
  response: Res;
  status: string;
}
