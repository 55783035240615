export enum CreateWidgetMutationTypes {
  RESET_STATE = 'RESET_STATE',
  SET_CUSTOM_TEXTS = 'SET_CUSTOM_TEXTS',
  SET_LOADING = 'SET_LOADING',
  SET_WIDGET = 'SET_WIDGET',
  SET_EMAIL_SETTINGS = 'SET_EMAIL_SETTINGS',
  SET_CONTENT_READY = 'SET_CONTENT_READY',
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  SET_ACTIVE_EMAIL_TAB = 'SET_ACTIVE_EMAIL_TAB',
  SET_WIDGET_CUSTOM_COLOUR = 'SET_WIDGET_CUSTOM_COLOUR',
  SET_WIDGET_TYPE = 'SET_WIDGET_TYPE',
  SET_WIDGET_BUTTON_TEXT = 'SET_WIDGET_BUTTON_TEXT',
  SET_WIDGET_BUTTON_FONT_SIZE = 'SET_WIDGET_BUTTON_FONT_SIZE',
  SET_WIDGET_BUTTON_BORDER_RADIUS = 'SET_WIDGET_BUTTON_BORDER_RADIUS',
  SET_WIDGET_PROP_BY_STRING = 'SET_WIDGET_BY_PROP',
  SET_WIDGET_STATUS = 'SET_WIDGET_STATUS',
  CACHE_WIDGET_PROP = 'CACHE_WIDGET_PROP',
  INITIALISE_GEO_REGIONS = 'INITIALISE_GEO_REGIONS',
  UPDATE_GEO_REGIONS = 'UPDATE_GEO_REGIONS',
  REMOVE_GEO_RESTRICTIONS = 'REMOVE_GEO_RESTRICTIONS',
  SET_WIDGET_TRAVEL_DESTINATION = 'SET_WIDGET_TRAVEL_DESTINATION',
  SET_GEOFENCE_TOGGLE = 'SET_GEOFENCE_TOGGLE',
  SET_GEOFENCE_MAP_TOGGLE = 'SET_GEOFENCE_MAP_TOGGLE',
  SET_EMAILSETTINGS_BOOLEAN_PROPERTY = 'SET_EMAILSETTINGS_BOOLEAN_PROPERTY',
  SET_EMAILSETTINGS_STRING_PROPERTY = 'SET_EMAILSETTINGS_STRING_PROPERTY',
  SET_EMAILSETTINGS_CUSTOM_COLOUR = 'SET_EMAILSETTINGS_CUSTOM_COLOUR',
  SET_ERROR_ON_PROPERTY = 'SET_ERROR_ON_PROPERTY',
  REMOVE_ERROR_ON_PROPERTY = 'REMOVE_ERROR_ON_PROPERTY',
  SET_WIDGET_ARRIVEBY = 'SET_WIDGET_ARRIVEBY',
  SET_WIDGET_RETURNBY = 'SET_WIDGET_RETURNBY',
  SET_OUTBOUND_TIME = 'SET_OUTBOUND_TIME',
  SET_RETURN_TIME = 'SET_RETURN_TIME',
  SET_UNSAVED_CHANGES = 'SET_UNSAVED_CHANGES',
  SET_MODAL = 'SET_MODAL',

  SET_BOOLEAN_PROP = 'SET_BOOLEAN_PROP',
}
