<script lang="ts" setup>
import { onBeforeMount } from 'vue';
import NetworkIssueScreen from '@/components-v2/layout/NetworkIssue.vue';
import InactivityTimer from '@/components-v2/layout/InactivityTimer.vue';
import LoadingV2 from '@/components-v2/status/LoadingModal.vue';

// Stores
import { storeToRefs } from 'pinia';
import { useGlobalStore } from '@/stores/global-store';
const globalStore = useGlobalStore();
const { networkIssue, globalsLoaded } = storeToRefs(globalStore);
import { useAuthStore } from '@/stores/auth/auth-store';
const authStore = useAuthStore();

interface Props {
  classes?: string | number;
  placeholder?: string;
}
withDefaults(defineProps<Props>(), {
  classes: '',
});

onBeforeMount(async () => {
  // Init the global store (loads the global vars) and check the network status
  globalStore.initGlobalStore();
});
</script>

<template>
  <NetworkIssueScreen v-if="networkIssue === true" />
  <div v-else class="h-screen flex flex-col overflow-x-hidden">
    <div :class="['flex-1 text-gray-800 bg-gray-100', classes]">
      <!-- Only load the route once the globals are available. -->
      <router-view v-if="globalsLoaded" />
      <LoadingV2 v-else />
    </div>
  </div>
  <InactivityTimer v-if="authStore.isAuthenticated" />
</template>
