import _ from 'underscore';
import { WidgetType } from '@/mw-components/enums/widgets';
import type { TravelPlanServiceType } from '@/assets/enum';
import { SurveyQuestionType } from '@/assets/enum';
import {
  CycleCaloriesPerSecond,
  recipientsConditionLabels,
  recipientsCriteriaLabels,
  WalkingCaloriesPerSecond,
} from '@/assets/constants';
import { defineAsyncComponent } from 'vue';
import { Quill } from '@vueup/vue-quill';
import type { ToastInterface } from 'vue-toastification';

import { getDomainMwWeb } from '@/helpers/url-helpers';

type Env = 'localhost' | 'localdev' | 'development' | 'staging' | 'production' | 'aspire';

export const getEnv = (): Env => {
  if (import.meta.env.MODE === 'localdev') return 'localdev';
  if (import.meta.env.MODE === 'aspire') return 'aspire';
  if (
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('127.0.0.1')
  )
    return 'localhost';
  if (window.location.hostname.includes('development')) return 'development';
  if (window.location.hostname.includes('staging')) return 'staging';
  return 'production';
};

export const getLiftshareDomain = () => {
  switch (getEnv()) {
    case 'aspire':
    case 'localdev':
    case 'localhost':
      return 'https://localhost:5001'
    case 'development':
      return 'https://development.liftshare.com';
    case 'staging':
      return 'https://staging.liftshare.com';
    case 'production':
    default:
      return 'https://liftshare.com';
  }
};

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getErrorMessages = (errorData: any, fallback?: string) => {
  const { Failures } = errorData;
  return Failures !== undefined ? getMessagesFromFailures(Failures) : fallback;
};

export const getMessagesFromFailures = (failures: any) =>
  Object.keys(failures).flatMap((key) => failures[key]);

export const formatLongNumber = (raw: number): string => {
  return numberWithCommas(Number(raw).toFixed(2));
};

export const numberWithCommas = (rawNumber: number | string): string => {
  if (rawNumber === null || rawNumber === undefined) return '';
  return rawNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberFormatter = (number: number, digits: number): string => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const pattern = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value;
    });
  return item ? (number / item.value).toFixed(digits).replace(pattern, '$1') + item.symbol : '0';
};

export const getTokenData = (userToken: string | null) => {
  if (!userToken) return null;
  return JSON.parse(atob(userToken.split('.')[1]));
};

export const generateDistinctColors = (index: number) => {
  const hue = index * 137.508; // use golden angle approximation
  return `hsl(${hue},50%,75%)`;
};

export const fileDownload = (data: any, filenameWithExtension: string): void => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filenameWithExtension);
  document.body.appendChild(link);
  link.click();
};

export const isValidEmail = (rawString: string): boolean => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(rawString));
};

export const splitLastIndex = (rawString: string, splitChar: string) => {
  if (!rawString) return [];
  const lastIndex = rawString.lastIndexOf(splitChar);
  return [rawString.substring(0, lastIndex).trim(), rawString.substring(lastIndex + 1).trim()];
};

export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};

export const contrastingColour = (hex: string): string => {
  let passedWithHash = false;

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
    passedWithHash = true;
  }

  const prefix = passedWithHash ? '#' : '';

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX Colour');
  }

  const red = parseInt(hex.slice(0, 2), 16);
  const green = parseInt(hex.slice(2, 4), 16);
  const blue = parseInt(hex.slice(4, 6), 16);

  return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? `${prefix}000000` : `${prefix}FFFFFF`;
};

export const isExternalUrl = (raw: string): boolean => {
  return typeof raw === 'string' && raw.startsWith('http');
};

export const getCommunityQueryString = (ids: Array<string>) => {
  // Loop and add each community id to query string
  if (!ids || !ids.length) return '';

  let queryString = '';
  ids.forEach((communityId, index) => {
    if (index > 0) {
      queryString = `${queryString}&`;
    }

    queryString = `${queryString}cid=${communityId}`;
  });

  if (!queryString) {
    throw new Error();
  }

  return queryString;
};

export const convertArrayOfObjectsToCSV = (args) => {
  let result: string,
    ctr: number,
    keys: Array<string>,
    columnDelimiter: string,
    lineDelimiter: string,
    data;

  data = args.data || null;
  if (data == null || !data.length) {
    return null;
  }

  columnDelimiter = args.columnDelimiter || ',';
  lineDelimiter = args.lineDelimiter || '\n';

  keys = Object.keys(data[0]);

  if (args.whitelist && Array.isArray(args.whitelist)) {
    keys = _.intersection(keys, args.whitelist);
  }

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item: object) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      let tempItem;

      if (item[key] === null || item[key] === undefined) {
        tempItem = '';
      } else if (Array.isArray(item[key])) {
        if (item[key].length && typeof item[key][0] === 'object' && item[key] !== null) {
          tempItem = item[key].map((e) => objToString(e)).join(' | ');
        } else {
          tempItem = item[key].join(' | ');
        }
      } else if (typeof item[key] === 'object' && item[key] !== null) {
        tempItem = objToString(item[key]);
      } else {
        tempItem = item[key];
      }

      result += tempItem.replace(/,/g, '');
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const objToString = (obj) => {
  let str = '';
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str += p + ': ' + obj[p] + '. ';
    }
  }
  return str;
};

export const downloadCSV = (
  filename: string,
  data: Array<object>,
  whitelist: Array<string> | null = null,
) => {
  let csvData, link;
  let csv = convertArrayOfObjectsToCSV({ data, whitelist });
  if (csv == null) return;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }
  csvData = encodeURI(csv);

  link = document.createElement('a');
  link.setAttribute('href', csvData);
  link.setAttribute('download', filename || 'export.csv');
  link.click();
};

// Dynamic Width (Build Regex)
export const wrapText = (rawText: string, width: number) =>
  rawText.replace(new RegExp(`(?![^\\n]{1,${width}}$)([^\\n]{1,${width}})\\s`, 'g'), '$1\n');

export const getQuestionTypeText = (questionType: SurveyQuestionType): string => {
  switch (questionType as SurveyQuestionType) {
    case SurveyQuestionType.NumberRating:
      return 'Number rating';
    case SurveyQuestionType.SingleText:
      return 'Single text box';
    case SurveyQuestionType.Comment:
      return 'Comment box';
    case SurveyQuestionType.MultipleChoiceCheckbox:
      return 'Checkboxes';
    case SurveyQuestionType.MultipleChoiceRadio:
      return 'Multiple choice';
    case SurveyQuestionType.EmailAddress:
      return 'Email address';
    case SurveyQuestionType.Dropdown:
      return 'Dropdown';
    case SurveyQuestionType.Location:
      return 'Location';
    case SurveyQuestionType.Acel:
      return 'ACEL';
    case SurveyQuestionType.MultiQuestion:
      return 'Multiple Boxes';
    default:
      return '';
  }
};

export const getCriteriaLabel = (key: string) => {
  return recipientsCriteriaLabels.find((e) => e.key === key)?.label || '';
};

export const getConditionLabel = (key: string) => {
  return recipientsConditionLabels.find((e) => e.key === key)?.label || '';
};

export const getCalories = (serviceType: TravelPlanServiceType, duration: number): number => {
  switch (serviceType) {
    case 'Walk':
      return Math.round(WalkingCaloriesPerSecond * duration);
    case 'Bike':
      return Math.round(CycleCaloriesPerSecond * duration);
    default:
      return 0;
  }
};

export const getComponentFilename = (questionType: SurveyQuestionType) => {
  switch (questionType) {
    case SurveyQuestionType.Acel:
      return defineAsyncComponent(() => import('@/views/Survey/Question/Acel.vue'));
    case SurveyQuestionType.Comment:
      return defineAsyncComponent(() => import('@/views/Survey/Question/Comment.vue'));
    case SurveyQuestionType.Dropdown:
      return defineAsyncComponent(() => import('@/views/Survey/Question/MultipleChoice.vue'));
    case SurveyQuestionType.EmailAddress:
      return defineAsyncComponent(() => import('@/views/Survey/Question/EmailAddress.vue'));
    case SurveyQuestionType.Location:
      return defineAsyncComponent(() => import('@/views/Survey/Question/Location.vue'));
    case SurveyQuestionType.MultipleChoiceCheckbox:
      return defineAsyncComponent(() => import('@/views/Survey/Question/MultipleChoice.vue'));
    case SurveyQuestionType.MultipleChoiceRadio:
      return defineAsyncComponent(() => import('@/views/Survey/Question/MultipleChoice.vue'));
    case SurveyQuestionType.MultiQuestion:
      return defineAsyncComponent(() => import('@/views/Survey/Question/MultiQuestion.vue'));
    case SurveyQuestionType.NumberRating:
      return defineAsyncComponent(() => import('@/views/Survey/Question/NumberRating.vue'));
    case SurveyQuestionType.SingleText:
      return defineAsyncComponent(() => import('@/views/Survey/Question/SingleText.vue'));
  }
};

export function debounce<T extends Function>(func: T, timeout: number = 300) {
  let timer: number;
  const callable = (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, timeout);
  };
  return <T>(<any>callable);
}

export const findTravelPlanError = (rawString: string): string => {
  // apparently this can't be manipulated on the server
  switch (rawString) {
    case "Cycling directions are in beta. Use caution – This route may contain streets that aren't suitable for cycling.":
      return "Use caution – This route may contain streets that aren't suited for bicycling.";
    case 'Walking directions are in beta. Use caution – This route may be missing pavements or pedestrian paths.':
      return 'Use caution – This route may be missing sidewalks or pedestrian paths.';
    default:
      return rawString;
  }
};

const Link = Quill.import('formats/link');
const PROTOCOL_WHITELIST: Array<string> = [
  'http',
  'https',
  'mailto',
  'tel',
  'radar',
  'rdar',
  'smb',
  'sms',
];
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = PROTOCOL_WHITELIST;

export class CustomLinkSanitizer extends Link {
  public static toast: ToastInterface;

  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank`
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') {
      CustomLinkSanitizer.toast.error('That link is invalid, please use a valid url!');
      return sanitizedUrl;
    }

    // Verify if the URL already have a whitelisted protocol
    const hasWhitelistedProtocol = PROTOCOL_WHITELIST.some((protocol) => {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `http://${sanitizedUrl}`;
  }
}

export const getSnippetLink = (widgetType: WidgetType, widgetId: string | null) => {
  const baseUrl = `${getDomainMwWeb()}/travel-plan`;

  let embedOrwidget = 'embed';

  if (widgetType === WidgetType.Url) {
    embedOrwidget = 'widget';
  }

  const planUrl = `${baseUrl}/${embedOrwidget}/${widgetId}`;

  return planUrl;
};

export const getSnippetLinkOrCode = (widgetType: WidgetType, widgetId: string | null) => {
  const planUrl = getSnippetLink(widgetType, widgetId);

  if (widgetType === WidgetType.Url) {
    return planUrl;
  }

  return `<iframe src="${planUrl}"></iframe>`;
};

export const getPreviousIntsAsArray = (num: number) => {
  const res: number[] = [];
  let i = 0;
  while (i < num) {
    res.push(i);
    i += 1;
  }
  return res;
};
