export const API_KEY = 'AIzaSyAVH3a-5YSN_7R8102lN-d-8KxhpNJe6_s';

// TODO: API key should probably be moved to an env var
// Also it exists in useGoogleMap.ts
export const GMAPS_API_KEY = 'AIzaSyDXiGZnNtw_HLI9XhfttMuvqFuCp-cg38I';
export const GMAPS_DEFAULT_CENTER = { latitude: 54.454985, longitude: -2.8222639 };
export const GMAPS_MARKER_PATH = 'https://myptp.blob.core.windows.net/static/images';

export const GEO_PATH = '/geo'; //'https://liftshare.blob.core.windows.net/static-assets/geojson';

export const ColorBrandPrimary = '#f163c1';
export const ColorBrandSecondary = '#01d2c5';

export const ScopingCostPerAddress = 0.03;
export const CommutingTripsPerYear = 364;
export const CycleCaloriesPerSecond = 0.1208333333333333;
export const WalkingCaloriesPerSecond = 0.07733333333333;

export const ACEL_TIERS = [
  { grade: 'A', width: '30%', rangeLow: 0, rangeHigh: 100, label: '0-100' },
  { grade: 'B', width: '44%', rangeLow: 100, rangeHigh: 300, label: '100-300' },
  { grade: 'C', width: '58%', rangeLow: 300, rangeHigh: 500, label: '300-500' },
  { grade: 'D', width: '72%', rangeLow: 500, rangeHigh: 700, label: '500-700' },
  { grade: 'E', width: '86%', rangeLow: 700, rangeHigh: 900, label: '700-900' },
  { grade: 'F', width: '100%', rangeLow: 900, rangeHigh: 100000, label: '900+' },
];

export const UK_GEOJSON_FEATURE_REGIONS: Array<{ id: number; name: string; filename: string }> = [
  { id: 58446, name: 'Scotland', filename: 'scotland.geojson.json' },
  { id: 156393, name: 'Northern Ireland', filename: 'northern-ireland.geojson.json' },
  { id: 58437, name: 'Wales', filename: 'wales.geojson.json' },
  { id: 151164, name: 'North East', filename: 'north-east-england.geojson.json' },
  { id: 151261, name: 'North West', filename: 'north-west-england.geojson.json' },
  {
    id: 151012,
    name: 'Yorkshire and the Humber',
    filename: 'yorkshire-and-the-humber.geojson.json',
  },
  { id: 151283, name: 'West Midlands', filename: 'west-midlands.geojson.json' },
  { id: 151279, name: 'East Midlands', filename: 'east-midlands.geojson.json' },
  { id: 151336, name: 'East of England', filename: 'east-of-england.geojson.json' },
  { id: 65606, name: 'London', filename: 'london.geojson.json' },
  { id: 51800, name: 'City of London', filename: 'city-of-london.geojson.json' },
  // IDs were the osm_id from the json file, but don't have one for surrey so made one up
  { id: 52000, name: 'Surrey', filename: 'surrey.geojson.json' },
  { id: 151304, name: 'South East', filename: 'south-east-england.geojson.json' },
  { id: 151339, name: 'South West', filename: 'south-west-england.geojson.json' },
];

export const recipientsCriteriaLabels = [
  { key: 'FirstName', label: 'First name' },
  { key: 'LastName', label: 'Last name' },
  { key: 'Email', label: 'Email address' },
  { key: 'HomeAddress', label: 'Home address' },
  { key: 'DateCreatedUtc', label: 'Created date' },
  { key: 'Tags', label: 'Contact tags' },
];

export const recipientsConditionLabels = [
  { key: 'EqualTo', label: 'is equal to' },
  { key: 'NotEqualTo', label: 'is not equal to' },
  { key: 'StartsWith', label: 'starts with' },
  { key: 'EndsWith', label: 'ends with' },
  { key: 'Contains', label: 'contains' },
  { key: 'DoesNotContain', label: 'does not contain' },
  { key: 'hasAtLeastOneOf', label: 'equal to any of' },
  { key: 'hasNoneOf', label: 'not equal to any of' },
  { key: 'Known', label: 'is known' },
  { key: 'Unknown', label: 'is unknown' },
  { key: 'LessThan', label: 'is less than' },
  { key: 'GreaterThan', label: 'is greater than' },
];
