import MWApiBase from './mw-api-base';
import { ENDPOINTS, ENDPOINTS_V2 } from './constants';
import { Methods } from '@/api/legacy/types';
import type { SurveyImageType } from '@/mw-components/enums/survey';
import type {
  WidgetEmailSettings,
  WidgetUpdateStatus,
  Widget,
} from '@/mw-components/types/widgets';
import type { NotificationResponse } from '@/mw-components/types/notifications';
import type { ContactDetailsResponse, ContactsModel } from '@/mw-components/types/contacts';

import type {
  DashboardLowFrequencyPayloadType,
  FeatureManagerResponse,
  HighFrequencyChartDataPayload,
} from '@/api/types';
import type { ContactModel } from '@/types/contacts-types';
import type { TravelPlan } from '@/mw-components/types/travel-plan';
import type { PendingLiftshareAccountsResponse } from '@/mw-components/types/liftshare';
import type { CommunitiesResponse } from '@/mw-components/types/communites';
import type { AccountSettings, AccountSettingsResponse } from '@/mw-components/types/account';

type OnlyClassMethods<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never;
}[keyof T];

interface IApiRetry<T> {
  withRetry(method: OnlyClassMethods<T>, maxRetries: number, retryCount: number): void;
}

export class MWApiV2 extends MWApiBase {
  Communities = () =>
    this.xhr<object, CommunitiesResponse>(Methods.Get, {}, ENDPOINTS_V2.Communities);
  AdminNotification = () =>
    this.xhr<object, NotificationResponse>(Methods.Get, {}, ENDPOINTS_V2.AdminNotifications);
  AdminEmailPreview = (notificationKeyDescription: string, model: object = {}) =>
    this.xhr<object, any>(
      Methods.Post,
      model,
      `${ENDPOINTS_V2.AdminEmailPreview}/${notificationKeyDescription}`,
      true,
    );

  BehaviourChangeBarriersToChange = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.BehaviourChangeBarriersToChange}?cid=${communityId}${queryString}`,
    );
  BehaviourChangeDistanceBreakdown = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.BehaviourChangeDistanceBreakdown}?cid=${communityId}${queryString}`,
    );
  BehaviourChangeModeBreakdown = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.BehaviourChangeModeBreakdown}?cid=${communityId}${queryString}`,
    );
  BehaviourChangeModalShift = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.BehaviourChangeModalShift}?cid=${communityId}${queryString}`,
    );
  BehaviourChangePlanMetrics = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.BehaviourChangePlanMetrics}?cid=${communityId}${queryString}`,
    );
  CommunityTags = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS_V2.CommunityTags}${queryString}`);

  CommuteBoostChallengeImageUpload = (
    formData: object,
    publicChallengeId: string,
    imageType: number,
    version: number,
  ) =>
    this.xhr<object, any>(
      Methods.Post,
      formData,
      `${ENDPOINTS_V2.CommuteBoostChallengeImageUpload}/${publicChallengeId}/${imageType}/${version}`,
      true,
    );

  Contacts = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS_V2.Contacts}${queryString}`);
  ContactDetails = (contactId: string) =>
    this.xhr<object, ContactDetailsResponse>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.Contacts}/${contactId}`,
    );
  ContactsLimit = (communityId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS_V2.ContactsLimit}?cid=${communityId}`);
  ContactAdd = (model: ContactModel) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.ContactAdd);
  ContactDelete = (contactIds: Array<string>) =>
    this.xhr<object, any>(
      Methods.Delete,
      { publicContactIds: contactIds },
      ENDPOINTS_V2.ContactDelete,
    );
  ContactFilteredExport = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.ContactFilteredExport, true);
  ContactsFilterProperties = () =>
    this.xhr<object, any>(Methods.Get, {}, ENDPOINTS_V2.ContactsFilterProperties);

  // Liftshare dashboard
  DashboardLiftshareSummary = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareSummaryData}?cid=${communityId}`,
    );
  DashboardLiftshareMembers = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareMembersData}?cid=${communityId}`,
    );
  DashboardLiftshareJourneys = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareJourneysData}?cid=${communityId}`,
    );
  DashboardLiftshareRequestToShares = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareRequestToSharesData}?cid=${communityId}`,
    );
  DashboardLiftshareTeams = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareTeamsData}?cid=${communityId}`,
    );
  DashboardLiftshareCumulativeMembers = (communityId: string, granularity: number) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareCumulativeMembersData}?cid=${communityId}&dateGranularity=${granularity}`,
    );
  DashboardLiftshareTripAuthenticationsLeaderboard = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareTripAuthenticationsLeaderboardData}?cid=${communityId}`,
    );
  DashboardLiftshareTripAuthentications = (communityId: string, granularity: number) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLiftshareTripAuthenticationsData}?cid=${communityId}&dateGranularity=${granularity}`,
    );

  // Low-frequency dashboard
  DashboardLowFrequencyChart = (model: DashboardLowFrequencyPayloadType) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.DashboardLowFrequencyChartData, true);
  DashboardLowFrequencySummary = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.DashboardLowFrequencySummaryData}?cid=${communityId}`,
    );

  FeatureManager = () =>
    this.xhr<object, FeatureManagerResponse>(Methods.Get, {}, ENDPOINTS_V2.FeatureManager);
  FilterContacts = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.FilterContacts, true);

  // High-frequency
  HighFrequencyGetDataSources = () =>
    this.xhr<object, any>(Methods.Get, {}, ENDPOINTS_V2.HighFrequencyDataSources);
  HighFrequencyGetImports = () =>
    this.xhr<object, any>(Methods.Get, {}, ENDPOINTS_V2.HighFrequencyImports);
  HighFrequencyPostImport = (model: FormData) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.HighFrequencyImports);
  HighFrequencyDeleteImport = (id: string) =>
    this.xhr<object, any>(Methods.Delete, { id }, ENDPOINTS_V2.HighFrequencyImportDelete);
  HighFrequencyDeleteImports = (ids: string[]) =>
    this.xhr<object, any>(Methods.Post, { ids }, ENDPOINTS_V2.HighFrequencyDeleteImports);
  HighFrequencyGetChartData = (model: HighFrequencyChartDataPayload) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.HighFrequencyChartData);
  //HighFrequencyGetSummaryData = (communityId: string) => this.xhr<object, any>(Methods.Post, {}, `${ENDPOINTS_V2.HighFrequencySummaryData}?cid=${communityId}`);

  TravelPlans = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.TravelPlans);
  TravelPlanBatches = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.TravelPlanBatches);
  TravelPlanResult = (planId: string, queryString: string = '') =>
    this.xhr<{}, TravelPlan.PlanResult>(
      Methods.Get,
      {},
      `${ENDPOINTS.TravelPlanResults}/${planId}${queryString}`,
    );
  NPSCheck = (category: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS_V2.NPSCheck}/${category}`);
  NPSPost = (model: object) => this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.NPSCheck);
  NPSUpdate = (model: object) => this.xhr<object, any>(Methods.Put, model, ENDPOINTS_V2.NPSCheck);
  PendingLiftshareAccountsBatchSave = (model: Object) =>
    this.xhr<object, any>(Methods.Put, model, ENDPOINTS_V2.PendingLiftshareAccountsBatch);
  PendingLiftshareAccountsBatchDelete = (batchId: String) =>
    this.xhr<object, any>(
      Methods.Delete,
      {},
      `${ENDPOINTS_V2.PendingLiftshareAccountsBatch}/${batchId}`,
    );
  PendingLiftshareAccountsBatchDetails = (batchId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.PendingLiftshareAccountsBatch}/${batchId}`,
    );
  PendingLiftshareAccounts = (communityId: string) =>
    this.xhr<object, PendingLiftshareAccountsResponse>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.PendingLiftshareAccountsBatch}?cid=${communityId}`,
    );
  PendingLiftshareAccountsTestEmail = (model: Object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS_V2.PendingLiftshareAccountsTestEmail);
  PendingLiftshareAccountsBatchFinalize = (model: Object) =>
    this.xhr<object, any>(Methods.Put, model, ENDPOINTS_V2.PendingLiftshareAccountsBatchFinalize);
  PendingLiftshareAccountsImageUpload = (formData: object) =>
    this.xhr<object, any>(
      Methods.Put,
      formData,
      `${ENDPOINTS_V2.PendingLiftshareAccountsImageUpload}`,
    );
  SurveyAvailableCommunities = () =>
    this.xhr<object, any>(
      Methods.Get, 
      {}, 
      ENDPOINTS_V2.SurveyAvailableCommunities
    );
  SurveyExportAllData = (surveyId: string, questionId: string, dateFrom: string, dateTo: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS_V2.SurveyExportAllData}/${surveyId}/${questionId}?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      true,
    );
  SurveySummaryQuestions = (id: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS_V2.SurveySummaryQuestions}/${id}`);
}

export class MWApi extends MWApiBase {
  // We should do our best to type these! Will make our lives and other devs lives much easier... Here is a start and the means to do so.
  AccountsList = () => this.xhr<object, any>(Methods.Get, {}, ENDPOINTS.AccountsList);
  AcelAssumptions = () => this.xhr<object, any>(Methods.Get, {}, ENDPOINTS.AcelAssumptions);
  AcelNational = () => this.xhr<object, any>(Methods.Get, {}, ENDPOINTS.AcelNational);
  AcelCreate = (model: object) => this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AcelCreate);
  AcelCreateFromSurvey = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AcelCreateFromSurvey);
  AcelReportDelete = (reportId: object) =>
    this.xhr<object, any>(Methods.Delete, {}, `${ENDPOINTS.AcelReportDelete}/${reportId}`);
  AcelReportRename = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AcelReportRename);
  AcelReports = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.AcelReports}/${queryString}`);
  AcelReportDetails = (reportId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.AcelReportDetails}/${reportId}`);
  AccountSettings = () =>
    this.xhr<object, AccountSettingsResponse>(Methods.Get, {}, ENDPOINTS.AccountSettings);
  AcelSetPrimary = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AcelSetPrimary);
  AcelSetPrivacy = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AcelSetPrivacy);
  AcelSetTarget = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AcelSetTarget);
  AdminPendingLiftshare = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.AdminPendingLiftshare}/${queryString}`);
  AdminPendingLiftshareCreate = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AdminPendingLiftshareCreate);
  AcelSurveys = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.AcelSurveys}/${queryString}`);
  AcelSurveyData = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AcelSurveyData);
  AutocompleteProxy = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.AutocompleteProxy, false);
  BehaviourChangeBarriersToChange = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.BehaviourChangeBarriersToChange}?cid=${communityId}${queryString}`,
    );
  BehaviourChangeDistanceBreakdown = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.BehaviourChangeDistanceBreakdown}?cid=${communityId}${queryString}`,
    );
  BehaviourChangeModalShift = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.BehaviourChangeModalShift}?cid=${communityId}${queryString}`,
    );
  BehaviourChangeModeBreakdown = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.BehaviourChangeModeBreakdown}?cid=${communityId}${queryString}`,
    );
  Contacts = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.Contacts}${queryString}`);
  ContactAdd = (model: object) => this.xhr<object, any>(Methods.Post, model, ENDPOINTS.ContactAdd);
  ContactDelete = (contactId: string) =>
    this.xhr<object, any>(Methods.Delete, {}, `${ENDPOINTS.ContactDelete}/${contactId}`);
  ContactDetails = (contactId: string) =>
    this.xhr<object, ContactsModel>(Methods.Get, {}, `${ENDPOINTS.ContactDetails}/${contactId}`);
  ContactImportHeaders = (model: FormData) =>
    this.xhr<FormData, any>(Methods.Post, model, ENDPOINTS.ContactImportHeaders, true);
  ContactPreviousImports = (communityId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ContactPreviousImports}/${communityId}`);
  ContactUpdate = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.ContactUpdate);
  ContactUpdateTags = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.ContactUpdateTags);
  ContactsUpload = (model: FormData) =>
    this.xhr<FormData, any>(Methods.Post, model, ENDPOINTS.ContactsUpload, true);
  ContactsEmailTaken = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.ContactsEmailTaken, true);
  ContactsImportProgress = (id: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ContactsImportProgress}/${id}`);
  ContactsPreviousImportFailures = (id: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ContactsPreviousImportFailures}/${id}`);
  CustomTexts = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.CustomTexts}${queryString}`);
  CustomTextDelete = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.CustomTextDelete);
  CustomTextSave = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.CustomTextSave);
  GeocodeProxy = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.GeocodeProxy, false);
  // Check this endpoint, typed as object, should probs be FormData
  ImageUpload = (formData: object, publicSurveyId: string, imageType: SurveyImageType) =>
    this.xhr<object, any>(
      Methods.Post,
      formData,
      `${ENDPOINTS.ImageUpload}?publicSurveyId=${publicSurveyId}&imageType=${imageType}`,
      true,
    );
  PasswordResetConfirm = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.PasswordResetConfirm, false);
  PasswordResetEmail = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.PasswordResetEmail, false);
  PersonalisationTokens = () =>
    this.xhr<object, any>(Methods.Get, {}, ENDPOINTS.PersonalisationTokens, false);
  PlatformUsageLogins = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.PlatformUsageLogins}${queryString}`, true);
  ScopingContacts = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.ScopingContacts);
  ScopingDataAggregated = (scopingReportId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.ScopingDataAggregated}/${scopingReportId}`,
      true,
    );
  ScopingDataPlan = (scopingReportId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingDataPlan}/${scopingReportId}`);
  ScopingResults = (scopingReportId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingResults}/${scopingReportId}`, true);
  ScopingMap = (scopingReportId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingMap}/${scopingReportId}`, false);
  ScopingMapPrivate = (scopingReportId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingMap}/${scopingReportId}`, true);
  ScopingReportAbort = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.ScopingReportAbort);
  ScopingReportContinue = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.ScopingReportContinue);
  ScopingReport = (scopingReportId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingReport}/${scopingReportId}`, true);
  ScopingReportStatus = (scopingReportId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingReportStatus}/${scopingReportId}`);
  ScopingSummary = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingSummary}${queryString}`);
  ScopingFullSummary = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.ScopingFullSummary}${queryString}`);
  ScopingUpload = (model: FormData) =>
    this.xhr<FormData, any>(Methods.Post, model, ENDPOINTS.ScopingUpload, true);
  Surveys = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.Surveys}${queryString}`);

  // NEW APIS ///////
  // Use { publicSurveyId, annotationContent } for header annotation, use { publicSurveyId, surveyQuestionId, annotationContent } for question annotation
  SurveyAnnotation = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveyAnnotation);
  SurveyHeadingAnnotationDelete = (publicSurveyId: string) =>
    this.xhr<object, any>(
      Methods.Delete,
      {},
      `${ENDPOINTS.SurveyHeadingAnnotationDelete}?publicSurveyId=${publicSurveyId}`,
    );
  SurveyQuestionAnnotationDelete = (publicSurveyId: string, surveyQuestionId: string) =>
    this.xhr<object, any>(
      Methods.Delete,
      {},
      `${ENDPOINTS.SurveyQuestionAnnotationDelete}?publicSurveyId=${publicSurveyId}&surveyQuestionId=${surveyQuestionId}`,
    );
  SurveyReportPrivacy = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveyReportPrivacy);
  SurveyPublicAnalyticsId = (publicSurveyId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyPublicAnalyticsId}/${publicSurveyId}`,
    );
  ///////////////////

  // One to look at, conditional ( may or may not require auth )
  SurveyAnalytics = (publicId: string, queryString: string) =>
    this.xhr(Methods.Get, {}, `${ENDPOINTS.SurveyAnalytics}/${publicId}${queryString}`, false);
  SurveyClone = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveyClone);
  SurveyContacts = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveyContacts);
  SurveyEmailMetrics = (publicId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyEmailMetrics}/${publicId}${queryString}`,
    );
  SurveyLinkMetrics = (publicId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyLinkMetrics}/${publicId}${queryString}`,
    );
  SurveyData = (publicId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.SurveyData}/${publicId}`);
  SurveyDelete = (publicId: string) =>
    this.xhr<object, any>(Methods.Delete, {}, `${ENDPOINTS.SurveyDelete}/${publicId}`);
  SurveyDeleteResponse = (
    publicSurveyId: string,
    anonymousResponderId: string,
    batchAliasId?: string,
  ) => {
    const id = batchAliasId ? `/${batchAliasId}` : '';
    const query = `${publicSurveyId}/${anonymousResponderId}` + id;
    return this.xhr<object, any>(Methods.Delete, {}, `${ENDPOINTS.SurveyDeleteResponse}/${query}`);
  };
  SurveyExport = (publicId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.SurveyExport}/${publicId}`, true);
  SurveyIndividualResponses = (publicSurveyId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyIndividualResponses}/${publicSurveyId}${queryString}`,
    );
  SurveyIndividualUserResponse = (publicSurveyId: string, anonymousResponderId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyIndividualUserResponse}/${publicSurveyId}/${anonymousResponderId}`,
    );
  SurveyPause = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveyPause);
  SurveyPublish = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveyPublish);
  SurveyResponses = (publicId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyResponses}/${publicId}${queryString}`,
    );
  // conditional
  SurveyResponsesOverTime = (publicId: string, queryString: string, requiresAuth?: boolean) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyResponsesOverTime}/${publicId}${queryString}`,
      requiresAuth !== undefined ? requiresAuth : false,
    );
  SurveySave = (model: object) => this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveySave);
  SurveySendMoreContacts = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveySendMoreContacts);
  SurveySendTestEmail = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveySendTestEmail);
  SurveyShareLink = (publicSurveyId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.SurveyShareLink}/${publicSurveyId}`);
  // conditional
  SurveySummary = (publicSurveyId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.SurveySummary}/${publicSurveyId}`, true);
  // conditioanl
  SurveySummaryByQuestion = (publicSurveyId: string, questionId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveySummaryByQuestion}/${publicSurveyId}/${questionId}${queryString}`,
      true,
    );
  SurveyTemplates = () => this.xhr<object, any>(Methods.Get, {}, ENDPOINTS.SurveyTemplates);
  SurveyTemplateData = (templateId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.SurveyTemplateData}?publicId=${templateId}`,
    );
  TravelPlans = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlans);
  TravelPlanArchive = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanArchive);
  TravelPlanBatches = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanBatches);
  TravelPlanBatchStatus = (batchId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.TravelPlanBatchStatus}?id=${batchId}`);
  TravelPlanDestinations = (communityId: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.TravelPlanDestinations}?cid=${communityId}`,
    );
  TravelPlanDetails = (queryString: string) =>
    this.xhr<object, TravelPlan.Batch>(
      Methods.Get,
      {},
      `${ENDPOINTS.TravelPlanDetails}${queryString}`,
    );
  TravelPlanEmail = (publicId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.TravelPlanEmail}/${publicId}`);
  TravelPlanFailed = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.TravelPlanFailed}${queryString}`);
  TravelPlanFinalize = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanFinalize);
  TravelPlanImageUpload = (formData: object, publicBatchId: string) =>
    this.xhr<object, any>(
      Methods.Post,
      formData,
      `${ENDPOINTS.TravelPlanImageUpload}?publicBatchId=${publicBatchId}`,
      true,
    );
  TravelPlanNotifyCompletion = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanNotifyCompletion);
  TravelPlanProcessedPlans = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanProcessedPlans);
  TravelPlanResults = (planId: string, queryString: string = '') =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.TravelPlanResults}/${planId}${queryString}`,
      false,
    );
  TravelPlanSave = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanSave);
  TravelPlanStartBatch = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanStartBatch);
  TravelPlanSendEmails = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanSendEmails);
  TravelPlanSendTestEmails = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanSendTestEmails);
  TravelPlanSendMultiple = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanSendMultiple);
  TravelPlanSurveyResults = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.TravelPlanSurveyResults}${queryString}`);
  TravelPlanSurveyEmailMetrics = (communityId: string, queryString: string) =>
    this.xhr<object, any>(
      Methods.Get,
      {},
      `${ENDPOINTS.TravelPlanSurveyEmailMetrics}/${communityId}${queryString}`,
    );
  TravelPlanViews = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.TravelPlanViews}${queryString}`);
  TravelPlanRateLimitStatus = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.TravelPlanRateLimitStatus}${queryString}`);
  Widgets = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.Widgets}${queryString}`);
  WidgetsDelete = (queryString: string) =>
    this.xhr<object, any>(Methods.Delete, {}, `${ENDPOINTS.WidgetsDelete}${queryString}`);
  Widget = (queryString: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.Widget}${queryString}`, false);
  WidgetsUpdateStatus = (model: WidgetUpdateStatus) =>
    this.xhr<WidgetUpdateStatus, any>(Methods.Post, model, ENDPOINTS.WidgetsUpdateStatus);
  WidgetsUpsert = (model: { widget: Widget; emailSettings: WidgetEmailSettings }) =>
    this.xhr<{ widget: Widget; emailSettings: WidgetEmailSettings }, any>(
      Methods.Post,
      model,
      ENDPOINTS.WidgetsUpsert,
    );
  WidgetsValidateLocations = (model: object) =>
    this.xhr(Methods.Post, model, '/dashboard/widgets/validate-locations');
  SurveyDataPublic = (publicId: string, contactId: string) => {
    let path = publicId;

    if (contactId) {
      path = `${path}/${contactId}`;
    }
    return this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.SurveyDataPublic}/${path}`, false);
  };
  EmailPreferences = (contactId: string) =>
    this.xhr<object, any>(Methods.Get, {}, `${ENDPOINTS.EmailPreferences}/${contactId}`, false);
  SurveyAnswer = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.SurveyAnswer, false);
  TravelPlanFeedback = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanFeedback, false);
  TravelPlanWidgetSearch = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.TravelPlanWidgetSearch, false);
  // This one was in mw web project, but looks like it should be private
  UpdateEmailPreferences = (model: object) =>
    this.xhr<object, any>(Methods.Post, model, ENDPOINTS.UpdateEmailPreferences, true);
  UpdateAccountSettings = (model: AccountSettings) =>
    this.xhr<object, AccountSettings>(Methods.Post, model, ENDPOINTS.UpdateAccountSettings, true);
}
