export enum DashboardAppMutationTypes {
  UPDATE_COMMUNITY = 'UPDATE_COMMUNITY',
  UPDATE_COMMUNITY_ROLES = 'UPDATE_COMMUNITY_ROLES',
  UPDATE_FILTERED_ROLES = 'UPDATE_FILTERED_ROLES',
  UPDATE_ACEL_TABS = 'UPDATE_ACEL_TABS',
  UPDATE_ACEL_MODAL_SHIFT = 'UPDATE_ACEL_MODAL_SHIFT',
  SET_CHECKED_ACCOUNT = 'SET_CHECKED_ACCOUNT',
  SET_SEARCH_TERM = 'SET_SEARCH_TERM',
  SET_COMMUNITY_FAIL = 'SET_COMMUNITY_FAIL',
}
