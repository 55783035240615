import type { AccountSettings } from '@/mw-components/types/account';
import { MWApi } from '@/api/legacy';

const api = new MWApi();

//TODO: Check LS for default per page, if not found, get from API
export default {
  getAccountDefaultPerPage: async (): Promise<number> => {
    try {
      const accountPrefs: AccountSettings = await api.AccountSettings();

      if (accountPrefs.paginationRowsPerPageDefault) {
        return Number(accountPrefs.paginationRowsPerPageDefault);
      }
      return 25;
    } catch {
      return 25;
    }
  },

  //TODO: Have local storage remember preferences
  // getAccountDefaultPerPage: async (): Promise<number> => {
  //   const globalStore = useGlobalStore();
  //   const lsPerPage: string | null = LS.get('perPage');

  //   //if localstorage has a value, return that
  //   if (lsPerPage) {
  //     globalStore.setPaginationPageSize(Number(lsPerPage));
  //     return Number(lsPerPage);
  //   }

  //   //if not, get from API
  //   let accountPrefs: AccountSettings = await api.AccountSettings();
  //   if (accountPrefs.paginationRowsPerPageDefault) {
  //     globalStore.setPaginationPageSize(Number(accountPrefs.paginationRowsPerPageDefault));
  //     return Number(accountPrefs.paginationRowsPerPageDefault);
  //   }
  //   globalStore.setPaginationPageSize(25);
  //   return 25;
  // },
};
