// MW DASH ROLES
// SuperAdmin = IT Staff, Admin = Other staff
// <MODULE>Admin has full access to that module's features
// <MODULE>Monitor has reduced access, usually only able to view data, and not modify it

export type UserRole =
  | 'SuperAdmin'
  | 'Admin'
  | 'CommunityAdmin'
  | 'CommunityMonitor'
  | 'MemberActivator'
  | 'MemberExportAdmin'
  | 'RequiresClassicReporting'
  | 'CommunityParkingPermit'
  | 'PaidSupportLicence'
  | 'TravelPlanAdmin'
  | 'TravelPlanMonitor'
  | 'ScopingUser'
  | 'SurveyAndAcelAdmin'
  | 'SurveyAndAcelMonitor'
  | 'ContactAdmin'
  | 'ContactMonitor'
  | 'AcelTargetAdmin';

export const ROLE_SUPERADMIN: Array<UserRole> = ['SuperAdmin'];
export const ROLE_ADMIN: Array<UserRole> = ['SuperAdmin', 'Admin'];

export const ROLE_CONTACTS: Array<UserRole> = [
  'Admin',
  'ContactAdmin',
  'ContactMonitor',
  'SurveyAndAcelAdmin',
  'SurveyAndAcelMonitor',
  'TravelPlanAdmin',
  'TravelPlanMonitor',
];
export const ROLE_CONTACTS_ADMIN: Array<UserRole> = [
  'Admin',
  'ContactAdmin',
  'SurveyAndAcelAdmin',
  'TravelPlanAdmin',
];

export const ROLE_SURVEY: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin', 'SurveyAndAcelMonitor'];
export const ROLE_SURVEY_ADMIN: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin'];

export const ROLE_ACEL: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin', 'SurveyAndAcelMonitor'];
export const ROLE_ACEL_ADMIN: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin'];

export const ROLE_SCOPING: Array<UserRole> = ['Admin', 'ScopingUser'];

export const ROLE_TRAVELPLAN: Array<UserRole> = ['Admin', 'TravelPlanAdmin', 'TravelPlanMonitor'];
export const ROLE_TRAVELPLAN_ADMIN: Array<UserRole> = ['Admin', 'TravelPlanAdmin'];

export const ROLE_ASSESS: Array<UserRole> = ['Admin', 'AcelTargetAdmin'];
