/**
 * @todo For clarity, append the word 'Home' to the end of each modules homepage's key, e.g. Instead of contacts: {}, make it contactsHome: {}
 */
export default {
  acelDashboard: {
    title: 'ACEL dashboard',
    description: '',
  },
  acelGenerator: {
    title: 'ACEL generator',
    description:
      'Calculating your ACEL (Average Commuter Emissions Level) for your organisation will allow you to quickly identify the modes of travel that are emitting the highest levels of CO2, allowing you to set targets and implement changes to reduce this.',
  },
  acelReports: {
    title: 'Saved ACEL reports',
    description: '',
  },
  adminHighFrequencyImports: {
    title: 'Commute activity imports',
    description: '',
  },
  commuteIQHome: {
    title: 'CommuteIQ',
    description: '',
  },
  commuteBoostCreateFormChallenge: {
    title: 'Challenge details',
    description:
      'The Challenge details section is concerned with the details seen by the end user in the app. Use the app preview on the right to guide your text content, and choice of images.',
  },
  commuteBoostCreateFormCriteria: {
    title: 'Qualification criteria',
    description:
      'The Qualification criteria are used by the system for identifying commutes that qualify towards the awarding of a Boost.',
  },
  commuteBoostCreateFormBoost: {
    title: 'Boost details',
    description:
      'The Boost details cover the details shown to the user in the app as well as some system details arounds issuing and redemption.',
  },
  commuteBoostCreateFormTerms: {
    title: 'Terms',
    description:
      'Terms are displayed on an additional screen in the app and should be the full terms and conditions of the challenge.',
  },
  commuteBoostCreateFormPublish: {
    title: 'Publish challenge',
    description:
      "Select your publish option, then validate the information you have entered. If everything is correct, you can publish your challenge, otherwise you'll be prompted to fix any issues. Once published, you can unpublish the challenge at any time.",
  },
  contacts: {
    title: 'Contacts',
    description: '',
  },
  contactsPreviousImports: {
    title: 'Previous imports',
    description: '',
  },
  highFrequencyDashboard: {
    title: 'Commute activity dashboard',
    description:
      'This dashboard displays data collected at a high frequency so you can track uptake and trends across all of your commuting modes. Get in touch with us if you have a dataset that you would to regularly capture against your other commuting modes.',
  },
  liftshareDashboard: {
    title: 'Liftshare dashboard',
    description:
      'This dashboard displays an overview of your statistics for your Liftshare community.',
  },
  liftshareInvites: {
    title: 'Liftshare invites',
    description:
      "Liftshare invites allow you to invite your contacts to join your Liftshare community. Non-existing Liftshare members will receive an email inviting them to claim their ready-to-go Liftshare account and be placed into your organisation's community. If a contact is already a member of Liftshare but not in your community, then an invite to join the the community will be sent instead.",
  },
  lowFrequencyDashboard: {
    title: 'Commute emissions dashboard',
    description:
      'This dashboard is here to show you how you are tracking against your commuter emissions. Data can be collected from ‘low frequency’ sources meaning that there is a rich dataset but this data is not collected regularly (For example, annual travel surveys).  Viewing your data in this way, allows you to quickly identify the modes of travel that are emitting the highest levels of CO2e over the years, allowing you to set targets and implement changes to reduce this.',
  },
  ptpWidgets: {
    title: 'Travel plan widgets',
    description: '',
  },
  surveysHome: {
    title: 'All surveys',
    description: '',
  },
  surveySelectTemplate: {
    title: 'Choose your survey template',
    description: '',
  },
  zeeloDashboard: {
    title: 'Zeelo dashboard',
    description: '',
  },
};
