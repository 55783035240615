export enum TravelPlanMutationTypes {
  RESET_STATE = 'RESET_STATE',
  SET_LOADING = 'SET_LOADING',
  SET_CONTENTREADY = 'SET_CONTENTREADY',
  SET_STRING_PROP = 'SET_STRING_PROP',
  SET_ROUTES = 'SET_ROUTES',
  SET_FACTS = 'SET_FACTS',
  SET_OPTIONS = 'SET_OPTIONS',
  SET_TRAVEL_MODE = 'SET_TRAVEL_MODE',
  SET_TRAVEL_MODE_BY_CLICKING_LIFTSHARE_MARKER = 'SET_TRAVEL_MODE_BY_CLICKING_LIFTSHARE_MARKER',
  SET_BOUNDS = 'SET_BOUNDS',
  SET_CONTACTS = 'SET_CONTACTS',
  SET_HEADER_IMAGE_URL = 'SET_HEADER_IMAGE_URL',
}
