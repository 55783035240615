import {
  WidgetButtonFontSize,
  WidgetButtonRadius,
  WidgetRestrictionType,
  WidgetSendEmailOption,
  WidgetStatus,
  WidgetType,
} from '@/mw-components/enums/widgets';

import type { Widget, WidgetEmailSettings } from '@/mw-components/types/widgets';

import { TravelPlanEmailTabStatus } from '@/mw-components/enums/travel-plan';

import { store } from '@/mw-components/store';

import moment from 'moment';

export type Tabs = {
  titles: Array<string>;
  active: number | null;
};

export type EmailTabs = {
  titles: Array<string>;
  active: TravelPlanEmailTabStatus;
};

export type CachedWidgetProps = {
  [prop in keyof Widget]?: Widget[prop];
};

export type CachedEmailSettingProps = {
  [prop in keyof WidgetEmailSettings]?: WidgetEmailSettings[prop];
};

export type FetchedGeoJSON = {
  [key: number]: GeoJSON.Feature | null;
};

export type WidgetErrorDescriptor = {
  description: string;
  element: keyof Widget | keyof WidgetEmailSettings;
  onTab: number | null;
  onEmailTab: TravelPlanEmailTabStatus | null;
  errors: Array<string>;
};

export type WidgetSettingsErrors = {
  [prop in keyof Widget]?: WidgetErrorDescriptor;
};

export type WidgetEmailSettingsErrors = {
  [prop in keyof WidgetEmailSettings]?: WidgetErrorDescriptor;
};

export enum CreateWidgetModalType {
  Exit,
}

export type Modal = {
  type: CreateWidgetModalType;
  active: boolean;
};

export type WidgetErrors = WidgetSettingsErrors & WidgetEmailSettingsErrors;

export type State = {
  loading: boolean;
  contentReady: boolean;
  unsavedChanges: boolean;
  modal: Modal;
  tabs: Tabs;
  emailTabs: EmailTabs;
  geofencingActive: boolean;
  geoFencingMapActive: boolean;
  selectedGeoFences: Array<number>;
  isEditingWidgetName: boolean;
  cachedWidgetProps: CachedWidgetProps;
  cachedEmailSettingProps: CachedEmailSettingProps;
  errors: WidgetErrors;
  widget: Widget;
  emailSettings: WidgetEmailSettings;
  lastSave: {
    geoFencesActive: boolean;
    selectedGeoFences: Array<number>;
    widget: Widget;
    emailSettings: WidgetEmailSettings;
  };
  customTexts: Array<{ label: string; value: string }>;
  showWidgetLinkModal: boolean;
};

export const emailSettingsDefaults = (): WidgetEmailSettings => ({
  behaviorChangeSurveyButtonText: 'Answer the survey',
  behaviorChangeSurveyCustomtext: null,
  behaviorChangeSurveyEnabled: false,
  behaviorChangeSurveySigniture: '<p>Best Wishes, The Team.</p>',
  behaviorChangeSurveySubject: '{FirstName}, have you changed your commuting behaviour?',
  behaviourChangeSurveyGreeting: 'Hi {FirstName},',
  emailSettingCompanyName: '',
  emailSettingButtonColour: '#01d2c5',
  emailSettingFromAddress: '',
  emailSettingFromName: '',
  emailSettingHeaderImageUrl:
    'https://liftshare.blob.core.windows.net/static-assets/mobilityways/dashboard/mw_full_logo_220.png',
  emailSettingIncludeSubscriptionPreferences: false,
  followUpSurveyButtonText: 'Answer the survey',
  followUpSurveyCustomtext: null,
  followUpSurveySigniture: '<p>Best Wishes, The Team.</p>',
  followUpSurveySubject: '{FirstName}, have you changed your commuting behaviour?',
  followUpSurveyGreeting: 'Hi {FirstName}',
  followupSurveyEnabled: false,
  sendBehaviorChangeSurveyDelayInDays: 0,
  sendBehaviorChangeSurveyReminderDelayInDays: 14,
  sendFollowUpSurveyDelayInDays: 90,
  sendFollowUpSurveyReminderDelayInDays: 0,
  sendPlanEmailsDelayInMinutes: 0,
  travelPlanButtonText: 'View your travel plan',
  travelPlanCustomText: null,
  travelPlanGreeting: 'Hi {FirstName},',
  travelPlanIntroduction: '',
  travelPlanSigniture: '<p>Best Wishes, The Team.</p>',
  travelPlanSubject: '{FirstName}, here is your Personal Travel Plan',
  whenToSendPlanEmails: 0,
});

// @ts-ignore
export const widgetDefaults = (): Widget => ({
  id: null,
  communityId: store.getters['dashboardApp/getSelectedCommunity'],
  status: WidgetStatus.Draft,
  type: WidgetType.Iframe,
  sendEmail: WidgetSendEmailOption.Send,
  askForName: true,
  askForEmail: true,
  name: 'Widget Name',
  title: 'Get your personal travel plan',
  plansGenerated: null,
  introduction: 'Download your Personal Travel Plan below',
  customButtonText: 'Get my personal travel plan',
  customButtonRadius: WidgetButtonRadius.Small,
  customButtonFontSize: WidgetButtonFontSize.XLarge,
  customColour: '#01d2c5',
  locations: [],
  createdByAdmin: null,
  updatedByAdmin: null,
  saveToContacts: true,
  restrictionType: WidgetRestrictionType.None,
  geographyRestrictions: { features: [], type: 'FeatureCollection' as 'FeatureCollection' },
  defaultArriveBy: true,
  defaultReturnBy: false,
  defaultOutboundTime: '08:00',
  defaultReturnTime: '17:00',
});

type WidgetProps = WidgetEmailSettings | Widget;

export const validators = {
  useDefault: (prop: keyof Widget | keyof WidgetEmailSettings) => {
    return (state: State) => {
      if (state.widget.hasOwnProperty(prop)) {
        if (state.widget[prop as keyof WidgetProps]) return { newValue: null, msg: null };
        if (state.emailSettings[prop as keyof WidgetEmailSettings])
          return { newValue: null, msg: null };
      }
      return {
        newValue: state.widget.hasOwnProperty(prop)
          ? (widgetDefaults()[prop as keyof WidgetProps] as string)
          : (emailSettingsDefaults()[prop as keyof WidgetProps] as string),
        msg: null,
      };
    };
  },
  requiredField: (prop: keyof Widget | keyof WidgetEmailSettings) => {
    return (state: State) => {
      return {
        newValue: null,
        msg: state.widget.hasOwnProperty(prop)
          ? !state.widget[prop as keyof Widget]
            ? 'This is a required field'
            : null
          : !state.emailSettings[prop as keyof WidgetEmailSettings]
          ? 'This is a required field'
          : null,
      };
    };
  },
  requiresLocationOrGeoRestriction: () => {
    return (state: State) => {
      return {
        newValue: null,
        msg:
          state.widget.locations &&
          state.widget.locations.length < 1 &&
          state.selectedGeoFences &&
          state.selectedGeoFences.length < 1
            ? 'Widgets require either a starting location or geo restrictions to be set.'
            : null,
      };
    };
  },
  leaveBeforeReturnTime: (flip: boolean = false) => {
    return (state: State) => {
      const leaveAt = moment(state.widget.defaultOutboundTime, 'HH:mm');
      const returnAt = moment(state.widget.defaultReturnTime, 'HH:mm');
      return {
        newValue: null,
        msg: leaveAt.isBefore(returnAt)
          ? null
          : flip
          ? 'should be after return time.'
          : 'should be before return time.',
      };
    };
  },
};

export const validationChecks: {
  [key: string]: {
    validators: Array<(state: State) => { newValue: string | null; msg: string | null }>;
    onTab: number | null;
    emailTab: TravelPlanEmailTabStatus | null;
  };
} = {
  customButtonText: {
    validators: [validators.useDefault('customButtonText')],
    onTab: 0,
    emailTab: null,
  },
  title: {
    validators: [validators.useDefault('title')],
    onTab: 1,
    emailTab: null,
  },
  name: {
    validators: [validators.useDefault('name')],
    onTab: null,
    emailTab: null,
  },
  introduction: {
    validators: [validators.useDefault('introduction')],
    onTab: 1,
    emailTab: null,
  },
  geographyRestrictions: {
    validators: [validators.requiresLocationOrGeoRestriction()],
    onTab: 1,
    emailTab: null,
  },
  locations: {
    validators: [validators.requiresLocationOrGeoRestriction()],
    onTab: 1,
    emailTab: null,
  },
  defaultOutboundTime: {
    validators: [validators.leaveBeforeReturnTime()],
    onTab: 1,
    emailTab: null,
  },
  defaultReturnTime: {
    validators: [validators.leaveBeforeReturnTime(true)],
    onTab: 1,
    emailTab: null,
  },
  emailSettingCompanyName: {
    validators: [validators.requiredField('emailSettingCompanyName')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.TravelPlanEmail,
  },
  emailSettingFromName: {
    validators: [validators.requiredField('emailSettingFromName')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.TravelPlanEmail,
  },
  travelPlanSubject: {
    validators: [validators.requiredField('travelPlanSubject')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.TravelPlanEmail,
  },
  travelPlanSigniture: {
    validators: [validators.requiredField('travelPlanSigniture')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.TravelPlanEmail,
  },
  travelPlanButtonText: {
    validators: [],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.TravelPlanEmail,
  },
  behaviorChangeSurveySubject: {
    validators: [validators.requiredField('behaviorChangeSurveySubject')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.BehaviourChangeEmail,
  },
  behaviorChangeSurveySigniture: {
    validators: [validators.requiredField('behaviorChangeSurveySigniture')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.BehaviourChangeEmail,
  },
  behaviorChangeSurveyButtonText: {
    validators: [],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.BehaviourChangeEmail,
  },
  followUpSurveySubject: {
    validators: [validators.requiredField('followUpSurveySubject')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.FollowUpEmail,
  },
  followUpSurveySigniture: {
    validators: [validators.requiredField('followUpSurveySigniture')],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.FollowUpEmail,
  },
  followUpSurveyButtonText: {
    validators: [],
    onTab: 2,
    emailTab: TravelPlanEmailTabStatus.FollowUpEmail,
  },
};

export const defaultState = () => ({
  loading: false,
  contentReady: false,
  unsavedChanges: false,
  modal: {
    type: CreateWidgetModalType.Exit,
    active: false,
  },
  tabs: {
    titles: ['Display', 'Options', 'Email', 'Snippet'],
    active: 0,
  },
  emailTabs: {
    titles: ['Travel Plan Email', 'Behaviour change survey email', 'Follow up survey email'],
    active: TravelPlanEmailTabStatus.TravelPlanEmail,
  },
  geofencingActive: false,
  geoFencingMapActive: false,
  selectedGeoFences: [],
  isEditingWidgetName: false,
  cachedWidgetProps: {},
  cachedEmailSettingProps: {},
  errors: {},
  widget: widgetDefaults(),
  emailSettings: emailSettingsDefaults(),
  lastSave: {
    widget: widgetDefaults(),
    emailSettings: emailSettingsDefaults(),
  },
  customTexts: [],
  showWidgetLinkModal: false,
});

// @ts-ignore
export const state: State = defaultState();
