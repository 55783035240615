export default {
  viewBoost: {
    challengeDetails: {
      title: 'Challenge details',
      challengeOverview: {
        title: 'Challenge overview',
        totalParticipants: 'Total participants',
        participantsInProgress: 'Participants in progress',
        participantsCompleted: 'Participants completed',
        boostsIssued: 'Boosts issued',
        boostsClaimed: 'Boosts claimed',
      },
      challengeDetails: 'Challenge details',
      boostDetails: 'Boost details',
      challengeRules: 'Challenge rules',
      boostDetailsAndTerms: 'Boost details and terms',
      termsAndConditions: 'Terms and conditions',
    },
    participants: {
      title: 'Participants',
      columns: {
        name: 'Name',
        journeysLogged: 'Journeys logged',
        progress: 'Progress',
        joinDate: 'Join date',
        boostStatus: 'Boost status',
        dateCompleted: 'Date completed',
      },
    },
    redemptions: {
      title: 'Redemptions',
      columns: {
        name: 'Name',
        description: 'Description',
        startDate: 'Start date',
        endDate: 'End date',
      },
    },
  },
};
