import { Headers } from './types';

export const DEFAULT_HEADERS = {
  [Headers.XRequestedWith]: 'XMLHTTPRequest',
  [Headers.ContentType]: 'application/json; charset=utf-8',
  [Headers.CacheControl]: 'no-cache',
  [Headers.Pragma]: 'no-cache',
};

export const ENDPOINTS_V2 = {
  AdminNotifications: '/v2/email',
  AdminEmailPreview: '/v2/email/preview',
  BehaviourChangeBarriersToChange: '/v2/travel-plan/behavior-change/barriers-to-change',
  BehaviourChangeDistanceBreakdown: '/v2/travel-plan/behavior-change/distance-breakdown',
  BehaviourChangeModeBreakdown: '/v2/travel-plan/behavior-change/results-mode-breakdown',
  BehaviourChangeModalShift: '/v2/travel-plan/behavior-change/modal-shift',
  BehaviourChangePlanMetrics: '/v2/travel-plan/behavior-change/plan-metrics',
  Communities: '/v2/community',
  CommunityTags: '/v2/tag',
  CommuteBoostChallengeImageUpload: '/v2/commute-boost/challenge/upload-image',
  Contacts: '/v2/contact', // Not used but will be used for opening up a contact form in future
  ContactsLimit: '/v2/contact/limit',
  ContactAdd: '/v2/contact',
  ContactDelete: '/v2/contact/delete',
  ContactUpdate: '/v2/contact/update',
  ContactFilteredExport: '/v2/contact/filtered-export',
  ContactsFilterProperties: '/v2/contacts/properties',

  // Liftshare dashboard
  DashboardLiftshareSummaryData: '/v2/liftshare/dashboard/statistics/current/summary',
  DashboardLiftshareMembersData: '/v2/liftshare/dashboard/statistics/current/members',
  DashboardLiftshareJourneysData: '/v2/liftshare/dashboard/statistics/current/journeys',
  DashboardLiftshareRequestToSharesData:
    '/v2/liftshare/dashboard/statistics/current/request-to-shares',
  DashboardLiftshareTeamsData: '/v2/liftshare/dashboard/statistics/current/liftshare-teams',
  DashboardLiftshareCumulativeMembersData:
    '/v2/liftshare/dashboard/statistics/current/cumulative-members',
  DashboardLiftshareTripAuthenticationsLeaderboardData:
    '/v2/liftshare/dashboard/statistics/current/trip-authentications-leaderboard',
  DashboardLiftshareTripAuthenticationsData:
    '/v2/liftshare/dashboard/statistics/current/cumulative-trip-authentications',

  // Low-frequency dashboard
  DashboardLowFrequencyChartData: '/v2/emissions/low-frequency',
  DashboardLowFrequencySummaryData: '/v2/emissions/low-frequency/dashboard',

  FilterContacts: '/v2/contact/filter',
  FeatureManager: '/v2/feature-manager',

  // High-frequency
  HighFrequencyDataSources: '/v2/emissions/high-frequency/data-sources',
  HighFrequencyImports: '/v2/emissions/high-frequency/imports',
  HighFrequencyImportDelete: '/v2/emissions/high-frequency/imports',
  HighFrequencyDeleteImports: '/v2/emissions/high-frequency/imports/delete',
  HighFrequencyChartData: '/v2/emissions/high-frequency',
  //HighFrequencySummaryData: '/v2/emissions/high-frequency/dashboard', // Does not exist yet

  TravelPlans: '/v2/travel-plan/search',
  TravelPlanBatches: '/v2/travel-plan-batch/search',
  NPSCheck: '/v2/nps',
  PendingLiftshareAccountsBatch: '/v2/pending-account/batch',
  PendingLiftshareAccountsTestEmail: '/v2/pending-account/batch/preview-email',
  PendingLiftshareAccountsBatchFinalize: '/v2/pending-account/batch/finalize',
  PendingLiftshareAccountsImageUpload: '/v2/pending-account/image',
  SurveyExportAllData: '/v2/survey/question/export/',
  SurveyAvailableCommunities: '/v2/survey/available-communities',
  SurveySummaryQuestions: '/v2/survey/summary/questions',
};

export const ENDPOINTS = {
  AccountsList: '/dashboard/scoping/communities', //todo: legacy - do not use as endpoint no longer exists. we should remove this
  AcelAssumptions: '/dashboard/acel/assumptions',
  AcelNational: '/v2/acel/national',
  AcelCreate: '/dashboard/acel/create',
  AcelCreateFromSurvey: '/dashboard/acel/create-from-survey',
  AcelReportDelete: '/dashboard/acel/report',
  AcelReportRename: '/dashboard/acel/rename',
  AcelReports: '/dashboard/acel/reports',
  AcelReportDetails: '/dashboard/acel/report',
  AcelSetPrimary: '/dashboard/acel/primary-report',
  AcelSetPrivacy: '/dashboard/acel/toggle-private',
  AcelSetTarget: '/dashboard/acel/set-target',
  AdminPendingLiftshare: '/dashboard/liftshare/from-contacts',
  AdminPendingLiftshareCreate: '/dashboard/liftshare/from-contacts',
  AcelSurveys: '/dashboard/acel/acel-surveys',
  AccountSettings: '/dashboard/settings',
  AcelSurveyData: '/dashboard/acel/from-survey',
  AutocompleteProxy: '/dashboard/geocode/autocomplete',
  BehaviourChangeBarriersToChange: '/dashboard/travel-plans/behavior-change/barriers-to-change',
  BehaviourChangeDistanceBreakdown: '/dashboard/travel-plans/behavior-change/distance-breakdown',
  BehaviourChangeModalShift: '/dashboard/travel-plans/behavior-change/modal-shift',
  BehaviourChangeModeBreakdown: '/dashboard/travel-plans/behavior-change/results-mode-breakdown',
  // Where are these used? Can we strip it down to just
  Contacts: '/dashboard/contacts',
  ContactAdd: '/dashboard/contacts',
  ContactDelete: '/dashboard/contacts',
  ContactDetails: '/dashboard/contacts',
  ContactImportHeaders: '/dashboard/contacts/upload/headers',
  ContactPreviousImports: '/dashboard/contacts/previous-imports',
  ContactUpdate: '/dashboard/contacts/update',
  ContactUpdateTags: '/dashboard/contacts/add-tag',
  ContactsUpload: '/dashboard/contacts/upload',
  ContactsEmailTaken: '/dashboard/contacts/email-address-taken',
  ContactsImportProgress: '/dashboard/contacts/import-progress',
  ContactsPreviousImportFailures: '/dashboard/contacts/previous-import-failures',
  CustomTexts: '/dashboard/travel-plans/get-custom-texts',
  CustomTextDelete: '/dashboard/travel-plans/delete-custom-texts',
  CustomTextSave: '/dashboard/travel-plans/save-custom-text',
  GeocodeProxy: '/dashboard/geocode/place',
  ImageUpload: '/dashboard/survey/image-upload',
  PasswordResetConfirm: '/dashboard/password/reset',
  PasswordResetEmail: '/dashboard/password/reset-link',
  PersonalisationTokens: '/dashboard/personalisation-tokens',
  PlatformUsageLogins: '/dashboard/admin/logins',
  ScopingContacts: '/dashboard/scoping/from-contacts',
  ScopingDataAggregated: '/dashboard/scoping/aggregated-data',
  ScopingDataPlan: '/dashboard/scoping/plan-data',
  ScopingResults: '/dashboard/scoping/results-csv',
  ScopingMap: '/dashboard/scoping/map',
  ScopingReportAbort: '/dashboard/scoping/cancel',
  ScopingReportContinue: '/dashboard/scoping/continue',
  ScopingReport: '/dashboard/scoping/pdf',
  ScopingReportStatus: '/dashboard/scoping/status',
  ScopingSummary: '/dashboard/scoping/summary',
  ScopingFullSummary: '/dashboard/scoping/full-summary',
  ScopingUpload: '/dashboard/scoping/upload',
  Surveys: '/dashboard/survey/previous',
  SurveyAnalytics: '/dashboard/survey/analysis',
  SurveyAnnotation: '/dashboard/survey/analytics/annotation',
  SurveyHeadingAnnotationDelete: '/dashboard/survey/analytics/annotation',
  SurveyQuestionAnnotationDelete: '/dashboard/survey/analytics/annotation',
  SurveyReportPrivacy: '/dashboard/survey/analytics/set-privacy',
  SurveyPublicAnalyticsId: '/dashboard/survey/analytics/public-key',
  SurveyClone: '/dashboard/survey/clone',
  SurveyContacts: '/dashboard/survey/contacts',
  SurveyEmailMetrics: '/dashboard/survey/email-metrics',
  SurveyLinkMetrics: '/dashboard/survey/view-metrics',
  SurveyData: '/dashboard/survey/editable',
  SurveyDelete: '/dashboard/survey',
  SurveyDeleteResponse: '/dashboard/survey/individual-response',
  SurveyExport: '/dashboard/survey/export',
  SurveyIndividualResponses: '/dashboard/survey/individual-responses',
  SurveyIndividualUserResponse: '/dashboard/survey/individual-response',
  SurveyPause: '/dashboard/survey/toggle-published',
  SurveyPublish: '/dashboard/survey/publish',
  SurveyResponses: '/dashboard/survey/responses',
  SurveyResponsesOverTime: '/dashboard/survey/responses-over-time',
  SurveySave: '/dashboard/survey/save',
  SurveySendMoreContacts: '/dashboard/survey/send-email',
  SurveySendTestEmail: '/dashboard/survey/send-preview-email',
  SurveyShareLink: '/dashboard/survey/link',
  SurveySummary: '/dashboard/survey/summary',
  SurveySummaryByQuestion: '/dashboard/survey/analysis',
  SurveyTemplates: '/dashboard/survey/templates',
  SurveyTemplateData: '/dashboard/survey/template',
  TravelPlans: '/dashboard/travel-plans/list',
  TravelPlanArchive: '/dashboard/travel-plans/archive-plans',
  TravelPlanBatches: '/dashboard/travel-plans/batch-list',
  TravelPlanBatchStatus: '/dashboard/travel-plans/batch-status',
  TravelPlanDestinations: '/dashboard/travel-plans/custom-destinations',
  TravelPlanDetails: '/dashboard/travel-plans/continue-batch',
  TravelPlanEmail: '/dashboard/travel-plans/plan-email',
  TravelPlanFailed: '/dashboard/travel-plans/failed-plans',
  TravelPlanFinalize: '/dashboard/travel-plans/finalize-batch',
  TravelPlanImageUpload: '/dashboard/travel-plans/image-upload',
  TravelPlanNotifyCompletion: '/dashboard/travel-plans/notify-batch-complete',
  TravelPlanProcessedPlans: '/dashboard/travel-plans/processed-plans',
  TravelPlanResults: '/dashboard/travel-plans/plan-results',
  TravelPlanSave: '/dashboard/travel-plans/save-batch',
  TravelPlanStartBatch: '/dashboard/travel-plans/start-batch',
  TravelPlanSendEmails: '/dashboard/travel-plans/send-emails-for-batch',
  TravelPlanSendTestEmails: '/dashboard/travel-plans/send-test-email',
  TravelPlanSendMultiple: '/dashboard/travel-plans/send-emails',
  TravelPlanSurveyResults: '/dashboard/travel-plans/survey-ids',
  TravelPlanSurveyEmailMetrics: '/dashboard/travel-plans/email-metrics',
  TravelPlanViews: '/dashboard/travel-plans/views',
  TravelPlanRateLimitStatus: '/dashboard/travel-plans/rate-limit-status',
  Widgets: '/dashboard/widgets',
  WidgetsDelete: '/dashboard/widgets',
  Widget: '/dashboard/widgets',
  WidgetsUpdateStatus: '/dashboard/widgets/update-status',
  WidgetsUpsert: '/dashboard/widgets/upsert',
  WidgetsValidateLocations: '/dashboard/widgets/validate-locations',
  SurveyDataPublic: '/dashboard/survey',
  EmailPreferences: '/dashboard/contacts/email-prefs',
  SurveyAnswer: '/dashboard/survey/answer',
  TravelPlanFeedback: '/dashboard/travel-plans/result-feedback',
  TravelPlanWidgetSearch: '/dashboard/travel-plans/widget/search',
  UpdateEmailPreferences: '/dashboard/contacts/update-email-prefs',
  UpdateAccountSettings: '/dashboard/settings/update',
};
