import i18n from '@/locales';
import { MobilitywaysTransportEnum } from '@/enums/global-enums';
const { t } = i18n.global;
// MW DASH ROLES
// SuperAdmin = IT Staff, Admin = Other staff
// <MODULE>Admin has full access to that module's features
// <MODULE>Monitor has reduced access, usually only able to view data, and not modify it

export type UserRole =
  | 'SuperAdmin'
  | 'Admin'
  | 'CommunityAdmin'
  | 'CommunityMonitor'
  | 'MemberActivator'
  | 'MemberExportAdmin'
  | 'RequiresClassicReporting'
  | 'CommunityParkingPermit'
  | 'PaidSupportLicence'
  | 'TravelPlanAdmin'
  | 'TravelPlanMonitor'
  | 'ScopingUser'
  | 'ScopingAdmin'
  | 'SurveyAndAcelAdmin'
  | 'SurveyAndAcelMonitor'
  | 'ContactAdmin'
  | 'ContactMonitor'
  | 'AcelTargetAdmin'
  | 'LiftshareCommunityAdmin'
  | 'LiftshareGroupAdmin';

export const ROLE_SUPERADMIN: Array<UserRole> = ['SuperAdmin'];
export const ROLE_ADMIN: Array<UserRole> = ['SuperAdmin', 'Admin'];

export const ROLE_CONTACTS: Array<UserRole> = [
  'Admin',
  'ContactAdmin',
  'ContactMonitor',
  'SurveyAndAcelAdmin',
  'TravelPlanAdmin',
];
export const ROLE_CONTACTS_ADMIN: Array<UserRole> = [
  'Admin',
  'ContactAdmin',
  'SurveyAndAcelAdmin',
  'TravelPlanAdmin',
];

export const ROLE_SURVEY: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin', 'SurveyAndAcelMonitor'];
export const ROLE_SURVEY_ADMIN: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin'];

export const ROLE_ACEL: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin', 'SurveyAndAcelMonitor'];
export const ROLE_ACEL_ADMIN: Array<UserRole> = ['Admin', 'SurveyAndAcelAdmin'];

export const ROLE_SCOPING: Array<UserRole> = ['Admin', 'ScopingUser'];
export const ROLE_SCOPING_ADMIN: Array<UserRole> = ['ScopingAdmin'];
export const ROLE_COMMUTEIQ: Array<UserRole> = ['Admin', 'ScopingUser', 'ScopingAdmin'];

export const ROLE_TRAVELPLAN: Array<UserRole> = ['Admin', 'TravelPlanAdmin', 'TravelPlanMonitor'];
export const ROLE_TRAVELPLAN_ADMIN: Array<UserRole> = ['Admin', 'TravelPlanAdmin'];

export const ROLE_ASSESS: Array<UserRole> = ['Admin', 'AcelTargetAdmin'];

// TODO: Get correct roles
export const ROLE_LIFTSHARE_ADMIN: Array<UserRole> = [
  'Admin',
  'LiftshareCommunityAdmin',
  'LiftshareGroupAdmin',
];

export const initialPageSizes = [
  { label: '10 per page', value: 10 },
  { label: '25 per page', value: 25 },
  { label: '50 per page', value: 50 },
  { label: '100 per page', value: 100 },
];

// convert the MobilitywaysTransportEnum enum to a list of options for the select
const commuteOptionsArray: { value: string; label: string; enum: string }[] = [];
Object.entries(MobilitywaysTransportEnum).forEach(([_, value]) => {
  // if the value is a string containing only numbers, then use those
  if (/^\d+$/.test(value)) {
    commuteOptionsArray.push({
      value: value,
      label: t(`transportModes.${Number(value)}`),
      enum: _,
    });
  }
});
export const commuteOptions = commuteOptionsArray;
