import Quill from 'quill';
import type { ToastInterface } from 'vue-toastification';
import { useToast } from 'vue-toastification';

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const getTokenData = (userToken: string | null) => {
  if (!userToken) return null;
  return JSON.parse(atob(userToken.split('.')[1]));
};

export const contrastingColour = (hex: string): string => {
  let passedWithHash = false;

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
    passedWithHash = true;
  }

  const prefix = passedWithHash ? '#' : '';

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error('Invalid HEX Colour');
  }

  const red = parseInt(hex.slice(0, 2), 16);
  const green = parseInt(hex.slice(2, 4), 16);
  const blue = parseInt(hex.slice(4, 6), 16);

  return red * 0.299 + green * 0.587 + blue * 0.114 > 186 ? `${prefix}000000` : `${prefix}FFFFFF`;
};

export const isExternalUrl = (raw: string): boolean => {
  return typeof raw === 'string' && raw.startsWith('http');
};

export const isInString = (stack: string, needle: string, caseSensitive?: boolean) => {
  if (caseSensitive) {
    return stack.includes(needle);
  }
  return stack.search(new RegExp(needle, 'i')) !== -1;
};

const Link = Quill.import('formats/link');
const PROTOCOL_WHITELIST: Array<string> = [
  'http',
  'https',
  'mailto',
  'tel',
  'radar',
  'rdar',
  'smb',
  'sms',
];
// Override the existing property on the Quill global object and add custom protocols
Link.PROTOCOL_WHITELIST = PROTOCOL_WHITELIST;

export class CustomLinkSanitizer extends Link {
  public static toast: ToastInterface = useToast();

  static sanitize(url: string) {
    // Run default sanitize method from Quill
    const sanitizedUrl = super.sanitize(url);

    // Not whitelisted URL based on protocol so, let's return `blank` and toast an error message
    if (!sanitizedUrl || sanitizedUrl === 'about:blank') {
      CustomLinkSanitizer.toast.error('That link is invalid, please use a valid url!');
      return sanitizedUrl;
    }

    // Verify if the URL already has a whitelisted protocol
    const hasWhitelistedProtocol = PROTOCOL_WHITELIST.some((protocol) => {
      return sanitizedUrl.startsWith(protocol);
    });

    if (hasWhitelistedProtocol) return sanitizedUrl;

    // if not, then append only 'http' to not to be a relative URL
    return `http://${sanitizedUrl}`;
  }
}

export const includeOnArray = (condition: any, val: any) => (condition ? [val] : []);

export const getPercentageOf = (comparing: number, compareTo: number) => {
  if (comparing === 0 && compareTo === 0) {
    return 100;
  }
  return (comparing / compareTo) * 100;
};
export const limitNumber = (num: number, limit: number) => (num < limit ? num : limit);

export const getAspectRatio = (width: number, height: number) => height / width;

export const milesToMeters = (val: number) => val * 1609;
export const metersToMiles = (val: number) => val / 1609;

export const isInteger = (value: string) => {
  return /^\d+$/.test(value);
};
export const isNumeric = (value: string) => {
  return /[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)/.test(value);
};

export const roundToTwoDecimalPlaces = (num: number) =>
  Math.round((num + Number.EPSILON) * 100) / 100;
