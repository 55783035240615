import type { MutationTree } from 'vuex';
import type { State } from './state';
import { DashboardAppMutationTypes } from './mutation-types';
import storage from '@/helpers/storage';

export type Mutations<S = State> = {
  [DashboardAppMutationTypes.UPDATE_COMMUNITY](state: S, payload?: string | null): void;
  [DashboardAppMutationTypes.UPDATE_ACEL_TABS](state: S, payload?: string | null): void;
  [DashboardAppMutationTypes.UPDATE_ACEL_MODAL_SHIFT](state: S, payload?: string | null): void;
  [DashboardAppMutationTypes.UPDATE_COMMUNITY_ROLES](state: S, payload: any): void;
  [DashboardAppMutationTypes.SET_CHECKED_ACCOUNT](state: S, payload: any): void;
  [DashboardAppMutationTypes.SET_SEARCH_TERM](state: S, payload: string): void;
  [DashboardAppMutationTypes.SET_COMMUNITY_FAIL](state: S, payload: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [DashboardAppMutationTypes.UPDATE_COMMUNITY](state, payload) {
    if (payload === undefined || payload === null) {
      storage.remove('community');
      state.community = null;
    } else {
      storage.set('community', payload._object.selectedCommunityId);
      state.community = payload._object.selectedCommunityId;
    }
  },
  [DashboardAppMutationTypes.UPDATE_ACEL_TABS](state, payload) {
    if (payload === undefined || payload === null) {
      storage.remove('acelTabs');
      state.acelTabs = null;
    } else {
      storage.set('acelTabs', payload);
      state.acelTabs = payload;
    }
  },
  [DashboardAppMutationTypes.UPDATE_ACEL_MODAL_SHIFT](state, payload) {
    if (payload === undefined || payload === null) {
      storage.remove('acelModalShift');
      state.acelModalShift = null;
    } else {
      storage.set('acelModalShift', payload);
      state.acelModalShift = payload;
    }
  },
  [DashboardAppMutationTypes.UPDATE_COMMUNITY_ROLES](state, roles) {
    state.communityRoles = roles;
  },
  [DashboardAppMutationTypes.SET_CHECKED_ACCOUNT](state, payload) {
    state.checkedAccount = payload;
  },
  [DashboardAppMutationTypes.SET_SEARCH_TERM](state, searchTerm) {
    state.communitySearchTerm = searchTerm;
  },
  [DashboardAppMutationTypes.SET_COMMUNITY_FAIL](state, failed) {
    state.communityFail = failed;
  },
};
