<template>
  <div class="">
    <label class="mb-2 flex flex-row items-center" v-if="label" v-tooltip="`${tooltip ?? ''}`">
      {{ label }}
      <i v-if="tooltip" class="fa-solid fa-circle-info ml-2"></i>
    </label>
  </div>
  <select
    v-bind="$attrs"
    :value="modelValue"
    @change="$emit('update:modelValue', $event.target.value)"
    class="bg-white border border-smoke rounded text-gray-700 py-2 px-3 focus:outline-none w-full mb-6"
  >
    <slot />
  </select>
</template>

<script lang="ts">
export default {
  name: 'BrandSelect',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  components: {},
};
</script>
