export enum WidgetStatus {
  Draft,
  Active,
  Paused,
}

export enum WidgetType {
  Iframe,
  Button,
  Url,
}

export enum WidgetRateLimitStatus {
  None,
  Near,
  Exceeded,
}

export enum WidgetRestrictionType {
  None,
  Origin,
  Destination,
  Both,
}

export enum WidgetSendEmailOption {
  DontSend,
  Optional,
  Send,
}

export enum WidgetButtonRadius {
  None,
  Small,
  Medium,
  Large,
  Full,
}

export enum WidgetButtonFontSize {
  XSmall,
  Small,
  Medium,
  Large,
  XLarge,
  XXLarge,
}
