/**
 * @important If you add a new transport type, you must also add it to the ordering array within:
 * /src/views/Survey/Create/survey-creation-helpers.ts > fetchOrderedTransportModes()
 * The arrays are transportModesEmissionsOrder and transportModesPopularityOrder
 */
export const transportTypeOptions = {
  0: 'I always work from home',
  1: 'Walk or Wheel',
  23: 'Run',
  2: 'Cycle',
  12: 'Drive alone',
  13: 'Drive alone (EV)',
  27: 'Drive alone (hybrid)',
  4: 'Bus',
  5: 'Bus (EV)',
  9: 'Car share',
  10: 'Car share (EV)',
  28: 'Car share (hybrid)',
  7: 'Train',
  8: 'Motorcycle/Moped',
  22: 'Motorcycle/Moped (EV)',
  11: 'Park and ride',
  3: 'Electric scooter/Electric bike',
  6: 'Light rail',
  24: 'Tube',
  25: 'Metro',
  26: 'Tram',
  20: 'Work shuttle bus',
  21: 'Work shuttle bus (EV)',
  14: 'Taxi',
  15: 'Taxi (EV)',
  18: 'Van pool',
  19: 'Van pool (EV)',
  16: 'Fly',
  17: 'Other',
};
/**
 * Why are there colours in here?
 * Internationalisation is the process of adapting software to different languages, regions and cultures.
 * Internationalisation doesn't just involve enabling different languages, it also entails adapting the software to accept different forms
 * of data and settings to match local customs.
 * The colours below have been chosen to denote meaning to the user. But differebnt cultures have different meanings for colour.
 * Hence, these colours fit within the remit of internationalisation.
 * But only these colours. Other colours, such as brand colours and theming colours do not belong in this file.
 */
export const transportTypeColours = {
  // Traffic light system
  0: '#4A6E1A', // WorkFromHome
  1: '#537722', // WalkOrWheel
  23: '#5A8124', // Run
  2: '#638B29', // Cycle
  12: '#F05936', // Drive
  13: '#FDE047', // ElectricDrive
  4: '#F6A814', // BusOrCoach
  5: '#85B33F', // ElectricBusOrCoach
  9: '#FAC730', // Carshare
  10: '#E7E550', // ElectricCarshare
  7: '#D7E957', // Train
  8: '#F59D0B', // Motorcycle
  22: '#8FBD46', // ElectricMotorcycle
  11: '#F27A22', // ParkAndRide
  3: '#6C952F', // ElectricScooter
  6: '#C6ED5F', // LightRail
  24: '#FBBF24', // Tube
  25: '#F59E0B', // Metro
  26: '#F97316', // Tram
  27: '#F59E0B', // Hybrid
  20: '#F7B31E', // CompanyShuttleBus
  21: '#7BA639', // ElectricCompanyShuttleBus
  14: '#F48E15', // Taxi
  15: '#96C54A', // ElectricTaxi
  18: '#FBD33B', // VanPool
  19: '#749E34', // ElectricVanPool
  16: '#EF4444', // Fly
  17: '#CBCBCB', // Other
};
