export enum TravelPlanServiceType {
  WalkOrWheel = 'WalkOrWheel',
  Bike = 'Bike',
  Public = 'Public',
  Drive = 'Drive',
  ParkAndRide = 'ParkAndRide',
  CycleToTrainStation = 'CycleToTrainStation',
  DriveToTrainStation = 'DriveToTrainStation',
  CarShareToTrainStation = 'CarShareToTrainStation',
  Carshare = 'Carshare',
  CommunityTransport = 'CommunityTransport',
}

export enum TravelPlanSwitchType {
  Recommended = 'Recommended',
  WalkOrWheel = 'WalkOrWheel',
  Bike = 'Bike',
  Carshare = 'Carshare',
  Public = 'Public',
  Drive = 'Drive',
}

export enum JourneyType {
  Passenger = 1,
  Driver = 2,
  Both = 3,
}

export enum JourneyFrequency {
  OneOff = 1,
  Regular = 2,
  Occasional = 3,
  Shift = 4,
}

export enum PassengerOrDriver {
  Passenger,
  Driver,
}

export enum TravelPlanEmailTabStatus {
  TravelPlanEmail,
  BehaviourChangeEmail,
  FollowUpEmail,
}

export enum TravelPlanRateLimitStatus {
  Under,
  Warning,
  Over,
}
