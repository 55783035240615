/**
 *
 * How to use i18n in a .ts file
 * ------------------------------
 * import i18n from '@/locales';
 * const { t } = i18n.global;
 * const string = t('message.world')
 *
 * How to use i18n in a .vue file in the <script setup>
 * ----------------------------------------------------
 * import { useI18n } from 'vue-i18n';
 * const { t } = useI18n();
 * const string = t('message.world')
 *
 * How to use i18n in a .vue file in the <template>
 * ------------------------------------------------
 * {{ $t('message.world') }}
 *
 */

import { createI18n } from 'vue-i18n';
import { enGB } from '@/locales/en-gb';
import numberFormats from '@/locales/number-formats';
type MessageSchema = typeof enGB;
type NumberSchema = typeof numberFormats;

const i18n = createI18n<
  {
    message: MessageSchema;
    number: NumberSchema;
  },
  'en-GB',
  false
>({
  locale: 'en-GB',
  fallbackLocale: 'en-GB',
  legacy: false,
  messages: {
    'en-GB': enGB,
  },
  numberFormats: numberFormats,
});

export default i18n;
