import type { GetterTree } from 'vuex';
import type { RootState } from '@/mw-components/store';
import type { State } from './state-types';

import moment from 'moment-timezone';
import { TravelPlanSwitchType, TravelPlanServiceType } from '@/mw-components/enums/travel-plan';

const MAX_DIST_WALKING = 24140; // 10 miles
const MAX_DIST_CYCLING = 80467; // 50 miles

export type Getters = {
  isWidget(state: State): boolean;
  getOriginAddress(state: State): string;
  getDestinationAddress(state: State): string;
  getLeaveDate(state: State): string;
  getTimeZoneIana(state: State): string;
  getArriveBy(state: State): boolean;
  getDepartAt(state: State): boolean;
  getLeaveTime(state: State): string;
  getArrivalDate(state: State): string;
  getArrivalTime(state: State): string;
  outboundFilteredTravelModes(state: State): Array<any>;
  filteredTravelModes(state: State, getters: any): Array<any>;
  recommendedTravelModes(state: State, getters: any): Array<any>;
  walkingTravelModes(state: State, getters: any): Array<any>;
  cyclingTravelModes(state: State, getters: any): Array<any>;
  carshareTravelModes(state: State, getters: any): Array<any>;
  publicTravelModes(state: State, getters: any): Array<any>;
  driveTravelModes(state: State, getters: any): Array<any>;
  currentTravelModes(state: State, getters: any): Array<any>;
  communityTransportModes(state: State, getters: any): Array<any>;
  isWalkingAvailable(state: State, getters: any): boolean;
  isCyclingAvailable(state: State, getters: any): boolean;
  isCarShareAvailable(state: State, getters: any): boolean;
  isPublicAvailable(state: State, getters: any): boolean;
  isDrivingAvailable(state: State, getters: any): boolean;
  getTravelModeCount(state: State, getters: any): number;
  getTravelModeText(state: State): string;
  getViewDetail(state: State, getters: any): any;
  getActiveDetail(state: State, getters: any): any;
  getActiveMode(state: State, getters: any): any;
  getActiveSteps(state: State, getters: any): any;
  getElevationData(state: State, getters: any): any;
  getContacts(state: State, getters: any): any;
  getOrigins(state: State, getters: any): any;
  getDestinations(state: State, getters: any): any;
};

export const getters: GetterTree<State, RootState> & Getters = {
  isWidget: (state) => !!state.widgetId,
  // @ts-ignore
  getOriginAddress: (state) => {
    if (state.options && state.options.origin) return state.options.origin.Address;
  },
  // @ts-ignore
  getDestinationAddress: (state) => {
    if (state.options && state.options.destination) return state.options.destination.Address;
  },

  //outbound
  getLeaveDate: (state) => {
    if (state.options && state.options.outbound_timing) {
      return moment(state.options.outbound_timing.time_utc_offset).format('DD/MM/YYYY');
    }
    return '';
  },
  getLeaveTime: (state) => {
    if (state.options && state.options.outbound_timing) {
      const timeZone = state.options.iana_time_zone_id;
      const outboundTimeUtc = moment.tz(state.options.outbound_timing.time_utc_offset, 'UTC');
      const localTime = outboundTimeUtc.tz(timeZone);
      return localTime.format('HH:mm')
    }
    return '';
  },
  getArriveBy: (state) => {
    if (state.options && state.options.outbound_timing)
      return state.options.outbound_timing.type === 'ArriveBy';
    return true;
  },

  getTimeZoneIana: (state) => {
    if (state.options && state.options.iana_time_zone_id) {
      return state.options.iana_time_zone_id;
    }
    return '';
  },

  //return
  getArrivalDate: (state) => {
    if (state.options && state.options.return_timing) {
      return moment(state.options.return_timing.time_utc_offset).format('DD/MM/YYYY');
    }
    return '';
  },
  getArrivalTime: (state) => {
    if (state.options && state.options.return_timing) {
      const timeZone = state.options.iana_time_zone_id;
      const returnTimeUtc = moment.tz(state.options.return_timing.time_utc_offset, 'UTC');
      const localTime = returnTimeUtc.tz(timeZone);
      return localTime.format('HH:mm');
    }
    return '';
  },
  getDepartAt: (state) => {
    if (state.options && state.options.return_timing)
      return state.options.return_timing.type === 'DepartAt';
    return true;
  },

  outboundFilteredTravelModes: (state) => {
    if (!state.routes || state.routes.length < 1) return [];

    return state.routes.filter((entry: any) => {
      return entry.route.route_options.leg_type === (state.outbound ? 'Outbound' : 'Return');
    });
  },
  filteredTravelModes: (state, getters) => {
    if (!state.routes || state.routes.length < 1) return;

    const serviceFiltered = getters.outboundFilteredTravelModes.filter((entry: any) => {
      switch (state.currentTravelMode) {
        case TravelPlanSwitchType.Recommended:
          return entry.valid;
        case TravelPlanSwitchType.WalkOrWheel:
          return (
            entry.route.service_type === TravelPlanServiceType.WalkOrWheel &&
            entry.route.total_distance_in_meters < MAX_DIST_WALKING
          );
        case TravelPlanSwitchType.Bike:
          return (
            entry.route.service_type === TravelPlanServiceType.Bike &&
            entry.route.total_distance_in_meters < MAX_DIST_CYCLING
          );
        case TravelPlanSwitchType.Public:
          const type = entry.route.service_type;
          return (
            type === TravelPlanServiceType.Public || type === TravelPlanServiceType.ParkAndRide
          );
        case TravelPlanSwitchType.Carshare:
          return entry.route.service_type === TravelPlanServiceType.Carshare;
        case TravelPlanSwitchType.Drive:
          return entry.route.service_type === TravelPlanServiceType.Drive;
      }
    });

    return serviceFiltered.sort((a: any, b: any) => a.route.rank - b.route.rank);
  },
  recommendedTravelModes: (_, getters) => {
    const sustainableOptions = [
      getters.isValidWalkingAvailable,
      getters.isValidCyclingAvailable,
      getters.isValidCarShareAvailable,
      getters.isValidPublicAvailable,
    ].includes(true);

    const walking = getters.walkingTravelModes
      .filter((item: any) => item.valid)
      .sort((a: any, b: any) => a.route.rank - b.route.rank)[0];
    const cycling = getters.cyclingTravelModes
      .filter((item: any) => item.valid)
      .sort((a: any, b: any) => a.route.rank - b.route.rank)[0];
    let carsharing = getters.carshareTravelModes.sort(
      (a: any, b: any) => a.route.rank - b.route.rank,
    );

    if (carsharing.length < 1) {
      carsharing = undefined;
    }

    const pub = getters.publicTravelModes
      .filter((item: any) => item.valid)
      .sort((a: any, b: any) => a.route.rank - b.route.rank);
    const car = getters.driveTravelModes
      .filter((item: any) => item.valid)
      .sort((a: any, b: any) => a.route.rank - b.route.rank)[0];

    return sustainableOptions
      ? [walking, cycling, carsharing, ...pub].filter((entry) => entry) // ? !Array.isArray(entry) ? entry.valid : true: false) :
      : [walking, cycling, carsharing, ...pub, car].filter((entry) => entry); // ? !Array.isArray(entry) ? entry.valid : true: false);
  },
  walkingTravelModes: (_, getters) => {
    return getters.outboundFilteredTravelModes.filter((entry: any) => {
      return (
        entry.route.service_type === TravelPlanServiceType.WalkOrWheel &&
        entry.route.total_distance_in_meters < MAX_DIST_WALKING
      );
    });
  },
  cyclingTravelModes: (_, getters) => {
    return getters.outboundFilteredTravelModes.filter((entry: any) => {
      return (
        entry.route.service_type === TravelPlanServiceType.Bike &&
        entry.route.total_distance_in_meters < MAX_DIST_CYCLING
      );
    });
  },
  carshareTravelModes: (_, getters) => {
    return getters.outboundFilteredTravelModes.filter((entry: any) => {
      return entry.route.service_type === TravelPlanServiceType.Carshare;
    });
  },
  publicTravelModes: (_, getters) => {
    return getters.outboundFilteredTravelModes.filter((entry: any) => {
      return [
        TravelPlanServiceType.Public,
        TravelPlanServiceType.ParkAndRide,
        TravelPlanServiceType.CommunityTransport,
      ].includes(entry.route.service_type);
    });
  },
  communityTransportModes: (_, getters) => {
    return getters.outboundFilteredTravelModes.filter((entry: any) => {
      return entry.route.service_type === TravelPlanServiceType.CommunityTransport;
    });
  },
  driveTravelModes: (_, getters) => {
    return getters.outboundFilteredTravelModes.filter((entry: any) => {
      return entry.route.service_type === TravelPlanServiceType.Drive;
    });
  },
  currentTravelModes: (state, getters) => {
    switch (state.currentTravelMode) {
      case TravelPlanSwitchType.Recommended:
        return getters.recommendedTravelModes;
      case TravelPlanSwitchType.WalkOrWheel:
        return getters.walkingTravelModes;
      case TravelPlanSwitchType.Bike:
        return getters.cyclingTravelModes;
      case TravelPlanSwitchType.Carshare:
        const modes = getters.carshareTravelModes;
        return modes && modes.length ? [modes] : [];
      case TravelPlanSwitchType.Public:
        return getters.publicTravelModes;
      case TravelPlanSwitchType.Drive:
        return getters.driveTravelModes;
      default:
        return [];
    }
  },
  isWalkingAvailable: (_, getters) => {
    return getters.walkingTravelModes.length > 0;
  },
  isCyclingAvailable: (_, getters) => {
    return getters.cyclingTravelModes.length > 0;
  },
  isCarShareAvailable: (_, getters) => {
    return getters.carshareTravelModes.length > 0;
  },
  isPublicAvailable: (_, getters) => {
    return getters.publicTravelModes.length > 0;
  },
  isDrivingAvailable: (_, getters) => {
    return getters.driveTravelModes.length > 0;
  },
  isValidWalkingAvailable: (_, getters) => {
    return getters.walkingTravelModes.filter((item: any) => item.valid).length > 0;
  },
  isValidCyclingAvailable: (_, getters) => {
    return getters.cyclingTravelModes.filter((item: any) => item.valid).length > 0;
  },
  isValidCarShareAvailable: (_, getters) => {
    return getters.carshareTravelModes.filter((item: any) => item.valid).length > 0;
  },
  isValidPublicAvailable: (_, getters) => {
    return getters.publicTravelModes.filter((item: any) => item.valid).length > 0;
  },
  isValidDrivingAvailable: (_, getters) => {
    return getters.driveTravelModes.filter((item: any) => item.valid).length > 0;
  },
  getTravelModeCount: (state, getters) => {
    switch (state.currentTravelMode) {
      case TravelPlanSwitchType.Recommended:
        return getters.recommendedTravelModes.filter((item: any) =>
          !Array.isArray(item) ? item.valid : true,
        ).length;
      case TravelPlanSwitchType.WalkOrWheel:
        return getters.walkingTravelModes.length;
      case TravelPlanSwitchType.Bike:
        return getters.cyclingTravelModes.length;
      case TravelPlanSwitchType.Carshare:
        return getters.carshareTravelModes.length;
      case TravelPlanSwitchType.Public:
        return getters.publicTravelModes.length;
      case TravelPlanSwitchType.Drive:
        return getters.driveTravelModes.length;
      default:
        return 0;
    }
  },
  getCurrentTravelMode: (state) => {
    return state.currentTravelMode;
  },
  showingCarshareJourneys: (_, getters) => {
    return getters.getCurrentTravelMode === TravelPlanSwitchType.Carshare;
  },
  getTravelModeText(state): string {
    switch (state.currentTravelMode) {
      case TravelPlanSwitchType.Recommended:
        return 'Recommended modes';
      case TravelPlanSwitchType.WalkOrWheel:
        return 'Walking';
      case TravelPlanSwitchType.Bike:
        return 'Cycling';
      case TravelPlanSwitchType.Public:
        return 'Public Transport';
      case TravelPlanSwitchType.Carshare:
        return 'Carsharing';
      case TravelPlanSwitchType.Drive:
        return 'Driving';
      default:
        return '';
    }
  },
  getViewDetail: (state, getters) => {
    if (state.activeCard && state.activeCard === state.viewDetail) {
      const result = getters.currentTravelModes.filter((entry: any) => {
        if (Array.isArray(entry)) {
          return entry.length > 0 && entry.some((e) => e.id === state.viewDetail);
        }
        return entry.id === state.viewDetail;
      })[0];
      if (Array.isArray(result)) {
        return result[0] && result[0].route ? result[0].route : {};
      }
      return result.route;
    } else {
      return [];
    }
  },
  // TODO: let's refactor these to tidy up switching logic....
  // TODO: can now use a composition from getActiveMode
  getActiveDetail: (state, getters) => {
    if (state.activeCard) {
      const result = getters.currentTravelModes.filter((entry: any) => {
        if (Array.isArray(entry)) {
          return entry.length > 0 && entry[0].id === state.activeCard;
        }
        return entry.id === state.activeCard;
      });

      if (!result || result.length < 1) {
        return {};
      }

      if (Array.isArray(result[0])) {
        return result[0][0].route;
      }
      return result[0] ? result[0].route : {};
    } else {
      return {};
    }
  },
  getActiveMode: (state, getters) => {
    if (state.activeCard) {
      const result = getters.currentTravelModes.filter((entry: any) => {
        if (Array.isArray(entry)) {
          return entry.length > 0 && entry[0].id === state.activeCard;
        }
        return entry.id === state.activeCard;
      });

      if (!result || result.length < 1) {
        return {};
      }

      return result[0];
    } else {
      return {};
    }
  },
  getActiveSteps: (_, getters) => {
    let finalSteps: Array<any> = [];
    if (getters.getActiveDetail) {
      if (
        ![TravelPlanServiceType.Carshare, TravelPlanServiceType.CommunityTransport].includes(
          getters.getActiveDetail.service_type,
        )
      ) {
        if (getters.getActiveDetail.legs) {
          for (const leg of getters.getActiveDetail.legs) {
            if (leg?.steps) {
              finalSteps = [...finalSteps, ...leg.steps];
            }
          }
        }
      }
    }
    return finalSteps;
  },
  getElevationData: (state, getters) => {
    if (state.activeCard && state.activeCard === state.viewDetail) {
      const result = getters.currentTravelModes.filter((entry: any) => {
        if (Array.isArray(entry)) {
          return entry.length > 0 && entry[0].id === state.activeCard;
        }
        return entry.id === state.activeCard;
      });

      if (Array.isArray(result[0])) {
        return result[0][0].elevation_data;
      }
      return result[0].elevation_data;
    } else {
      return [];
    }
  },
  getContacts: (state) => {
    if (state.contacts && state.contacts.length > 0) {
      return state.contacts;
    }
    return [];
  },
  // Had to render these as arrays in the template as v-ifs seem to be broken for map in vue atm..
  getOrigins: (state) => {
    if (state.options && state.options.origin) {
      return [state.options.origin];
    }
    return [];
  },
  getDestinations: (state) => {
    if (state.options && state.options.destination) {
      return [state.options.destination];
    }
    return [];
  },
  getFacts: (state) => {
    return state.facts;
  },
  getHeaderUrl: (state) => state.headerImageUrl,
};
