// TODO: Split enums into different files for each module

export type GeocodeStatus =
  | 'Uploaded'
  | 'Geocoded'
  | 'aggregated'
  | 'Processed'
  | 'Aborted'
  | 'Processing';

export type TravelPlanServiceType =
  | 'Walk'
  | 'Bike'
  | 'Public'
  | 'Drive'
  | 'ParkAndRide'
  | 'CycleToTrainStation'
  | 'DriveToTrainStation'
  | 'CarshareToTrainStation'
  | 'Carshare'
  | 'CommunityTransport';

export enum TwoFactorType {
  None,
  Yubikey,
  GoogleAuthenticator,
}

export enum ScopingTemplateType {
  Standard,
  Enterprise,
  Generic,
}

export enum MapDataType {
  Circle,
  Marker,
}

export enum ScopingMapType {
  PublicTransport,
  Liftshare,
  ActiveTravel,
  Operator,
}

export enum ScopingOperatorType {
  Bus,
  Train,
  ParkAndRide,
}

export enum SurveyType {
  ACEL,
  TravelSurvey,
  Custom,
}

export enum ContactPreferenceType {
  MarketingInformation = 0,
  Surveys = 1,
  TravelPlans = 2,

  // Not really a preference since you cannot opt out, but included here
  // so we can use the same component
  PendingLiftshareAccounts = 2,
}

export enum SurveyImageType {
  Email,
  ThankyouMessage,
}

export enum SurveyDeliveryMethod {
  SharableLink,
  Email,
  Either,
}

export enum SurveyStatus {
  Draft = 0,
  Published = 1,
  Unpublished = 2,
}

export enum ContactsFilterType {
  None,
  InvalidEmail,
  Unsubscribed,
  Suppression,
  NoAddress,
  AlreadyInPendingLiftshareAccountFlow,
}

export enum SurveyQuestionType {
  None = -1,
  NumberRating = 0,
  SingleText = 1,
  Comment = 2,
  MultipleChoiceCheckbox = 3,
  MultipleChoiceRadio = 4,
  EmailAddress = 5,
  Dropdown = 6,
  Location = 7,
  Acel = 8,
  MultiQuestion = 9,
  Number = 10,
  Distance = 11,
  DaysPerWeek = 12,
}

export enum SurveySectionType {
  IndividualQuestion = 0,
  RadioButtonMatrix = 1,
  QuestionGroup = 2,
  AcelQuestion = 3,
  AcelNumDaysTravelToWork = 4,
  AcelNumDaysWorkFromHome = 5, // TODO: This is not ACEL apparently. To be removed?
  AcelDistanceToWork = 6,
  QuestionSection = 7,
}

export enum TravelPlanRateLimitStatus {
  Under,
  Warning,
  Over,
}

export enum ContactFieldType {
  Unknown = -1,
  FirstName = 0,
  LastName = 1,
  Email = 2,
  HomeAddress = 3,
  PublicId = 4,
  Tag = 5,
  WorkStartTime = 6,
  WorkFinishTime = 7,
  WorkDepartment = 8,
  WorkLocation = 9,
  CurrentCommuteMode = 10,
  AlternativeCommuteModes = 11,
  CommuteDistanceInMeters = 12,
  CommuteDaysPerWeek = 13,
}

export enum ContactTableColumn {
  FirstName = 0,
  LastName = 1,
  Email = 2,
  HomeAddress = 3,
  Tags = 4,
  WorkDepartment = 6,
  CurrentCommuteMode = 8,
  AlternativeCommuteModes = 9,
  CommuteDistanceInMeters = 10,
  CommuteDaysPerWeek = 11,
  WorkStartTime = 12,
  WorkFinishTime = 13,
}

export enum RecipientFilterType {
  AND,
  OR,
}

export enum SurveysAnalysisChartGroupBy {
  Day = 0,
  Week = 1,
  Month = 2,
  Quarter = 3,
  Year = 4,
}

export enum NewAcelType {
  Manual,
  FromSurvey,
}

export enum SentStatus {
  Draft,
  NotSent,
  Scheduled,
  Sent,
  Failed,
}

export enum TravelPlanStatus {
  Draft,
  NotSent,
  Scheduled,
  Sent,
  Failed,
}

export enum TravelPlanSource {
  Individual = 0,
  Batch = 1,
  Widget = 2,
  Other = 100,
}

export enum RecipientsFilterType {
  IndividualContact,
  FilterCriteria,
}

export enum TravelPlansListOrderBy {
  DateCreatedUtc,
  Status,
  DateEmailSentUtc,
  PlanOnlineViews,
  DateSurveySentUtc,
  BatchName,
  ContactName,
}

export enum TravelPlanSearchFilterProperty {
  ContactName,
  Email,
  Origin,
  Destination,
  DateCreatedUtc,
  SearchableProperties,
  DateEmailSentUtc,
  PlanSource,
  Status,
  BatchName,
}

export enum TravelPlanBatchDelayType {
  DoNotSend,
  Immediately,
  TimedDelay,
}

export enum TravelPlanBatchRecipientsListType {
  Individual,
  ContactSearchFilter,
  BatchUpload,
}

export enum TravelPlanLegType {
  Outbound,
  Return,
}

export enum TravelPlanBatchStatus {
  Draft = 0,
  RecipientPlansFinalized = 1,
  GeneratingPlans = 2,
  GeneratingPlansComplete = 3,
  FinalizedAndReadyToSend = 4,
  Scheduled = 5,
  Sent = 6,
  Cancelled = 7,
}

export enum BatchListStatus {
  Draft = 0,
  NotSent = 1,
  Scheduled = 2,
  Sent = 3,
}

export enum WidgetStatus {
  Active,
  Paused,
}

export enum WidgetType {
  Iframe,
  Button,
  Url,
}

export enum WidgetRestrictionType {
  None,
  Origin,
  Destination,
  Both,
}

export enum WidgetSendEmailOption {
  DontSend,
  Optional,
  Send,
}

export enum WidgetButtonRadius {
  None,
  Small,
  Medium,
  Large,
  Full,
}

export enum WidgetButtonFontSize {
  XSmall,
  Small,
  Medium,
  Large,
  XLarge,
  XXLarge,
}

export enum JourneyType {
  Passenger = 1,
  Driver = 2,
  Both = 3,
}

export enum JourneyFrequency {
  OneOff = 1,
  Regular = 2,
  Occasional = 3,
  Shift = 4,
}

export enum PassengerOrDriver {
  Passenger,
  Driver,
}

export enum TravelPlanEmailTabStatus {
  TravelPlanEmail,
  BehaviourChangeEmail,
  FollowUpEmail,
}

export enum PtpTabStatus {
  Draft,
  Results,
  Breakdown,
  Liftshare,
}

export enum SurveyImagePosition {
  Left,
  Centre,
  Right,
}

export enum TravelPlanFailReason {
  Unknown = 0,
  UnableToRoute = 1,
  NoValidRoutes = 2,
}

export enum GeoRestrictionType {
  DefinedDestinations = 0,
  Geofence = 1,
}

export enum PendingLiftshareAccountsFilterType {
  None,
}

export enum PendingLiftshareAccountsEmailTabStatus {
  InitialEmail,
  FollowUpEmail1,
  FollowUpEmail2,
}

export enum PlaTabStatus {
  Draft,
  Results,
  Breakdown,
  Liftshare,
}

export enum PendingLiftshareAccountsBatchStatus {
  Draft = 0,
  Final = 1,
  Processed = 2,
  Scheduled = 3,
}

export enum PendingLiftshareAccountsBatchRecipientsListType {
  Individual,
  ContactSearchFilter,
  BatchUpload,
}

export enum PendingLiftshareAccountsBatchDelayType {
  Immediately,
  TimedDelay,
}

export enum PendingLiftshareAccountsJourneyType {
  OneOff = 1,
  Regular = 2,
  Occasional = 3,
  Shift = 4,
}
