import type { RootState } from '@/mw-components/store';
import type { State } from '@/mw-components/store/modules/dashboard-app';
import { DashboardAppMutationTypes } from './mutation-types';
import type { Mutations } from './mutations';
import { DashboardAppActionTypes } from './action-types';
import type { ActionContext, ActionTree } from 'vuex';
import { MWApi, MWApiV2 } from '@/api/legacy';
import { ROLE_ADMIN } from '@/mw-components/types/roles';

import storage from '@/helpers/storage';

const apiV2 = new MWApiV2();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [DashboardAppActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void;
  [DashboardAppActionTypes.GET_ACCOUNTS_LIST]({
    commit,
    dispatch,
    rootGetters,
  }: AugmentedActionContext): void;
  [DashboardAppActionTypes.GET_CURRENT_FROM_STORE](
    { state, commit, getters }: AugmentedActionContext,
    payload: boolean,
  ): void;
  [DashboardAppActionTypes.SEARCH_TERM_CHANGE](
    { commit }: AugmentedActionContext,
    payload: string,
  ): void;
  [DashboardAppActionTypes.ON_ACCOUNT_CHANGE](
    { state, commit, getters }: AugmentedActionContext,
    payload: string,
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [DashboardAppActionTypes.LOGOUT]({ commit }) {
    commit(DashboardAppMutationTypes.UPDATE_ACEL_MODAL_SHIFT, null);
    commit(DashboardAppMutationTypes.UPDATE_ACEL_TABS, null);
    storage.remove('isRefreshingToken'); // Cleaning up local storage
  },
  async [DashboardAppActionTypes.GET_ACCOUNTS_LIST]({ commit, dispatch, rootGetters }) {
    if (rootGetters['auth/hasRole'](ROLE_ADMIN) === true) {
      try {
        const {
          response: { communities },
        } = await apiV2.Communities();

        commit(DashboardAppMutationTypes.UPDATE_COMMUNITY_ROLES, communities);
        dispatch(DashboardAppActionTypes.GET_CURRENT_FROM_STORE, true);
        commit(DashboardAppMutationTypes.SET_COMMUNITY_FAIL, false);
      } catch {
        commit(DashboardAppMutationTypes.SET_COMMUNITY_FAIL, true);
      }
    } else {
      const communityRoles = rootGetters['auth/getUserRolesArray'].map((role: any) => ({
        id: role.CommunityId,
        name: role.CommunityName,
        role: role.Role,
      }));
      commit(DashboardAppMutationTypes.SET_COMMUNITY_FAIL, false);
      commit(DashboardAppMutationTypes.UPDATE_COMMUNITY_ROLES, communityRoles);
      dispatch(DashboardAppActionTypes.GET_CURRENT_FROM_STORE, false);
    }
  },
  [DashboardAppActionTypes.GET_CURRENT_FROM_STORE]({ state, commit, getters }, isAdmin) {
    const existsInRoles = state.communityRoles.some(
      (e: any) => e.id === getters.getSelectedCommunity,
    );

    if (getters.getSelectedCommunity && existsInRoles) {
      commit(DashboardAppMutationTypes.SET_CHECKED_ACCOUNT, getters.getSelectedCommunity);
      commit(DashboardAppMutationTypes.UPDATE_COMMUNITY, getters.getSelectedCommunity);
    } else {
      const community = isAdmin ? 'xk' : state.communityRoles[0]?.id;
      commit(DashboardAppMutationTypes.SET_CHECKED_ACCOUNT, community);
      commit(DashboardAppMutationTypes.UPDATE_COMMUNITY, community);
    }
  },
  [DashboardAppActionTypes.SEARCH_TERM_CHANGE]({ commit }, searchTerm) {
    commit(DashboardAppMutationTypes.SET_SEARCH_TERM, searchTerm);
  },
  [DashboardAppActionTypes.ON_ACCOUNT_CHANGE]({ state, commit, getters }, id) {
    if (id !== getters.getSelectedCommunity) {
      commit(DashboardAppMutationTypes.UPDATE_COMMUNITY, id);
      commit(DashboardAppMutationTypes.SET_CHECKED_ACCOUNT, id);
      commit(DashboardAppMutationTypes.UPDATE_ACEL_MODAL_SHIFT, null);
      commit(DashboardAppMutationTypes.UPDATE_ACEL_TABS, null);
    }
  },
};
