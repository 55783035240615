<template>
  <a v-if="data.isExternalUrl" :class="data.classes" :href="data.routerLink" target="_blank">
    <slot></slot>
  </a>
  <router-link v-else :to="{ name: data.routerLink }" :class="data.classes">
    <slot></slot>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { isExternalUrl } from '@/plugin/functions';

export default defineComponent({
  name: 'HeaderLink',
  props: {
    routerLink: String, // vue router identifier for this link
  },

  setup(props) {
    const data = reactive({
      routerLink: props.routerLink,
      isExternalUrl: isExternalUrl(props.routerLink),
      classes: 'text-sm text-gray-100 font-medium p-4 hover:bg-gray-900',
    });

    return {
      data,
    };
  },
});
</script>
