<template>
  <nav class="bg-gray-800 shadow">
    <div
      class="container-fluid mx-auto flex flex-col lg:flex-row justify-between items-start lg:items-center"
    >
      <div class="flex items-start justify-between w-full lg:w-auto">
        <div class="flex flex-col lg:flex-row flex-1 justify-between lg:items-center">
          <div class="block px-6 py-4">
            <img
              class="w-24 h-6"
              src="https://cdn.liftshare.com/static-assets/mobilityways/dashboard/mw_logo.png"
              alt="Mobilityways"
            />
          </div>

          <template v-if="isAuthenticated">
            <div class="lg:flex items-center pt-4 lg:pt-0" :class="navOpen ? 'block' : 'hidden'">
              <div class="flex flex-col lg:flex-row">
                <template v-for="item in NAV_ITEMS" :key="item.label">
                  <template
                    v-if="
                      item.menuData &&
                      ((item.role && globalHelpers.isUserPermitted(item.role)) || !item.role)
                    "
                  >
                    <HeaderLinkMenu
                      :id="item.id"
                      :menu-data="filterMenuItemsByRole(item)"
                      :show-border="item.showBorder"
                      >{{ item.label }}</HeaderLinkMenu
                    >
                  </template>
                  <template v-else>
                    <HeaderLink
                      :data-cy="`nav-link-${item.id}`"
                      v-if="(item.role && globalHelpers.isUserPermitted(item.role)) || !item.role"
                      :router-link="item.link"
                      >{{ item.label }}</HeaderLink
                    >
                  </template>
                </template>

                <a
                  data-cy="logout-button"
                  @click="signout()"
                  class="text-sm text-gray-100 font-medium p-4 hover:bg-gray-900 lg:hidden"
                >
                  Sign Out
                </a>
              </div>
            </div>
          </template>
        </div>

        <!-- Mobile menu button -->
        <div data-toggle-mobile-menu class="flex lg:hidden" @click="toggleHamburger()">
          <button
            type="button"
            class="text-gray-500 hover:text-gray-600 focus:outline-none mx-6 my-4 focus:text-gray-600"
            aria-label="toggle menu"
          >
            <svg viewBox="0 0 24 24" class="h-6 w-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <div class="flex items-center px-6">
        <UserNav data-header-usernav-item v-if="isAuthenticated" />
        <router-link
          :to="{ name: paths.login.name }"
          class="block px-4 py-2 text-sm capitalize text-white hover:bg-gray-800"
          v-else
        >
          Sign in
        </router-link>
      </div>
    </div>
  </nav>
</template>
<script lang="ts" setup>
import { ref } from 'vue';

import router from '@/router';
import paths from '@/router/paths';
import { NAV_ITEMS, type NavItem } from './navigation';
import globalHelpers from '@/stores/global-helpers';

// pinia stores
import { useAuthStore } from '@/stores/auth/auth-store';
const authStore = useAuthStore();

// components
import HeaderLink from './HeaderLink.vue';
import HeaderLinkMenu from './HeaderLinkMenu.vue';
import UserNav from './UserNav.vue';

defineProps<{
  isAuthenticated: boolean;
}>();

const navOpen = ref(false);
const toggleHamburger = () => {
  navOpen.value = !navOpen.value;
};

// destroy tokens, etc
const signout = () => {
  authStore.destroyTokens();
  router.push({ name: 'login', query: { why: 'user' } });
};

// Dropdown menus have items sometimes containing their own user access roles so filter them out
function filterMenuItemsByRole(item: NavItem) {
  return item.menuData!.filter((i) => !i.role || globalHelpers.isUserPermitted(i.role));
}
</script>
