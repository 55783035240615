import type { MutationTree } from 'vuex';
import type {
  CachedWidgetProps,
  State,
  WidgetErrorDescriptor,
  CreateWidgetModalType,
} from './state';
import { defaultState } from './state';
import { CreateWidgetMutationTypes } from './mutation-types';

import type { TravelPlanEmailTabStatus } from '@/mw-components/enums/travel-plan';

import type { Widget, WidgetEmailSettings } from '@/mw-components/types/widgets';
import type { Location } from '@/mw-components/types/location';

import type {
  WidgetButtonFontSize,
  WidgetButtonRadius,
  WidgetType,
} from '@/mw-components/enums/widgets';
import { WidgetStatus } from '@/mw-components/enums/widgets';

import { clone } from 'underscore';

type FilteredKeys<T, U> = {
  [prop in keyof T]: T[prop] extends U ? prop : never;
}[keyof T];

type StateBooleanProp = FilteredKeys<State, boolean>;
export type EmailSettingStringProp = FilteredKeys<WidgetEmailSettings, string>;
export type EmailSettingBooleanProp = FilteredKeys<WidgetEmailSettings, boolean>;
export type WidgetStringProp = FilteredKeys<Widget, string>;
export type WidgetNullableProp = FilteredKeys<Widget, null>;

export type Mutations<S = State> = {
  [CreateWidgetMutationTypes.RESET_STATE](state: S): void;
  [CreateWidgetMutationTypes.SET_LOADING](state: S, payload: boolean): void;
  [CreateWidgetMutationTypes.SET_WIDGET](state: S, payload: Widget): void;
  [CreateWidgetMutationTypes.SET_CUSTOM_TEXTS](
    state: S,
    payload: Array<{ label: string; value: string }>,
  ): void;
  [CreateWidgetMutationTypes.SET_EMAIL_SETTINGS](state: S, payload: WidgetEmailSettings): void;
  [CreateWidgetMutationTypes.SET_CONTENT_READY](state: S, payload: boolean): void;
  [CreateWidgetMutationTypes.SET_ACTIVE_TAB](state: S, payload: string): void;
  [CreateWidgetMutationTypes.SET_ACTIVE_EMAIL_TAB](
    state: S,
    payload: TravelPlanEmailTabStatus,
  ): void;
  [CreateWidgetMutationTypes.SET_WIDGET_CUSTOM_COLOUR](
    state: S,
    payload: { colour: string; contrast: string },
  ): void;
  [CreateWidgetMutationTypes.SET_WIDGET_TYPE](state: S, payload: WidgetType): void;
  [CreateWidgetMutationTypes.SET_WIDGET_BUTTON_TEXT](state: S, payload: string): void;
  [CreateWidgetMutationTypes.SET_WIDGET_BUTTON_BORDER_RADIUS](
    state: S,
    payload: WidgetButtonRadius,
  ): void;
  [CreateWidgetMutationTypes.SET_WIDGET_BUTTON_FONT_SIZE](
    state: S,
    payload: WidgetButtonFontSize,
  ): void;
  [CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING](
    state: S,
    payload: { prop: WidgetStringProp | WidgetNullableProp; value: string | null },
  ): void;
  [CreateWidgetMutationTypes.CACHE_WIDGET_PROP](
    state: S,
    payload: { prop: keyof CachedWidgetProps; value: any },
  ): void;
  [CreateWidgetMutationTypes.SET_WIDGET_STATUS](state: S, payload: boolean): void;
  [CreateWidgetMutationTypes.INITIALISE_GEO_REGIONS](
    state: S,
    payload: GeoJSON.FeatureCollection<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>,
  ): void;
  [CreateWidgetMutationTypes.UPDATE_GEO_REGIONS](state: S, payload: Array<number>): void;
  [CreateWidgetMutationTypes.REMOVE_GEO_RESTRICTIONS](state: S, payload: Array<number>): void;
  [CreateWidgetMutationTypes.SET_WIDGET_TRAVEL_DESTINATION](
    state: S,
    payload: Array<Location>,
  ): void;
  [CreateWidgetMutationTypes.SET_GEOFENCE_TOGGLE](state: S, payload: boolean): void;
  [CreateWidgetMutationTypes.SET_GEOFENCE_MAP_TOGGLE](state: S, payload: boolean): void;
  [CreateWidgetMutationTypes.SET_EMAILSETTINGS_BOOLEAN_PROPERTY](
    state: S,
    payload: { prop: string; value: boolean },
  ): void;
  [CreateWidgetMutationTypes.SET_EMAILSETTINGS_STRING_PROPERTY](
    state: S,
    payload: { prop: string; value: string | null },
  ): void;
  [CreateWidgetMutationTypes.SET_EMAILSETTINGS_CUSTOM_COLOUR](
    state: S,
    payload: { colour: string; contrast: string },
  ): void;
  [CreateWidgetMutationTypes.SET_ERROR_ON_PROPERTY](
    state: S,
    payload: { prop: keyof Widget | keyof WidgetEmailSettings; error: WidgetErrorDescriptor },
  ): void;
  [CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY](
    state: S,
    payload: keyof Widget | keyof WidgetEmailSettings,
  ): void;
  [CreateWidgetMutationTypes.SET_WIDGET_ARRIVEBY](state: S): void;
  [CreateWidgetMutationTypes.SET_WIDGET_RETURNBY](state: S): void;
  [CreateWidgetMutationTypes.SET_OUTBOUND_TIME](state: S, payload: string): void;
  [CreateWidgetMutationTypes.SET_RETURN_TIME](state: S, payload: string): void;
  [CreateWidgetMutationTypes.SET_UNSAVED_CHANGES](state: S, payload: boolean): void;
  [CreateWidgetMutationTypes.SET_MODAL](
    state: S,
    payload: { type: CreateWidgetModalType; active: boolean },
  ): void;

  [CreateWidgetMutationTypes.SET_BOOLEAN_PROP](
    state: S,
    payload: { prop: StateBooleanProp; value: boolean },
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [CreateWidgetMutationTypes.RESET_STATE](state) {
    Object.assign(state, defaultState());
  },
  [CreateWidgetMutationTypes.SET_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [CreateWidgetMutationTypes.SET_CUSTOM_TEXTS](state, customTexts) {
    state.customTexts = customTexts;
  },
  [CreateWidgetMutationTypes.SET_WIDGET](state, widget) {
    state.widget = {
      ...state.widget,
      ...widget,
    };
    state.lastSave = {
      ...state.lastSave,
      widget: {
        ...state.lastSave.widget,
        ...widget,
      },
    };
  },
  [CreateWidgetMutationTypes.SET_EMAIL_SETTINGS](state, emailSettings) {
    state.emailSettings = {
      ...state.emailSettings,
      ...emailSettings,
    };
    state.lastSave = {
      ...state.lastSave,
      emailSettings: {
        ...state.lastSave.emailSettings,
        ...emailSettings,
      },
    };
  },
  [CreateWidgetMutationTypes.SET_CONTENT_READY](state, isReady) {
    state.contentReady = isReady;
  },
  [CreateWidgetMutationTypes.SET_ACTIVE_TAB](state, name) {
    state.tabs.active = state.tabs.titles.indexOf(name);
  },
  [CreateWidgetMutationTypes.SET_ACTIVE_EMAIL_TAB](state, tab) {
    state.emailTabs.active = tab;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_CUSTOM_COLOUR](state, data) {
    state.widget.customColour = data.colour;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_TYPE](state, value) {
    state.widget.type = value;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_BUTTON_TEXT](state, value) {
    state.widget.customButtonText = value;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_BUTTON_FONT_SIZE](state, value) {
    state.widget.customButtonFontSize = value;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_BUTTON_BORDER_RADIUS](state, value) {
    state.widget.customButtonRadius = value;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING]({ widget }, { prop, value }) {
    if (typeof value === 'string') {
      prop = prop as WidgetStringProp;
      if (prop) widget[prop] = value;
    } else {
      prop = prop as WidgetNullableProp;
      // @ts-ignore
      widget[prop] = value;
    }
  },
  [CreateWidgetMutationTypes.CACHE_WIDGET_PROP]({ cachedWidgetProps }, { prop, value }) {
    // @ts-ignore
    cachedWidgetProps[prop] = value;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_STATUS](state, value) {
    state.widget.status = value ? WidgetStatus.Active : WidgetStatus.Paused;
  },
  [CreateWidgetMutationTypes.INITIALISE_GEO_REGIONS](state, payload) {
    state.selectedGeoFences = payload.features.map((feature) => feature?.properties?.osm_id);
  },
  [CreateWidgetMutationTypes.UPDATE_GEO_REGIONS](state, payload) {
    state.selectedGeoFences = payload;
  },
  [CreateWidgetMutationTypes.REMOVE_GEO_RESTRICTIONS](state, values) {
    if (state.widget.geographyRestrictions) {
      const { features } = state.widget.geographyRestrictions;
      // @ts-ignore
      state.widget.geographyRestrictions.features = features.filter((feature) => {
        if (feature.properties?.hasOwnProperty('osm_id')) {
          return values.includes(feature.properties?.osm_id);
        } else {
          return true;
        }
      });
    }
  },
  [CreateWidgetMutationTypes.SET_WIDGET_TRAVEL_DESTINATION](state, payload) {
    state.widget.locations = payload;
  },
  [CreateWidgetMutationTypes.SET_GEOFENCE_TOGGLE](state, payload) {
    state.geofencingActive = payload;

    if (state.geoFencingMapActive) {
      state.geoFencingMapActive = false;
    }
  },
  [CreateWidgetMutationTypes.SET_GEOFENCE_MAP_TOGGLE](state, payload) {
    state.geoFencingMapActive = payload;
  },
  [CreateWidgetMutationTypes.SET_EMAILSETTINGS_BOOLEAN_PROPERTY](state, { prop, value }) {
    // @ts-ignore
    state.emailSettings[prop] = value;
  },
  [CreateWidgetMutationTypes.SET_EMAILSETTINGS_STRING_PROPERTY](state, { prop, value }) {
    // @ts-ignore
    state.emailSettings[prop] = value;
  },
  [CreateWidgetMutationTypes.SET_EMAILSETTINGS_CUSTOM_COLOUR](state, data) {
    state.emailSettings.emailSettingButtonColour = data.colour;
  },
  [CreateWidgetMutationTypes.SET_ERROR_ON_PROPERTY](state, { prop, error }) {
    state.errors[prop] = error;
  },
  [CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY](state, prop) {
    delete state.errors[prop];
  },
  [CreateWidgetMutationTypes.SET_WIDGET_ARRIVEBY](state) {
    state.widget.defaultArriveBy = !state.widget.defaultArriveBy;
  },
  [CreateWidgetMutationTypes.SET_WIDGET_RETURNBY](state) {
    state.widget.defaultReturnBy = !state.widget.defaultReturnBy;
  },
  [CreateWidgetMutationTypes.SET_OUTBOUND_TIME](state, time) {
    state.widget.defaultOutboundTime = time;
  },
  [CreateWidgetMutationTypes.SET_RETURN_TIME](state, time) {
    state.widget.defaultReturnTime = time;
  },
  [CreateWidgetMutationTypes.SET_UNSAVED_CHANGES](state, isUnsaved) {
    state.unsavedChanges = isUnsaved;
    state.lastSave.widget = clone(state.widget);
    state.lastSave.emailSettings = clone(state.emailSettings);
  },
  [CreateWidgetMutationTypes.SET_MODAL](state, { type, active }) {
    state.modal.type = type;
    state.modal.active = active;
  },
  [CreateWidgetMutationTypes.SET_BOOLEAN_PROP](state, payload) {
    state[payload.prop] = payload.value;
  },
};
