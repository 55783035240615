import type { MutationTree } from 'vuex';
import { defaultState } from './state';
import type { State } from './state-types';
import { TravelPlanMutationTypes } from './mutation-types';
import type { TravelPlanSwitchType } from '@/mw-components/enums/travel-plan';

type FilteredKeys<T, U> = {
  [prop in keyof T]: T[prop] extends U ? prop : never;
}[keyof T];

export type StateStringNullProp = FilteredKeys<State, string | null>;

export type Mutations<S = State> = {
  [TravelPlanMutationTypes.RESET_STATE](state: S): void;
  [TravelPlanMutationTypes.SET_LOADING](state: S, payload: boolean): void;
  [TravelPlanMutationTypes.SET_CONTENTREADY](state: S, payload: boolean): void;
  [TravelPlanMutationTypes.SET_STRING_PROP](
    state: S,
    payload: { prop: StateStringNullProp; value: string | null },
  ): void;
  [TravelPlanMutationTypes.SET_ROUTES](state: S, payload: any): void;
  [TravelPlanMutationTypes.SET_OPTIONS](state: S, payload: any): void;
  [TravelPlanMutationTypes.SET_FACTS](state: S, payload: any): void;
  [TravelPlanMutationTypes.SET_TRAVEL_MODE](state: S, payload: TravelPlanSwitchType): void;
  [TravelPlanMutationTypes.SET_TRAVEL_MODE_BY_CLICKING_LIFTSHARE_MARKER](
    state: S,
    payload: TravelPlanSwitchType,
  ): void;
  [TravelPlanMutationTypes.SET_BOUNDS](state: S, payload: boolean): void;
  [TravelPlanMutationTypes.SET_CONTACTS](state: S, payload: boolean): void;
  [TravelPlanMutationTypes.SET_HEADER_IMAGE_URL](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [TravelPlanMutationTypes.RESET_STATE](state) {
    Object.keys(state).forEach((key) => {
      const k = key as keyof State;
      state[k] = defaultState()[k];
    });
  },
  [TravelPlanMutationTypes.SET_LOADING](state, payload) {
    state.loading = payload;
  },
  [TravelPlanMutationTypes.SET_CONTENTREADY](state, payload) {
    state.contentReady = payload;
  },
  [TravelPlanMutationTypes.SET_STRING_PROP](state, payload) {
    state[payload.prop] = payload.value;
  },
  [TravelPlanMutationTypes.SET_ROUTES](state, payload) {
    state.routes = payload;
  },
  [TravelPlanMutationTypes.SET_OPTIONS](state, payload) {
    state.options = payload;
  },
  [TravelPlanMutationTypes.SET_FACTS](state, payload) {
    state.facts = payload;
  },
  [TravelPlanMutationTypes.SET_TRAVEL_MODE](state, payload) {
    state.currentTravelMode = payload;
  },
  // I needed another mutation that does the same as SET_TRAVEL_MODE, but doesn't run the subscription
  // associated with SET_TRAVEL_MODE (it always selectes the first liftshare card when the mode changes,
  // which is undesirable when trying to change the mode when clicking on the liftshare marker - we should select the
  // marker which has been clicked on. Thus, I created a duplicate mutation...)
  [TravelPlanMutationTypes.SET_TRAVEL_MODE_BY_CLICKING_LIFTSHARE_MARKER](state, payload) {
    state.currentTravelMode = payload;
  },
  [TravelPlanMutationTypes.SET_BOUNDS](state, payload) {
    state.outbound = payload;
  },
  [TravelPlanMutationTypes.SET_CONTACTS](state, payload) {
    state.contacts = payload;
  },
  [TravelPlanMutationTypes.SET_HEADER_IMAGE_URL](state, payload) {
    state.headerImageUrl = payload;
  },
};
