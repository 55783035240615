import { getEnv } from '@/plugin/functions';

/**
 * Get the API domain based on the current environment
 */
export const getApiDomain = () => {
  switch (getEnv()) {
    case 'localdev':
    case 'aspire':
      return 'https://localhost:5001';
    case 'localhost':
    case 'development':
      return 'https://interface-dev.liftshare.com';
    case 'staging':
      return 'https://interface-staging.liftshare.com';
    case 'production':
    default:
      return 'https://interface.liftshare.com';
  }
};
