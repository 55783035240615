<template>
  <div
    class="relative cursor-pointer"
    v-clickout="closeDropdown"
    @click="data.dropdownVisible = true"
  >
    <slot />

    <div class="absolute max-w-xs w-screen pt-1 right-0 z-20" v-if="data.dropdownVisible">
      <ul class="pt-1 acel__list bg-white border shadow-lg w-full overflow-y-auto">
        <li class="w-full relative p-2 bg-smoke">
          <input
            type="text"
            v-model="data.searchTerm"
            class="shadow appearance-none border rounded w-full py-1 px-3 text-gray-700 focus:outline-none"
            placeholder="Search results"
          />
          <i
            v-if="data.searchTerm"
            class="fas fa-times absolute mr-4 p-2 right-0 cursor-pointer z-20"
            id="clearSearchQuery"
            @click="data.searchTerm = ''"
          ></i>
        </li>
        <template v-if="filteredEntries.length">
          <li
            @click="selectEntry(entry)"
            class="w-full flex items-center justify-between py-2 px-4 hover:bg-gray-200 cursor-pointer"
            v-for="(entry, index) in filteredEntries"
            :key="index"
          >
            {{ entry.label }}
          </li>
        </template>
        <template v-else>
          <li class="flex items-center py-2 px-4 opacity-75">No result found</li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, reactive } from 'vue';
// @ts-ignore
import vClickout from 'vue3-clickout';

type Entry = {
  id?: string;
  label: string;
  value: string;
};

export default defineComponent({
  name: 'InputMenu',
  emits: ['entrySelected'],
  directives: {
    clickout: vClickout,
  },
  props: {
    dataset: {
      type: Array as PropType<Array<Entry>>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const data = reactive({
      entries: props.dataset,
      dropdownVisible: false,
      searchTerm: '',
    });

    const filteredEntries = computed(() => {
      if (!data.searchTerm) return data.entries;
      const searchTerm = data.searchTerm.toLowerCase();

      return data.entries.filter((entry) => entry.label?.toLowerCase().includes(searchTerm));
    });

    const closeDropdown = ($event?: { el: HTMLElement; event: Event }) => {
      if ($event) {
        const { event } = $event;
        const target = event.target as HTMLElement;
        if (target && target.id === 'clearSearchQuery') return;
      }
      data.dropdownVisible = false;
    };

    const selectEntry = (entry: Entry) => {
      if (!entry) return;
      emit('entrySelected', entry);
      closeDropdown();
    };

    return {
      data,
      closeDropdown,
      selectEntry,
      filteredEntries,
    };
  },
});
</script>
