export default {
  commuteBoostSteps: {
    navigationTitle: 'Create challenge',
    challengeDetails: 'Challenge details',
    qualificationCriteria: 'Qualification criteria',
    boostDetails: 'Boost details',
    terms: 'Terms',
    publish: 'Publish challenge',
  },
  backButtons: {
    backToCBList: 'Back to challenge list',
  },
};
