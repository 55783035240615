import type { RootState } from '@/mw-components/store';
import type { CreateWidgetModalType, FetchedGeoJSON, State, WidgetErrorDescriptor } from './state';
import type { Mutations, WidgetStringProp } from './mutations';
import { CreateWidgetMutationTypes } from './mutation-types';
import { CreateWidgetActionTypes } from './action-types';
import type { ActionContext, ActionTree } from 'vuex';
import type { TravelPlanEmailTabStatus } from '@/mw-components/enums/travel-plan';

import type { Widget, WidgetEmailSettings } from '@/mw-components/types/widgets';
import type { Location } from '@/mw-components/types/location';

import type {
  WidgetButtonFontSize,
  WidgetButtonRadius,
  WidgetType,
} from '@/mw-components/enums/widgets';

import { MWApi } from '@/api/legacy';
import { clone, isEqual } from 'underscore';
import { uuidv4 } from '@/mw-components/helpers/functions';
import { store } from '@/mw-components/store';

const api = new MWApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [CreateWidgetActionTypes.FETCH_WIDGET]({ commit }: AugmentedActionContext, payload: string): void;
  [CreateWidgetActionTypes.FETCH_CUSTOM_TEXT](
    { commit }: AugmentedActionContext,
    payload: string,
  ): void;
  [CreateWidgetActionTypes.ACTIVE_TAB_CHANGE](
    { commit }: AugmentedActionContext,
    name: string,
  ): void;
  [CreateWidgetActionTypes.ACTIVE_EMAIL_TAB_CHANGE](
    { commit }: AugmentedActionContext,
    tab: TravelPlanEmailTabStatus,
  ): void;
  [CreateWidgetActionTypes.WIDGET_CUSTOM_COLOUR_CHANGE](
    { commit }: AugmentedActionContext,
    data: { colour: string; contrast: string },
  ): void;
  [CreateWidgetActionTypes.WIDGET_TYPE_CHANGE](
    { commit }: AugmentedActionContext,
    value: WidgetType,
  ): void;
  [CreateWidgetActionTypes.WIDGET_BUTTON_TEXT_CHANGE](
    { commit }: AugmentedActionContext,
    value: string,
  ): void;
  [CreateWidgetActionTypes.WIDGET_TITLE_CHANGE](
    { commit }: AugmentedActionContext,
    value: string,
  ): void;
  [CreateWidgetActionTypes.WIDGET_NAME_CHANGE](
    { commit }: AugmentedActionContext,
    value: string,
  ): void;
  [CreateWidgetActionTypes.WIDGET_INTRO_CHANGE](
    { commit }: AugmentedActionContext,
    value: string,
  ): void;
  [CreateWidgetActionTypes.WIDGET_TITLE_TOGGLED](
    { state, commit, dispatch }: AugmentedActionContext,
    value: boolean,
  ): void;
  [CreateWidgetActionTypes.WIDGET_INTRO_TOGGLED](
    { state, commit, dispatch }: AugmentedActionContext,
    value: boolean,
  ): void;
  [CreateWidgetActionTypes.WIDGET_BUTTON_FONT_SIZE_CHANGE](
    { commit }: AugmentedActionContext,
    value: WidgetButtonFontSize,
  ): void;
  [CreateWidgetActionTypes.WIDGET_BUTTON_RADIUS_CHANGE](
    { commit }: AugmentedActionContext,
    value: WidgetButtonRadius,
  ): void;
  [CreateWidgetActionTypes.WIDGET_STATUS_CHANGE](
    { commit }: AugmentedActionContext,
    value: boolean,
  ): void;
  [CreateWidgetActionTypes.WIDGET_GEO_REGIONS_CHANGE](
    { state, commit }: AugmentedActionContext,
    value: Array<number>,
  ): void;
  [CreateWidgetActionTypes.WIDGET_TRAVEL_DESTINATION_CHANGE](
    { commit }: AugmentedActionContext,
    value: Array<Location>,
  ): void;
  [CreateWidgetActionTypes.WIDGET_STRING_PROPERTY_CHANGE](
    { commit }: AugmentedActionContext,
    payload: { prop: keyof Widget; value: string | null },
  ): void;
  [CreateWidgetActionTypes.SAVE_WIDGET](
    { state, commit }: AugmentedActionContext,
    payload: FetchedGeoJSON,
  ): Promise<void>;
  [CreateWidgetActionTypes.WIDGET_GEOFENCE_TOGGLED](
    { commit }: AugmentedActionContext,
    value: boolean,
  ): void;
  [CreateWidgetActionTypes.WIDGET_GEOFENCE_MAP_TOGGLED]({
    state,
    commit,
  }: AugmentedActionContext): void;
  [CreateWidgetActionTypes.EMAILSETTINGS_BOOLEAN_PROPERTY_CHANGE](
    { commit }: AugmentedActionContext,
    payload: { prop: string; value: boolean },
  ): void;
  [CreateWidgetActionTypes.EMAILSETTINGS_STRING_PROPERTY_CHANGE](
    { commit }: AugmentedActionContext,
    payload: { prop: keyof WidgetEmailSettings; value: string | null },
  ): void;
  [CreateWidgetActionTypes.EMAILSETTINGS_CUSTOM_COLOUR_CHANGE](
    { commit }: AugmentedActionContext,
    payload: { colour: string; contrast: string },
  ): void;
  [CreateWidgetActionTypes.ERROR_ON_PROPERTY](
    { commit }: AugmentedActionContext,
    payload: { prop: keyof Widget | keyof WidgetEmailSettings; error: WidgetErrorDescriptor },
  ): void;
  [CreateWidgetActionTypes.GOTO_ERROR](
    { state, commit }: AugmentedActionContext,
    payload: WidgetErrorDescriptor,
  ): Promise<boolean>;
  [CreateWidgetActionTypes.ON_IMAGE_CHANGE](
    { state, commit }: AugmentedActionContext,
    payload: { file: File; geoData: FetchedGeoJSON },
  ): void;
  [CreateWidgetActionTypes.WIDGET_ARRIVEBY_TOGGLED]({ commit }: AugmentedActionContext): void;
  [CreateWidgetActionTypes.WIDGET_RETURNBY_TOGGLED]({ commit }: AugmentedActionContext): void;
  [CreateWidgetActionTypes.WIDGET_OUTBOUND_TIME_CHANGE](
    { commit }: AugmentedActionContext,
    payload: string,
  ): void;
  [CreateWidgetActionTypes.WIDGET_RETURN_TIME_CHANGE](
    { commit }: AugmentedActionContext,
    payload: string,
  ): void;
  [CreateWidgetActionTypes.UNSAVED_CHANGES](
    { state, commit }: AugmentedActionContext,
    payload: boolean,
  ): void;
  [CreateWidgetActionTypes.ACTIVATE_MODAL](
    { commit }: AugmentedActionContext,
    payload: { type: CreateWidgetModalType; active: boolean },
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [CreateWidgetActionTypes.FETCH_WIDGET]({ commit }, publicId) {
    // @ts-ignore
    commit(CreateWidgetMutationTypes.RESET_STATE);
    if (publicId) {
      commit(CreateWidgetMutationTypes.SET_LOADING, true);

      api
        .Widget(`/${publicId}`)
        .then((resp: any) => {
          const { widget, emailSettings } = resp;
          const { geographyRestrictions } = widget;
          commit(CreateWidgetMutationTypes.SET_LOADING, false);
          if (geographyRestrictions) {
            commit(CreateWidgetMutationTypes.SET_GEOFENCE_TOGGLE, true);
            commit(CreateWidgetMutationTypes.INITIALISE_GEO_REGIONS, geographyRestrictions);
          }
          if (!widget.title) widget.title = '';
          if (!widget.introduction) widget.introduction = '';
          commit(CreateWidgetMutationTypes.SET_WIDGET, widget);
          commit(CreateWidgetMutationTypes.SET_EMAIL_SETTINGS, emailSettings);
          commit(CreateWidgetMutationTypes.SET_CONTENT_READY, true);
        })
        .catch(() => {
          // toast.error('Unable to fetch widgets, please try again later');
          commit(CreateWidgetMutationTypes.SET_LOADING, false);
          commit(CreateWidgetMutationTypes.SET_CONTENT_READY, true);
        });
    } else {
      commit(CreateWidgetMutationTypes.SET_CONTENT_READY, true);
    }
  },
  [CreateWidgetActionTypes.FETCH_CUSTOM_TEXT]({ commit }, communityId) {
    api.CustomTexts(`?cid=${communityId}`).then((resp: any) => {
      const { customTexts } = resp;
      const data: Array<{ label: string; value: string }> = customTexts.map((e: any) => ({
        label: e.internalName,
        value: e.customText,
      }));

      commit(CreateWidgetMutationTypes.SET_CUSTOM_TEXTS, data);
    });
  },
  [CreateWidgetActionTypes.ACTIVE_TAB_CHANGE]({ commit }, name) {
    commit(CreateWidgetMutationTypes.SET_ACTIVE_TAB, name);
  },
  [CreateWidgetActionTypes.ACTIVE_EMAIL_TAB_CHANGE]({ commit }, tab) {
    commit(CreateWidgetMutationTypes.SET_ACTIVE_EMAIL_TAB, tab);
  },
  [CreateWidgetActionTypes.WIDGET_CUSTOM_COLOUR_CHANGE]({ commit }, data) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_CUSTOM_COLOUR, data);
  },
  [CreateWidgetActionTypes.WIDGET_TYPE_CHANGE]({ commit }, value) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_TYPE, value);
  },
  [CreateWidgetActionTypes.WIDGET_BUTTON_TEXT_CHANGE]({ commit }, value) {
    const prop: WidgetStringProp = 'customButtonText';
    commit(CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING, { prop, value });
  },
  [CreateWidgetActionTypes.WIDGET_TITLE_CHANGE]({ commit }, value) {
    const prop: WidgetStringProp = 'title' as WidgetStringProp;
    commit(CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING, { prop, value });
  },
  [CreateWidgetActionTypes.WIDGET_NAME_CHANGE]({ commit }, value) {
    const prop: WidgetStringProp = 'name';
    commit(CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING, { prop, value });
  },
  [CreateWidgetActionTypes.WIDGET_INTRO_CHANGE]({ commit }, value) {
    const prop: WidgetStringProp = 'introduction' as WidgetStringProp;
    commit(CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING, { prop, value });
  },
  [CreateWidgetActionTypes.WIDGET_TITLE_TOGGLED]({ state, commit, dispatch }, value) {
    if (!value) {
      const prop: keyof Widget = 'title';
      commit(CreateWidgetMutationTypes.CACHE_WIDGET_PROP, { prop, value: state.widget.title });
      dispatch(CreateWidgetActionTypes.WIDGET_TITLE_CHANGE, '');
    } else {
      if (state.cachedWidgetProps?.title) {
        dispatch(CreateWidgetActionTypes.WIDGET_TITLE_CHANGE, state.cachedWidgetProps?.title);
      } else {
        dispatch(CreateWidgetActionTypes.WIDGET_TITLE_CHANGE, '');
      }
    }
  },
  [CreateWidgetActionTypes.WIDGET_INTRO_TOGGLED]({ state, commit, dispatch }, value) {
    if (!value) {
      const prop: keyof Widget = 'introduction';
      commit(CreateWidgetMutationTypes.CACHE_WIDGET_PROP, {
        prop,
        value: state.widget.introduction,
      });
      dispatch(CreateWidgetActionTypes.WIDGET_INTRO_CHANGE, '');
    } else {
      if (state.cachedWidgetProps?.introduction) {
        dispatch(
          CreateWidgetActionTypes.WIDGET_INTRO_CHANGE,
          state.cachedWidgetProps?.introduction,
        );
      } else {
        dispatch(CreateWidgetActionTypes.WIDGET_INTRO_CHANGE, '');
      }
    }
  },
  [CreateWidgetActionTypes.WIDGET_BUTTON_RADIUS_CHANGE]({ commit }, value) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_BUTTON_BORDER_RADIUS, value);
  },
  [CreateWidgetActionTypes.WIDGET_BUTTON_FONT_SIZE_CHANGE]({ commit }, value) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_BUTTON_FONT_SIZE, value);
  },
  [CreateWidgetActionTypes.WIDGET_STATUS_CHANGE]({ commit }, value) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_STATUS, value);
  },
  [CreateWidgetActionTypes.WIDGET_GEO_REGIONS_CHANGE]({ commit }, value) {
    commit(CreateWidgetMutationTypes.UPDATE_GEO_REGIONS, value);
    if (value.length > 0) {
      commit(CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY, 'geographyRestrictions');
      commit(CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY, 'locations');
    }
  },
  [CreateWidgetActionTypes.WIDGET_TRAVEL_DESTINATION_CHANGE]({ commit }, value) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_TRAVEL_DESTINATION, value);
    if (value.length > 0) {
      commit(CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY, 'geographyRestrictions');
      commit(CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY, 'locations');
    }
  },
  [CreateWidgetActionTypes.SAVE_WIDGET]({ state, commit }, payload) {
    commit(CreateWidgetMutationTypes.SET_LOADING, true);

    const widget = clone(state.widget);
    const emailSettings = clone(state.emailSettings);

    if (!state.geofencingActive) {
      widget.geographyRestrictions = null;
    } else {
      // @ts-ignore
      widget.locations = null;

      widget.geographyRestrictions = {
        type: 'FeatureCollection',
        features: [
          ...state.selectedGeoFences.map((fence) => {
            return payload[fence];
          }),
        ] as GeoJSON.Feature[],
      };
    }

    // TODO: can prob refactor this now
    return new Promise<void>((resolve, reject) => {
      api
        .WidgetsUpsert({ widget: widget, emailSettings: emailSettings })
        .then((resp: any) => {
          const { widget } = resp;
          commit(CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING, {
            prop: 'id' as WidgetStringProp,
            value: widget.id,
          });
          commit(CreateWidgetMutationTypes.SET_LOADING, false);
          commit(CreateWidgetMutationTypes.SET_UNSAVED_CHANGES, false);
          resolve();
        })
        .catch(() => {
          commit(CreateWidgetMutationTypes.SET_LOADING, false);
          reject();
        });
    });
  },
  [CreateWidgetActionTypes.WIDGET_STRING_PROPERTY_CHANGE]({ commit }, value) {
    // @ts-ignore
    commit(CreateWidgetMutationTypes.SET_WIDGET_PROP_BY_STRING, value);
    commit(CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY, value.prop);
  },
  [CreateWidgetActionTypes.WIDGET_GEOFENCE_TOGGLED]({ commit }, value) {
    commit(CreateWidgetMutationTypes.SET_GEOFENCE_TOGGLE, value);
  },
  [CreateWidgetActionTypes.WIDGET_GEOFENCE_MAP_TOGGLED]({ state, commit }) {
    commit(CreateWidgetMutationTypes.SET_GEOFENCE_MAP_TOGGLE, !state.geoFencingMapActive);
  },
  [CreateWidgetActionTypes.EMAILSETTINGS_BOOLEAN_PROPERTY_CHANGE]({ commit }, payload) {
    commit(CreateWidgetMutationTypes.SET_EMAILSETTINGS_BOOLEAN_PROPERTY, payload);
  },
  [CreateWidgetActionTypes.EMAILSETTINGS_STRING_PROPERTY_CHANGE]({ commit }, payload) {
    commit(CreateWidgetMutationTypes.SET_EMAILSETTINGS_STRING_PROPERTY, payload);
    commit(CreateWidgetMutationTypes.REMOVE_ERROR_ON_PROPERTY, payload.prop);
  },
  [CreateWidgetActionTypes.EMAILSETTINGS_CUSTOM_COLOUR_CHANGE]({ commit }, payload) {
    commit(CreateWidgetMutationTypes.SET_EMAILSETTINGS_CUSTOM_COLOUR, payload);
  },
  [CreateWidgetActionTypes.ERROR_ON_PROPERTY]({ commit }, payload) {
    commit(CreateWidgetMutationTypes.SET_ERROR_ON_PROPERTY, payload);
  },
  [CreateWidgetActionTypes.GOTO_ERROR]({ state, commit }, error) {
    return new Promise((resolve, _) => {
      if (typeof error.onTab === 'number')
        commit(CreateWidgetMutationTypes.SET_ACTIVE_TAB, state.tabs.titles[error.onTab]);
      if (typeof error.onEmailTab === 'number')
        commit(CreateWidgetMutationTypes.SET_ACTIVE_EMAIL_TAB, error.onEmailTab);
      resolve(true);
    });
  },
  [CreateWidgetActionTypes.ON_IMAGE_CHANGE]({ state, commit }, { file, geoData }) {
    const formData = new FormData();
    formData.append('file', file);

    // Need to save the widget to get an id to store the image against
    // TODO: check with Jason as there are usually some validation rules that run on save
    store
      .dispatch(`createWidget/${CreateWidgetActionTypes.SAVE_WIDGET}`, geoData)
      // @ts-ignore
      .then(() =>
        api
          .TravelPlanImageUpload(formData, state.widget.id ? state.widget.id : uuidv4())
          .then((resp: any) => {
            const { imageUrl } = resp;
            commit(CreateWidgetMutationTypes.SET_EMAILSETTINGS_STRING_PROPERTY, {
              prop: 'emailSettingHeaderImageUrl',
              value: imageUrl,
            });
          }),
      );
  },
  [CreateWidgetActionTypes.WIDGET_ARRIVEBY_TOGGLED]({ commit }) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_ARRIVEBY);
  },
  [CreateWidgetActionTypes.WIDGET_RETURNBY_TOGGLED]({ commit }) {
    commit(CreateWidgetMutationTypes.SET_WIDGET_RETURNBY);
  },
  [CreateWidgetActionTypes.WIDGET_OUTBOUND_TIME_CHANGE]({ commit }, time) {
    commit(CreateWidgetMutationTypes.SET_OUTBOUND_TIME, time);
  },
  [CreateWidgetActionTypes.WIDGET_RETURN_TIME_CHANGE]({ commit }, time) {
    commit(CreateWidgetMutationTypes.SET_RETURN_TIME, time);
  },
  [CreateWidgetActionTypes.UNSAVED_CHANGES]({ state, commit }, isUnsaved) {
    const emailSettings = isEqual(state.lastSave.emailSettings, state.emailSettings);
    const widgets = isEqual(state.lastSave.widget, state.widget);
    const geofencingActive = state.lastSave.geoFencesActive === state.geofencingActive;
    const selectedGeoFences = isEqual(state.lastSave.selectedGeoFences, state.selectedGeoFences);
    if (!emailSettings || !widgets || !geofencingActive || !selectedGeoFences)
      commit(CreateWidgetMutationTypes.SET_UNSAVED_CHANGES, isUnsaved);
  },
  [CreateWidgetActionTypes.ACTIVATE_MODAL]({ commit }, payload) {
    commit(CreateWidgetMutationTypes.SET_MODAL, payload);
  },
};
