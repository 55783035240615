import type { Store as VuexStore, CommitOptions, DispatchOptions, Module } from 'vuex';

import type { RootState } from '@/mw-components/store';

import { state } from './state';
import type { Getters } from './getters';
import { getters } from './getters';
import type { Mutations } from './mutations';
import { mutations } from './mutations';
import type { Actions } from './actions';
import { actions } from './actions';

import type { State } from './state';

export { State };

export type DashboardAppStore<S = State> = Omit<VuexStore<S>, 'getters' | 'commit' | 'dispatch'> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions,
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K | string,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions,
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export const store: Module<State, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
