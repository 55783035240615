export default {
  // General
  login: {
    path: '/',
    name: 'login',
  },
  forgotPassword: {
    path: '/forgot-password',
    name: 'forgot-password',
  },
  passwordReset: {
    path: '/account/passwordreset',
    name: 'password-reset',
  },
  help: {
    path: '/help',
    name: 'help',
  },
  accountSettings: {
    path: '/account/settings',
    name: 'account-settings',
  },
  // ACEL
  acel: {
    path: '/acel/:reportId?',
    name: 'acel-home',
  },
  acelReports: {
    path: '/acel/reports',
    name: 'acel-reports',
  },
  acelReportDetails: {
    path: '/acel/report/:reportId',
    name: 'acel-reportdetails',
  },
  acelCalculator: {
    path: '/acel/generator',
    name: 'acel-calculator',
  },
  acelDisabled: {
    path: '/average-commuter-emissions-level',
    name: 'acel-disabled',
  },
  // Survey
  survey: {
    path: '/survey',
    name: 'survey-home',
  },
  surveyDisabled: {
    path: '/mobilityways-surveys',
    name: 'survey-disabled',
  },
  surveyCreateIndex: {
    path: '/survey/create/new/:templateId?/:step?',
    basePath: '/survey/create/new',
    name: 'survey-create',
  },
  surveyCreateTemplates: {
    path: '/survey/templates',
    name: 'survey-create-templates',
  },
  surveyEdit: {
    path: '/survey/edit/:publicId?',
    name: 'survey-edit',
  },
  surveyCreate: {
    path: '/survey/create',
    name: 'survey-create',
  },
  surveyAnalysis: {
    path: '/survey/analytics/:publicId',
    name: 'survey-analysis',
  },
  // Scoping
  scoping: {
    path: '/scoping',
    name: 'scoping-home',
  },
  scopingFull: {
    path: '/full-scoping/:communityId?',
    name: 'scoping-full',
  },
  scopingDisabled: {
    path: '/scoping-smart-mobility',
    name: 'scoping-disabled',
  },
  scopingMap: {
    path: '/scoping/map/:id',
    name: 'scoping-map',
  },
  scopingProcessing: {
    path: '/scoping/processing/:id',
    name: 'scoping-processing',
  },
  // Travel Plans
  travelplans: {
    path: '/travel-plans',
    name: 'travelplan-home',
  },
  travelPlanList: {
    path: '/travel-plans/plans',
    name: 'travelplan-searches',
  },
  travelplansSurvey: {
    path: '/travel-plans/survey',
    name: 'travelplan-survey',
  },
  travelplansWidget: {
    path: '/travel-plans/widget',
    name: 'travelplan-widget',
  },
  travelplansTexts: {
    path: '/travel-plans/custom-texts',
    name: 'travelplan-texts',
  },
  travelplansDisabled: {
    path: '/ptp-disabled',
    name: 'ptp-disabled',
  },
  travelplansCreatePlan: {
    path: '/travel-plans/create-plan/new/:publicId?/:step?',
    basePath: '/travel-plans/create-plan/new',
    name: 'travel-plans-createplan',
  },
  travelplansEditPlan: {
    path: '/travel-plans/edit-plan/:publicId?/:step?',
    name: 'travel-plans-editplan',
  },
  travelplansCreateWidget: {
    path: '/travel-plans/create-widget/new/:publicId?',
    basePath: '/travel-plans/create-widget/new',
    name: 'travel-plans-createwidget',
  },
  travelplansEditWidget: {
    path: '/travel-plans/edit-widget/:publicId?',
    name: 'travel-plans-editwidget',
  },
  travelplansWidgetsNew: {
    path: '/travel-plans/widgets/new',
    name: 'travelplan-widgets-new',
  },
  travelplansPlanEmail: {
    path: '/travel-plans/plan-email/preview/:publicId',
    name: 'travel-plans-planEmail',
  },
  travelplansBatchEmail: {
    path: '/travel-plans/batch-email/preview/:batchId',
    name: 'travel-plans-batchEmail',
  },
  // Contacts
  contacts: {
    path: '/contacts',
    name: 'contacts-home',
  },
  contactsDisabled: {
    path: '/contacts-database',
    name: 'contacts-disabled',
  },
  contactsImport: {
    path: '/contacts/import',
    name: 'contacts-import',
  },
  contactsPreviousImports: {
    path: '/contacts/previous-imports',
    name: 'contacts-previousImports',
  },
  //LIFTSHARE INVITES
  pendingLiftshareAccounts: {
    path: '/pending-liftshare-accounts',
    name: 'pending-liftshare-accounts',
  },
  // pendingLiftshareAccountsCreate: {
  //   path: '/pending-liftshare-accounts/create',
  //   basePath: '/pending-liftshare-accounts/create-plan/new',
  //   name: 'pending-liftshare-accounts-create',
  // },
  pendingLiftshareAccountsCreate: {
    path: '/pending-liftshare-accounts/create/new/:publicId?/:step?',
    basePath: '/pending-liftshare-accounts/create/new',
    name: 'pending-liftshare-accounts-create',
  },
  pendingLiftshareAccountsEdit: {
    path: '/pending-liftshare-accounts/edit/:batchId?/:step?',
    name: 'pending-liftshare-accounts-edit',
  },
  pendingLiftshareAccountsDisabled: {
    path: '/pending-liftshare-accounts-disabled',
    name: 'pending-liftshare-accounts-disabled',
  },
  //LIFTSHARE
  Liftshare: {
    path: '/liftshare-home',
    name: 'liftshare-home',
  },
  // Admin
  adminPendingLiftshare: {
    path: '/admin/create-liftshare-accounts',
    name: 'admin-pendingLiftshare',
  },
  adminPendingLiftshareNew: {
    path: '/admin/create-liftshare-accounts/new',
    name: 'admin-pendingLiftshareNew',
  },
  adminNotificationEmailPreview: {
    path: '/admin/notifications/emailPreview/:notificationKeyDescription',
    name: 'admin-notification',
  },
  adminNotifications: {
    path: '/admin/notifications',
    name: 'notifications',
  },
  adminPlatformUsage: {
    path: '/admin/platform-usage',
    name: 'platform-usage',
  },
  // Dashboards
  dashboardHighFrequency: {
    path: '/dashboard/commute-activity',
    name: 'high-frequency-dashboard',
  },
  dashboardLowFrequency: {
    path: '/dashboard/commute-emissions',
    name: 'low-frequency-dashboard',
  },
  dashboardLiftshare: {
    path: '/dashboard/liftshare',
    name: 'liftshare-dashboard',
  },
  dashboardZeelo: {
    path: '/dashboard/zeelo',
    name: 'zeelo-dashboard',
  },
  // Admin pages
  adminHF: {
    path: '/admin/commute-activity',
    name: 'admin-hf',
  },
  adminCommuteIQListAll: {
    path: '/admin/commute-iq/all',
    name: 'admin-commute-iq-list-all',
  },
  adminSurveyListAll: {
    path: '/admin/surveys',
    name: 'admin-survey-list-all',
  },
  // CommuteBoost
  adminCommuteBoostChallenges: {
    path: '/admin/commute-boost/challenges',
    name: 'adminCommuteBoostChallenges',
  },
  adminCommuteBoostChallengesCreate: {
    path: '/admin/commute-boost/challenges/create',
    name: 'adminCommuteBoostChallengesCreate',
  },
  adminCommuteBoostChallengesEdit: {
    path: '/admin/commute-boost/challenges/edit/:challengeId',
    name: 'adminCommuteBoostChallengesEdit',
  },
  adminCommuteBoostRedeem: {
    path: '/admin/commute-boost/redeem',
    name: 'adminCommuteBoostRedeem',
  },
  adminCommuteBoostChallengesView: {
    path: '/admin/commute-boost/challenges/view/:challengeId',
    name: 'adminCommuteBoostChallengesView',
  },
  adminTracking: {
    path: '/admin/tracking',
    name: 'admin-tracking',
  },
  // Commute IQ
  commuteIQ: {
    path: '/commute-iq',
    name: 'commute-iq-report-list',
  },
  commuteIQReportGenerate: {
    path: '/commute-iq/report-generate',
    name: 'commute-iq-report-generate',
  },
  commuteIQSnapshotGenerate: {
    path: '/commute-iq/report/:reportId/create-snapshot',
    name: 'commute-iq-snapshot-generate',
  },
  commuteIQGeocodingStatus: {
    path: '/commute-iq/geocoding-status/:reportId',
    name: 'commute-iq-geocoding-status',
  },
  commuteIQReport: {
    path: '/commute-iq/report/:reportId',
    name: 'commute-iq-report',
  },
  commuteIQMap: {
    path: '/commute-iq/map/:reportId',
    name: 'commute-iq-map',
  },
  commuteIQDisabled: {
    path: '/commute-iq-info',
    name: 'commute-iq-disabled',
  },
  // Misc.
  behaviourChange: {
    path: '/projects',
    name: 'projects-home',
  },
  termsAndConditions: {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
  },
  // privacyPolicy: {
  //   path: '/privacy-policy',
  //   name: 'privacy-policy',
  // },
  cookieInformation: {
    path: '/cookie-info',
    name: 'cookie-info',
  },
  acelAssumptions: {
    path: '/acel-assumptions',
    name: 'acel-assumptions',
  },
  LowFrequencyAssumptions: {
    path: '/dashboard/commute-emissions/assumptions',
    name: 'low-frequency-assumptions',
  },
  designSystem: {
    path: '/design-system',
    name: 'design-system',
  },
  // Liftshare
  // pendingLiftshareAccounts: {
  //   path: '/pending-liftshare-accounts',
  //   name: 'pending-liftshare-accounts',
  // },
};
