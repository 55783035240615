export enum CreateWidgetActionTypes {
  FETCH_WIDGET = 'FETCH_WIDGET',
  FETCH_CUSTOM_TEXT = 'FETCH_CUSTOM_TEXT',
  ACTIVE_TAB_CHANGE = 'ACTIVE_TAB_CHANGE',
  ACTIVE_EMAIL_TAB_CHANGE = 'ACTIVE_EMAIL_TAB_CHANGE',
  WIDGET_CUSTOM_COLOUR_CHANGE = 'WIDGET_CUSTOM_COLOUR_CHANGE',
  WIDGET_TYPE_CHANGE = 'WIDGET_TYPE_CHANGE',
  WIDGET_TITLE_CHANGE = 'WIDGET_TITLE_CHANGE',
  WIDGET_NAME_CHANGE = 'WIDGET_NAME_CHANGE',
  WIDGET_TITLE_TOGGLED = 'WIDGET_TITLE_TOGGLED',
  WIDGET_INTRO_CHANGE = 'WIDGET_INTRO_CHANGE',
  WIDGET_INTRO_TOGGLED = 'WIDGET_INTRO_TOGGLED',
  WIDGET_BUTTON_TEXT_CHANGE = 'WIDGET_BUTTON_TEXT_CHANGE',
  WIDGET_BUTTON_RADIUS_CHANGE = 'WIDGET_BUTTON_RADIUS_CHANGE',
  WIDGET_BUTTON_FONT_SIZE_CHANGE = 'WIDGET_BUTTON_FONT_SIZE_CHANGE',
  WIDGET_STATUS_CHANGE = 'WIDGET_STATUS_CHANGE',
  WIDGET_STRING_PROPERTY_CHANGE = 'WIDGET_STRING_PROPERTY_CHANGE',
  WIDGET_GEO_REGIONS_CHANGE = 'WIDGET_GEO_REGIONS_CHANGE',
  WIDGET_TRAVEL_DESTINATION_CHANGE = 'WIDGET_TRAVEL_DESTINATION_CHANGE',
  WIDGET_GEOFENCE_TOGGLED = 'WIDGET_GEOFENCE_TOGGLED',
  WIDGET_GEOFENCE_MAP_TOGGLED = 'WIDGET_GEOFENCE_MAP_TOGGLED',
  WIDGET_ARRIVEBY_TOGGLED = 'WIDGET_ARRIVEBY_TOGGLED',
  WIDGET_RETURNBY_TOGGLED = 'WIDGET_RETURNBY_TOGGLED',
  WIDGET_OUTBOUND_TIME_CHANGE = 'WIDGET_OUTBOUND_TIME_CHANGE',
  WIDGET_RETURN_TIME_CHANGE = 'WIDGET_RETURN_TIME_CHANGE',
  SAVE_WIDGET = 'SAVE_WIDGET',
  EMAILSETTINGS_BOOLEAN_PROPERTY_CHANGE = 'EMAILSETTINGS_BOOLEAN_PROPERTY_CHANGE',
  EMAILSETTINGS_STRING_PROPERTY_CHANGE = 'EMAILSETTINGS_STRING_PROPERTY_CHANGE',
  EMAILSETTINGS_CUSTOM_COLOUR_CHANGE = 'EMAIL_SETTINGS_CUSTOM_COLOUR_CHANGE',
  ERROR_ON_PROPERTY = 'ERROR_ON_PROPERTY',
  GOTO_ERROR = 'GOTO_ERROR',
  ON_IMAGE_CHANGE = 'ON_IMAGE_CHANGE',
  UNSAVED_CHANGES = 'UNSAVED_CHANGES',
  ACTIVATE_MODAL = 'ACTIVATE_MODAL',
}
