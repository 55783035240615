import storage from '@/helpers/storage';

export type State = {
  community: string | null;
  communityRoles: any | null;
  communitySearchTerm: string;
  communityFail: boolean | null;
  checkedAccount: any | null;
  acelTabs: string | null;
  acelModalShift: string | null;
};

export const state: State = {
  community: storage.get<string>('community'),
  communityRoles: null,
  communitySearchTerm: '',
  communityFail: null,
  checkedAccount: null,
  acelTabs: storage.get<string>('acelTabs'),
  acelModalShift: storage.get<string>('acelModalShift'),
};
