import type { RootState } from '@/mw-components/store';
import type { ActionContext, ActionTree } from 'vuex';
import type { Mutations } from './mutations';
import { TravelPlanMutationTypes } from './mutation-types';
import type { State } from './state-types';
import { TravelPlanActionTypes } from './action-types';
import { MWApiV2 } from '@/api/legacy';

import type { TravelPlanSwitchType } from '@/mw-components/enums/travel-plan';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [TravelPlanActionTypes.FETCH_TRAVELPLAN](
    { state, commit }: AugmentedActionContext,
    payload: string,
  ): void;
  [TravelPlanActionTypes.SET_WIDGET_ID]({ commit }: AugmentedActionContext, payload: string): void;
  [TravelPlanActionTypes.SET_PLAN_ID]({ commit }: AugmentedActionContext, payload: string): void;
  [TravelPlanActionTypes.TRAVEL_MODE_CHANGE](
    { commit }: AugmentedActionContext,
    payload: TravelPlanSwitchType,
  ): void;
  [TravelPlanActionTypes.TRAVEL_MODE_CHANGE_BY_CLICKING_LIFTSHARE_MARKER](
    { commit }: AugmentedActionContext,
    payload: TravelPlanSwitchType,
  ): void;
  [TravelPlanActionTypes.TRAVEL_CARD_CLICKED](
    { commit }: AugmentedActionContext,
    payload: string | null,
  ): void;
  [TravelPlanActionTypes.LIFTSHARE_CARD_CLICKED](
    { commit }: AugmentedActionContext,
    payload: string | null,
  ): void;
  [TravelPlanActionTypes.TRAVEL_BOUNDS_CHANGED](
    { commit }: AugmentedActionContext,
    payload: boolean,
  ): void;
  [TravelPlanActionTypes.TRAVEL_PLAN_DETAIL_DISMISSED]({ commit }: AugmentedActionContext): void;
  [TravelPlanActionTypes.SET_OPTIONS]({ commit }: AugmentedActionContext, payload: object): void;
}

const apiV2 = new MWApiV2();

export const actions: ActionTree<State, RootState> & Actions = {
  async [TravelPlanActionTypes.FETCH_TRAVELPLAN]({ state, commit }, payload: string = '') {
    if (!state.planId) {
      throw new Error('No plan id');
    }
    try {
      const resp = await apiV2.TravelPlanResult(state.planId, payload);
      const { routes, facts, options, headerImageUrl } = resp;

      commit(TravelPlanMutationTypes.SET_ROUTES, routes); //.filter((e: any) => e.valid));
      commit(TravelPlanMutationTypes.SET_FACTS, facts);
      commit(TravelPlanMutationTypes.SET_HEADER_IMAGE_URL, headerImageUrl);
      commit(TravelPlanMutationTypes.SET_OPTIONS, options);
      commit(TravelPlanMutationTypes.SET_LOADING, false);
      commit(TravelPlanMutationTypes.SET_CONTENTREADY, true);
    } catch (e) {
      commit(TravelPlanMutationTypes.SET_LOADING, false);
      throw e;
    }
  },
  [TravelPlanActionTypes.SET_WIDGET_ID]({ commit }, payload) {
    if (payload) {
      commit(TravelPlanMutationTypes.SET_STRING_PROP, { prop: 'widgetId', value: payload });
      commit(TravelPlanMutationTypes.SET_LOADING, false);
      commit(TravelPlanMutationTypes.SET_CONTENTREADY, true);
    }
  },
  [TravelPlanActionTypes.SET_PLAN_ID]({ commit }, payload) {
    if (payload) {
      commit(TravelPlanMutationTypes.SET_STRING_PROP, { prop: 'planId', value: payload });
    }
  },
  [TravelPlanActionTypes.TRAVEL_MODE_CHANGE]({ commit }, payload) {
    commit(TravelPlanMutationTypes.SET_TRAVEL_MODE, payload);
  },
  [TravelPlanActionTypes.TRAVEL_MODE_CHANGE_BY_CLICKING_LIFTSHARE_MARKER]({ commit }, payload) {
    commit(TravelPlanMutationTypes.SET_TRAVEL_MODE_BY_CLICKING_LIFTSHARE_MARKER, payload);
  },
  [TravelPlanActionTypes.TRAVEL_CARD_CLICKED]({ state, commit }, payload) {
    if (state.activeCard !== payload || state.activeCard === null) {
      commit(TravelPlanMutationTypes.SET_STRING_PROP, { prop: 'activeCard', value: payload });
      return;
    }

    if (
      (state.activeCard === payload && state.viewDetail === null) ||
      state.viewDetail !== payload
    ) {
      commit(TravelPlanMutationTypes.SET_STRING_PROP, { prop: 'viewDetail', value: payload });
      return;
    }
  },
  [TravelPlanActionTypes.LIFTSHARE_CARD_CLICKED]({ commit }, payload) {
    commit(TravelPlanMutationTypes.SET_STRING_PROP, {
      prop: 'activeLiftshareCardId',
      value: payload,
    });
  },
  [TravelPlanActionTypes.TRAVEL_BOUNDS_CHANGED]({ commit }, payload) {
    commit(TravelPlanMutationTypes.SET_BOUNDS, payload);
  },
  [TravelPlanActionTypes.TRAVEL_PLAN_DETAIL_DISMISSED]({ commit }) {
    commit(TravelPlanMutationTypes.SET_STRING_PROP, { prop: 'viewDetail', value: null });
  },
  [TravelPlanActionTypes.SET_OPTIONS]({ commit }, payload) {
    if (payload) {
      commit(TravelPlanMutationTypes.SET_OPTIONS, payload);
    }
  },
};
