<template>
  <div
    v-if="!hasBeenClosed"
    ref="npsPanel"
    :class="{
      'nps-panel--show': showNPS,
      'nps-panel--active': npsActive,
      'nps-panel--closed': !npsActive,
      'nps-panel--selected': selected,
    }"
    class="nps-panel absolute w-screen bottom-0 border-t-2"
  >
    <div class="nps-panel__header">
      <div
        v-if="showNPS && !npsActive"
        @click="minimise(false)"
        class="nps-panel__open text-gray-600 text-center"
      >
        <br />
      </div>
      <div class="nps-panel__close text-right">
        <button class="mr-4 mt-1" @click="minimise(true)"><i class="fa fa-2x fa-times"></i></button>
      </div>
    </div>

    <p v-if="!selected" class="text-center mb-2 px-4">
      How likely are you are you to recommend Mobilityways to a friend or colleague?
    </p>
    <p v-else class="text-center mb-2 px-4 font-bold">Thanks</p>

    <div class="nps-panel__feedback px-4">
      <div class="flex items-center">
        <p class="text-sm">Not at all likely</p>
        <label
          ><input
            name="nps"
            type="radio"
            value="1"
            data-value="1"
            v-model="selected"
            @change="npsChange"
          />1</label
        >
      </div>

      <label
        ><input
          name="nps"
          type="radio"
          value="2"
          data-value="2"
          v-model="selected"
          @change="npsChange"
        />2</label
      >
      <label
        ><input
          name="nps"
          type="radio"
          value="3"
          data-value="3"
          v-model="selected"
          @change="npsChange"
        />3</label
      >
      <label
        ><input
          name="nps"
          type="radio"
          value="4"
          data-value="4"
          v-model="selected"
          @change="npsChange"
        />4</label
      >
      <label
        ><input
          name="nps"
          type="radio"
          value="5"
          data-value="5"
          v-model="selected"
          @change="npsChange"
        />5</label
      >
      <label
        ><input
          name="nps"
          type="radio"
          value="6"
          data-value="6"
          v-model="selected"
          @change="npsChange"
        />6</label
      >
      <label
        ><input
          name="nps"
          type="radio"
          value="7"
          data-value="7"
          v-model="selected"
          @change="npsChange"
        />7</label
      >
      <label
        ><input
          name="nps"
          type="radio"
          value="8"
          data-value="8"
          v-model="selected"
          @change="npsChange"
        />8</label
      >
      <label
        ><input
          name="nps"
          type="radio"
          value="9"
          data-value="9"
          v-model="selected"
          @change="npsChange"
        />9</label
      >

      <div class="flex items-center">
        <label
          ><input
            name="nps"
            type="radio"
            value="10"
            data-value="10"
            v-model="selected"
            @change="npsChange"
          />10</label
        >
        <p class="text-sm">Extremely likely</p>
      </div>

      <div class="nps-panel__furtherinfo">
        <div class="nps-panel__furtherinfo-content">
          <BrandTextarea
            style="height: 100px; max-height: 100px"
            :label="
              parseInt(selected) > 8
                ? 'Thank you, is there anything we can do to improve your experience?'
                : 'Can you tell us more about your experience, so we can do even better next time?'
            "
            v-model="comment"
          />
          <div class="text-right">
            <Button label="Submit" @click="submitNps" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, watchEffect, nextTick } from 'vue';
import { MWApiV2 } from '@/api/legacy';
import BrandTextarea from '@/mw-components/components/BrandTextarea/BrandTextarea.vue';
import Button from 'primevue/button';
import storage from '@/helpers/storage';
import { NpsCategory } from '@/mw-components/enums/nps';
import { useToast } from 'vue-toastification';

import StorageHelpers from '@/helpers/storage';

const api = new MWApiV2();

export default defineComponent({
  name: 'NPSFeedback',
  components: {
    BrandTextarea,
    Button,
  },

  setup() {
    const NPS_MINIMIZED = sessionStorage.getItem('NPS_MIN');
    const NPS_ID_STORAGE_KEY = 'nps_id';
    const NPS_SELECTED_STORAGE_KEY = 'nps-selected';
    const checkId = storage.get<String>(NPS_ID_STORAGE_KEY);
    const checkSelected = storage.get<String>(NPS_SELECTED_STORAGE_KEY);

    const selected = ref<String>(checkSelected ? checkSelected : '');
    const comment = ref<String>('');
    const id = ref<String>(checkId ? checkId : '');
    const showNPS = ref<boolean>(!!checkId);
    const npsActive = ref<boolean>(false);
    const npsPanel = ref<HTMLDivElement | null>(null);

    const toast = useToast();

    /**
     * This feedback form is way over-complicated.
     * Just set a value in local storage to stop hassling users.
     */
    const hasBeenClosed = ref(StorageHelpers.get('feedback-form-closed') ?? false);

    onMounted(() => {
      if (NPS_MINIMIZED !== 'true') {
        if (!checkId) initNps();
      }
    });

    watchEffect(() => {
      if (npsPanel.value !== null && showNPS.value === true) {
        setTimeout(() => {
          npsActive.value = true;
        }, 1000);
      }
    });

    const initNps = async () => {
      try {
        const {
          response: { npsRequired },
        } = await api.NPSCheck(NpsCategory.MobilitywaysDashboard);
        showNPS.value = npsRequired;
      } catch {}
    };

    const npsChange = () => {
      if (!id.value && selected.value) {
        postNps();
      } else if (id.value && selected.value) {
        updateNps();
        storage.set<String>(NPS_SELECTED_STORAGE_KEY, selected.value);
      }
    };

    const postNps = async () => {
      try {
        const {
          response: { npsId },
        } = await api.NPSPost({
          score: selected.value,
          Comment: comment.value,
          Category: NpsCategory.MobilitywaysDashboard,
        });
        id.value = npsId;
        storage.set<String>(NPS_ID_STORAGE_KEY, npsId);
        storage.set<String>(NPS_SELECTED_STORAGE_KEY, selected.value);
      } catch (error) {
        toast.error('There was an issue saving your nps selection, please try again');
        selected.value = '';
      }
    };

    const updateNps = async () => {
      try {
        await api.NPSUpdate({
          score: selected.value,
          comment: comment.value,
          npsId: id.value,
        });
        return true;
      } catch {
        toast.error('There was an issue updating your nps selection and comment, please try again');
        return false;
      }
    };

    const submitNps = () => {
      updateNps();
      npsPanel.value?.addEventListener('transitionend', () => {
        nextTick(() => {
          showNPS.value = false;
        });
      });
      minimise();
      storage.remove(NPS_ID_STORAGE_KEY);
      storage.remove(NPS_SELECTED_STORAGE_KEY);
    };

    const minimise = (value: boolean = true) => {
      if (value) StorageHelpers.set('feedback-form-closed', true);
      sessionStorage.setItem('NPS_MIN', 'true');
      npsActive.value = !value;
    };

    return {
      hasBeenClosed,
      selected,
      showNPS,
      npsActive,
      npsPanel,
      comment,
      npsChange,
      submitNps,
      minimise,
    };
  },
});
</script>

<style lang="scss">
@use 'sass:math';

$size: 50px;

.nps-panel {
  overflow: hidden;
  background-color: #f1f2f8;
  border-color: #99a0cb;
  height: 0px;
  transition: height 0.5s ease-in-out;
  z-index: 1000;

  .nps-panel__feedback {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 2em;

    & > label,
    & > div label:not(.nps-panel__furtherinfo-content label) {
      position: relative;
      display: block;
      text-align: center;
      line-height: $size;
      width: $size;
      height: $size;
      margin: math.div($size, 8);
      font-weight: bold;
    }

    input[type='radio'] {
      position: absolute;
      cursor: pointer;
      display: grid;
      border: 1px solid #f163c1;
      appearance: none;
      background-color: #fff;
      font: inherit;
      width: $size;
      height: $size;

      &::before {
        content: '';
        width: $size;
        height: $size;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 70px 70px #f163c195;
      }

      &::after {
        position: absolute;
        z-index: 1;
        content: attr(data-value);
        width: $size;
        height: $size;
        text-align: center;
      }

      &:checked::before {
        transform: scale(1);
      }
    }

    .nps-panel__furtherinfo {
      width: 100%;
      margin-top: 1em;

      .nps-panel__furtherinfo-content {
        max-width: 615px;
        margin: 0 auto;
      }
    }
  }

  .nps-panel__header {
    color: #99a0cb;

    button {
      color: #99a0cb;
    }
  }
}

.nps-panel.nps-panel--show {
  display: block;
  height: 0px;
}

.nps-panel.nps-panel--show.nps-panel--closed {
  height: 0px;
}

.nps-panel.nps-panel--show.nps-panel--active {
  height: 239px;
}

.nps-panel.nps-panel--show.nps-panel--active.nps-panel--selected {
  height: 430px;
}

body .nps-panel .nps-panel__feedback .nps-panel__furtherinfo .nps-panel__furtherinfo-content {
  textarea {
    background-color: white;
    border-color: #f163c1;
  }
}

.nps-panel.nps-panel--closed {
  .nps-panel__close {
    display: none;
  }
}

@media screen and (min-width: 605px) {
  .nps-panel.nps-panel--show.nps-panel--active {
    height: 215px;
  }
}

@media screen and (min-width: 852px) {
  .nps-panel.nps-panel--show.nps-panel--active {
    height: 153px;
  }

  .nps-panel.nps-panel--show.nps-panel--active.nps-panel--selected {
    height: 350px;
  }
}
</style>
